<template>
  <div v-show="subStep === 0 && !isNext" class="absolute bottom-5 w-full">
    <div class="m-auto w-11/12 lg:w-2/3 question-container max-h-[300px] lg:max-h-[500px]">
     <p class="italic !text-blue-dark mb-2">
        {{
          nbGoodAnswers > 1
            ? 'Plusieurs bonnes réponses sont possibles'
            : 'Une seule bonne réponse est possible'
        }}
      </p>
      <div class="flex flex-col justify-center p-2 max-h-[180px] lg:max-h-[300px] overflow-auto">
        <div
          @click="!isAnswers ? chooseAnswer(answer.id, answer.next_text) : ''"
          v-for="answer in answers"
          :key="answer.id"
          class="px-6 py-2 answer-container mb-3 rounded-xl text-left font-semibold flex text-blue-dark border-b-4 border-t-4"
          :class="divActive(answer.id, answer.is_correct)"
        >
          <img
            v-if="!isAnswers"
            :src="
              '/img/check-' +
              (circleActive(answer.id) ? 'full2' : 'empty2') +
              '.png'
            "
            alt=""
            class="w-5 h-5 my-auto mr-2"
          />
          <img
            v-if="isAnswers && answer.is_correct"
            src="/img/check.svg"
            alt=""
            class="w-7 mr-2"
          />
           <img
            v-if="isAnswers && !answer.is_correct"
            src="/img/cross.svg"
            alt=""
            class="w-7 mr-2"
          />
          <p v-html="answer.text"></p>
        </div>
      </div>
      <button
        v-if="!isAnswers"
        @click="answersChoice.length == 0 ? '' : sawAnswers() "
        :class="
          'px-6 py-2 answer-container rounded-full uppercase ' +
          (answersChoice.length == 0
            ? '!bg-gray-dark cursor-default '
            : '!bg-blue hover:!bg-blue-hover')
        "
      >
        <div class="flex">
          <img
            v-if="answersChoice.length != 0"
            src="/img/check.svg"
            alt=""
            class="w-7 mr-2"
          />
          <p>Valider ma réponse</p>
        </div>
      </button>
      <button
        v-if="isAnswers"
        @click="nextStepPractice()"
        :class="
          'px-6 py-2 answer-container rounded-full uppercase !bg-blue hover:!bg-blue-hover'
        "
      >
        <div class="flex">
          <p>Suivant</p>
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import StepMixin from '@/components/scene/components/mixins/StepMixin'

export default {
  emits: ['is-next-practice', 'next-practice', 'submit'],
  name: 'PracticeQuestion',
  mixins: [StepMixin],  
  components: {
  },
  data() {
    return {
      goodAnswers: [],
      answersChoice: [],
      isAnswers: false,
    }
  },
  props: {
    subStep: [Boolean, Number],
    isNext: [Boolean, Number],
    nbGoodAnswers: [Number, String],
    nextText: [String],
    step: [Object],
    answers: [Object],
  },
  computed: {
    user() {
      return this.$store.state.account.data
    }
  },
  methods: {
    sawAnswers() {
      this.isAnswers = true
    },
    nextStepPractice() {
      const lastChoice = this.answers.find(answer => answer.is_correct);
      // const lastChoice = this.answers.filter(answer => answer.id == this.answersChoice[this.answersChoice.length - 1]);
      // if(lastChoice[0].next_text != '' && lastChoice[0].next_text != null) {
      if(lastChoice && lastChoice.next_text != '' && lastChoice.next_text != null) {
        this.$emit('next-practice', true, lastChoice.next_text,this.answersChoice)
      } else {
        this.$emit('submit', this.answersChoice)
      }
      
      this.isAnswers = false

    },
    // check the response and throw the next action
    divActive(answerId, isCorrect) {
      if (this.isAnswers && isCorrect) {
        return 'bg-[#C2E59B] border-b-4 border-[#C2E59B]'
      }
      if (this.isAnswers && !isCorrect) {
        return 'bg-[#FDA6A6] border-b-4 border-[#FDA6A6]'
      }

      if (this.answersChoice.includes(answerId)) {
        return 'bg-gray-lightest border-b-4 border-blue cursor-pointer border-gray-lightest hover:border-b-gray'
      }
      return 'bg-gray-lightest cursor-pointer border-gray-lightest hover:border-b-gray'
    },
    circleActive(answerId) {
      if (this.answersChoice.includes(answerId)) {
        return true
      }
      return false
    },
    chooseAnswer(answerId) {
      if (!this.answersChoice.includes(answerId)) {
          if (this.nbGoodAnswers == 1) {
          this.answersChoice = []
          }
          this.answersChoice.push(answerId)
      } else {
          let index = this.answersChoice.indexOf(answerId)
          this.answersChoice.splice(index, 1)
      }
    },
  },
  watch: {
    answers() {
      this.answersChoice = []
    }
  },
  created() {
    // console.log(this.answers)
    // console.log(this.step.options)
  }
}
</script>

<style scoped>

.question-container {
  @apply bg-white rounded-3xl  border-b-[10px] border-blue;
}

.answer-container {
  /* background-color: RGB(123, 43, 69); */
  user-select: none; /* standard syntax */
  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -khtml-user-select: none; /* webkit (konqueror) browsers */
  -ms-user-select: none; /* IE10+ */
}

.question-container {
  @apply text-white m-auto text-sm lg:text-xl p-4 text-center;
}
</style>
