<template>
  <div v-if="collaborators.length !== 0" class="content">
    <div class="flex">
      <HomeReturn :route="'Menu'" />
    </div>
    <div class="flex flex-col pt-[10vmin] h-full">
      <div class="text-6xl w-full text-center mb-6 font-semibold">
        <span class="text-blue">L'équipe</span>
      </div>
      <Carousel :items-to-show="2.5" :wrap-around="true">
        <Slide v-for="collaborator of collaborators" :key="collaborator.id">
          <div
            class="carousel__item bg-white w-11/12 relative shadow-xl p-6 text-center text-blue-dark text-2xl"
          >
            <img
              :src="'/img/collaborators/' + collaborator.picture + '.jpg'"
              class="w-30 m-auto"
              alt=""
            />
            <p class="mt-2 text-blue-dark uppercase font-bold text-3xl mt-4">
              {{ collaborator.name }}
            </p>
            <p class="mt-2 text-blue uppercase font-semibold text-2xl mt-4">
              {{ collaborator.job.name }}
            </p>
          </div>
        </Slide>

        <template #addons>
          <Navigation />
        </template>
      </Carousel>
    </div>
  </div>
</template>

<script>
import apiManager from '../datas/apiManager'
import { Carousel, Navigation, Slide } from 'vue3-carousel'
import HomeReturn from '@/components/HomeReturn.vue'

import 'vue3-carousel/dist/carousel.css'

export default {
  components: {
    Carousel,
    Slide,
    Navigation,
    HomeReturn
  },
  name: 'ViewCollaborators',
  data() {
    return {
      collaborators: []
    }
  },
  computed: {
    user() {
      return this.$store.state.account.data
    }
  },
  methods: {},
  mounted() {
    let buttons = document.querySelectorAll('.carousel > button')
    buttons.forEach((button) => {
      button.style.backgroundColor = 'RGB(28, 160, 180)'
      button.style.width = '50px'
      button.style.height = '50px'
    })
  },
  created() {
    apiManager.getCollaborators().then((response) => {
      this.collaborators = response.data.data
    })
  },
  watch: {
    // whenever question changes, this function will run
    collaborators() {
      let buttons = document.querySelectorAll('.carousel > button')
      buttons.forEach((button) => {
        button.style.backgroundColor = 'RGB(28, 160, 180)'
        button.style.width = '50px'
        button.style.height = '50px'
      })
    }
  }
}
</script>

<style scoped>
.carousel > button {
  background-color: black !important;
}
.carousel {
  width: 70% !important;
  margin: auto;
  padding: 8px;
}
.carousel__slide > .carousel__item {
  transform: scale(1);
  opacity: 0.5;
  transition: 0.5s;
}
.carousel__slide--visible > .carousel__item {
  opacity: 1;
  transform: rotateY(0);
}
.carousel__slide--next > .carousel__item {
  transform: scale(0.9) translate(-10px);
}
.carousel__slide--prev > .carousel__item {
  transform: scale(0.9) translate(10px);
}
.carousel__slide--active > .carousel__item {
  /* transform: scale(1.1); */
}
</style>
