<template>
  <overlay-scene @overlay-clicked="nextStep()"></overlay-scene>
  <div class="absolute top-5 w-full flex h-24">
    <div class="flex ml-10 w-24 lg:w-52" style="z-index: 49">
      <router-link
        v-if="module"
        :to="{
          name: 'Modules',
          params: {
            idAdventure: module.adventure_id,
            idThematic: module.thematic_id ? module.thematic_id : 0
          }
        }"
      >
        <img
          :src="retour_img"
          @mouseover="retour_img = '/img/btn_quit_hover.svg'"
          @mouseleave="retour_img = '/img/btn_quit.svg'"
          alt=""
          class="mt-5"
        />
      </router-link>
    </div>
  </div>

  <div class="absolute bottom-[10%] lg:bottom-[20%] w-full">
    <div class="w-2/3 flex justify-center dialog-container py-3 !text-base lg:!text-3xl">
      <p v-html="step.options.text"></p>
    </div>
  </div>
</template>

<script>
import StepMixin from '@/components/scene/components/mixins/StepMixin'

export default {
  emits: ['is-view', 'is-next-practice'],
  name: 'TransitionScene',
  mixins: [StepMixin],
  components: {},
  data() {
    return {
      retour_img: '/img/btn_quit.svg',
    }
  },
  methods: {},
  watch: {},
  updated() {
    setTimeout(
      () => {
        this.nextStep()
      },
      this.step.options.duration == 0 ? 2000 : this.step.options.duration * 1000
    )
  },
  created() {
    setTimeout(
      () => {
        this.nextStep()
      },
      this.step.options.duration == 0 ? 2000 : this.step.options.duration * 1000
    )
  }
}
</script>

<style scoped>
.dialog-container {
  @apply text-black text-3xl m-auto p-12 text-center font-bold;
}
</style>
