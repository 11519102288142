import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/Login.vue'
import ForgetPassword from '../views/ForgetPassword.vue'
import NewPassword from '../views/NewPassword.vue'
import Menu from '../views/Menu.vue'
import Manual from '../views/Manual.vue'
import Carousel from '../views/Carousel.vue'
import Toolbox from '../views/Toolbox.vue'
import Documents from '../views/Toolbox/Documents.vue'

import Collaborators from '../views/Collaborators.vue'
import Company from '../views/Company.vue'

import Training from '../views/Training.vue'
import CommunExp from '../views/Training/CommunExp.vue'
import Commun from '../views/Training/Commun.vue'
import CommunLoc from '../views/Training/CommunLoc.vue'
import CommunCol from '../views/Training/CommunCol.vue'
import CommunJob from '../views/Training/CommunJob.vue'
import JobExp from '../views/Training/JobExp.vue'
import Thematics from '../views/Training/Thematics.vue'
import Modules from '../views/Training/Modules.vue'
import Quizz from '../views/Training/Quizz.vue'

import Profile from '../views/Profile.vue'
import Progression from '../views/Profile/Progression.vue'
import Perf from '../views/Profile/Perf.vue'
import PerfAdventure from '../views/Profile/PerfAdventure.vue'
import PerfGraph from '../views/Profile/PerfGraph.vue'

import Game from '../views/Game.vue'
import Test from '../views/Test.vue'

import store from '../store/index'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'Menu',
      component: Menu
    },

    {
      path: '/login',
      name: 'Login',
      props: true,
      component: Login
    },
    {
      path: '/forget-password',
      name: 'ForgetPassword',
      component: ForgetPassword
    },
    {
      path: '/new-password/:token',
      name: 'NewPassword',
      props: true,
      component: NewPassword
    },
    {
      path: '/profile',
      name: 'Profile',
      component: Profile
    },

    {
      path: '/collaborators',
      name: 'Collaborators',
      component: Collaborators
    },
    {
      path: '/company',
      name: 'Company',
      component: Company
    },

    {
      path: '/training',
      name: 'Training',
      component: Training
    },
    {
      path: '/commun-exp',
      name: 'CommunExp',
      component: CommunExp
    },
    {
      path: '/commun-job',
      name: 'CommunJob',
      component: CommunJob
    },
    {
      path: '/commun-loc',
      name: 'CommunLoc',
      component: CommunLoc
    },
    {
      path: '/commun-col',
      name: 'CommunCol',
      component: CommunCol
    },
    {
      path: '/commun',
      name: 'Commun',
      props: true,
      component: Commun
    },

    {
      path: '/job-exp',
      name: 'JobExp',
      component: JobExp
    },
    {
      path: '/thematics/:id',
      name: 'Thematics',
      props: true,
      component: Thematics
    },

    {
      path: '/modules/:idAdventure/:idThematic',
      name: 'Modules',
      props: true,
      component: Modules
    },
    {
      path: '/quizz/:idModule',
      name: 'Quizz',
      props: true,
      component: Quizz
    },

    {
      path: '/toolbox',
      name: 'Toolbox',
      component: Toolbox
    },
    {
      path: '/documents',
      name: 'Documents',
      component: Documents
    },
    {
      path: '/manual',
      name: 'Manual',
      component: Manual
    },
    {
      path: '/carousel',
      name: 'Carousel',
      component: Carousel
    },

    {
      path: '/progression',
      name: 'Progression',
      component: Progression
    },
    {
      path: '/perf',
      name: 'Perf',
      component: Perf
    },
    {
      path: '/perf-adventure/:id',
      name: 'PerfAdventure',
      props: true,
      component: PerfAdventure
    },
    {
      path: '/perf-graph/:id',
      name: 'PerfGraph',
      props: true,
      component: PerfGraph
    },
    {
      path: '/game/:idModule',
      name: 'Game',
      props: true,
      component: Game
    },
    {
      path: '/test/',
      name: 'Test',
      props: true,
      component: Test
    }
    // {
    //   path: '/about',
    //   name: 'about',
    //   // route level code-splitting
    //   // this generates a separate chunk (About.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import('../views/AboutView.vue')
    // }
  ]
})

router.beforeEach((to, from, next) => {
  if (
    to.name !== 'Login' &&
    to.name !== 'ForgetPassword' &&
    to.name !== 'NewPassword' &&
    !store.getters['account/loggedIn']
  ) {
    next({ name: 'Login' })
  } else if (
    to.name === 'ForgetPassword' &&
    !store.getters['account/loggedIn'] &&
    from.path !== '/login' &&
    from.path !== '/'
  ) {
    next({ name: 'ForgetPassword' })
  } else if (
    to.name === 'NewPassword' &&
    !store.getters['account/loggedIn'] &&
    from.path !== '/login' &&
    from.path !== '/'
  ) {
    next({ name: 'NewPassword' })
  } else if (to.name === 'Login' && store.getters['account/loggedIn']) {
    next({ name: 'Menu' })
  } else {
    next()
  }
})

export default router
