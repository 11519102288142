<template>
  <div
    v-if="modules.length > 0 && adventure"
    class="flex justify-center items-center h-screen"
  >
    <div class="responsive-box">
      <div class="top-left-back-btn">
        <div>
          <router-link :to="{ name: 'Menu' }">
            <img src="/img/logo.svg" alt="" />
          </router-link>
        </div>
        <div>
          <router-link
            v-if="adventure"
            :to="{
              name: prevRoutePath(adventure.reference),
              params: {
                id: prevRouteParams(adventure.reference)
              }
            }"
          >
            <img
              :src="retour_img"
              @mouseover="retour_img = '/img/btn_retour_hover.svg'"
              @mouseleave="retour_img = '/img/btn_retour.svg'"
              alt=""
              class="mt-5"
            />
          </router-link>
        </div>
      </div>
      <div class="flex flex-col pt-[10vmin] h-full">
        <div class="text-6xl w-full text-center font-semibold mb-6">
          <span class="title">{{ adventure ? adventure.name : '' }}</span>
        </div>
        <!-- <div class="page-desc barlow uppercase !w-full text-center mb-6">
          Choisissez votre module
        </div> -->

        <div
          class="flex items-stretch overflow-auto flex-wrap justify-center w-full select-none"
        >
          <div
            v-for="(module, index) of modules"
            :key="module.id"
            class="w-1/6 relative border-r-8 border-b-8 rounded-xl text-left text-blue-dark text-2xl module-element mt-3 m-3"
            :class="
              completedModules[module.id] || index == 0
                ? module.is_quizz
                  ? 'bg-pink border-pink-dark hover:bg-pink-hover hover:border-pink-hoverdark cursor-pointer'
                  : 'bg-blue-lightest border-blue hover:bg-blue-hoverlight hover:border-blue-hover'
                : 'bg-gray border-gray-dark'
            "
          >
            <router-link
              v-if="completedModules[module.id] || index == 0"
              :to="{
                name: module.is_quizz ? 'Quizz' : 'Game',
                params: {
                  idModule: module.id
                }
              }"
            >
              <div class="p-2 xl:p-4">
                <!-- <div
                  class="w-full"
                  :class="module.is_quizz ? 'arrow-div-quiz xl:!w-24 xl:!h-24 !w-10 !h-10' :  ''"
                > -->
                <div
                  class="w-full"
                  :class="''"
                >
                <img :src="'/img/modules/Module-' + module.id + '.png'" alt="">
                </div>
                <!-- <img :src="imgHover" class="xl:w-24 xl:h-24 w-10 h-10" alt="" /> -->
                <p class="uppercase font-semibold text-base xl:text-3xl mt-4">
                  Session {{ module.order }}
                </p>
                <hr class="text-white mt-2" />
                <p class="page-desc !w-full mt-2">{{ module.name }}</p>
              </div>
            </router-link>

            <div v-else>
              <div class="p-2 xl:p-4">
                <img :src="'/img/modules/Module-' + module.id + '-lock.png'" alt="">
                <!-- <img v-else src="/img/lock.png" class="xl:w-24 w-10" alt="" /> -->
                <p class="uppercase font-semibold text-base xl:text-3xl mt-4">
                  Session {{ module.order }}
                </p>
                <hr class="text-white mt-2" />
                <p class="page-desc !w-full mt-2">{{ module.name }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModaleModules
      v-if="isModal"
      :moduleOrder="moduleOrder"
      :moduleName="moduleName"
      @close-popup="closePopup"
    />

    <ModaleQuiz
      v-if="isModalQuiz"
      :adventureName="adventure.name"
      :module="moduleQuiz"
      :isValidate="isValidate"
      :percent="percent"
      :min="min"
      :adventureRef="adventure.reference"
      @close-popup-quiz="closePopupQuiz"
      @open-score="isScore = true"
    />
    
    <modal-scene v-if="isScore">
      <div 
        v-for="(question, key, index) in questions"
        :key="index"
        class="bg-white my-2 w-10/12 m-auto "
      >
        <div v-if="index != 0" class="bg-blue rounded-full mt-6 lg:mt-8 mb-6 lg:mb-8 h-[3px]"/>

        <template v-if="question[0] && question[0].order != 0 && question[0].order != null">
          <p
            :class="corrects[key] == 1 ? 'text-green' : 'text-red'"
            class="text-lg lg:text-xl font-bold my-5"
            v-text="
              questionsText[key] + ' ' +  (corrects[key] == 1 ? 'VRAI' : 'FAUX')
            "
          ></p>

          <div
            v-for="answer in getOrder(question)"
            :key="answer.id"
            class="flex justify-center w-full mb-1"
          >
            <div class="m-auto w-2/12 bg-blue-dark rounded-full text-white text-bold w-8 h-8 mr-2">
            <p class="m-auto mt-1">{{answer.order}}</p>
            </div>
            <p v-html="answer.text" class="w-10/12 text-left text-blue-dark font-semibold text-sm lg:text-lg">
            </p>
          </div>
        </template>

        <template v-else>
          <p
            :class="corrects[key] == 1 ? 'text-green' : 'text-red'"
            class="text-lg lg:text-xl font-bold my-5"
            v-text="
              questionsText[key] + ' ' + (corrects[key] == 1 ? 'VRAI' : 'FAUX')
            "
          ></p>

          <div
            v-for="answer in corrects[key] == 1
              ? goodAnswers(question)
              : badAnswers(question)"
            :key="answer.id"
            class="flex justify-center w-full mb-1"
          >
            <div class="w-1/12">
              <img
                class="w-8"
                :src="corrects[key] == 1 ? '/img/check.svg' : '/img/cross.svg'"
                alt=""
              />
            </div>
            <p v-html="answer.text" class="w-10/12 text-left text-blue-dark font-semibold text-sm lg:text-lg">
            </p>
          </div>

          <hr class="mt-4 mb-4" />

          <div
            v-for="answer in corrects[key] == 1
              ? badAnswers(question)
              : goodAnswers(question)"
            :key="answer.id"
            class="flex justify-center w-full mb-1"
          >
            <div class="w-1/12">
              <img
                class="w-8"
                :src="corrects[key] == 1 ? '/img/cross.svg' : '/img/check.svg'"
                alt=""
              />
            </div>
            <p v-html="answer.text" class="w-10/12 text-left text-blue-dark font-semibold text-sm lg:text-lg">
            </p>
          </div>
        </template>
      
      </div>
      <button
        @click="isScore = false"
        class="bg-blue hover:bg-blue-hover rounded-full text-white px-6 py-2 my-2"
      >
        Fermer
      </button>
    </modal-scene>
  </div>
</template>

<script>
import apiManager from '../../datas/apiManager'
import ModaleModules from '../../components/ModaleModules.vue'
import ModaleQuiz from '../../components/ModaleQuiz.vue'
import ModalScene from '@/components/scene/components/modal/ModalScene'

export default {
  components: {
    ModaleModules,
    ModaleQuiz,
    ModalScene
  },
  name: 'TrainingModules',
  data() {
    return {
      modules: [],
      moduleQuiz: null,
      userAnswers: [],
      completedModules: [],
      adventure: null,
      imgHover: '/img/arrow.svg',
      isModal: this.moduleOrder ? true : false,
      isModalQuiz: this.percent ? true : false,
      retour_img: '/img/btn_retour.svg',
      questionsText: [],
      questions: [],
      correct: [],
      isScore : false,
    }
  },
  props: {
    idAdventure: [String, Number],
    idThematic: [String, Number],
    moduleOrder: [String, Number],
    moduleName: [String, Number],

    isValidate: [Boolean, Number, String ],
    percent: [String, Number],
    min: [String, Number]
  },
  computed: {
    user() {
      return this.$store.state.account.data
    }
  },
  methods: {
    closePopup() {
      this.isModal = false
    },
    closePopupQuiz() {
      this.isModalQuiz = false
    },
    prevRoutePath(refAdventure) {
      if (refAdventure == 'LOC') {
        return 'CommunLoc'
      }

      if (refAdventure == 'COL') {
        return 'CommunCol'
      }

      return 'Thematics'
    },
    prevRouteParams(refAdventure) {
      if (refAdventure == 'LOC') {
        return null
      }

      if (refAdventure == 'COL') {
        return null
      }

      return this.modules[0] ? this.modules[0].thematic.job.id : null
    },
    goodAnswers(answers) {
      let goodAnswers = []
      answers.forEach((answer) => {
        if (answer.is_correct == 1) {
          goodAnswers.push(answer)
        }
      })

      return goodAnswers
    },
    badAnswers(answers) {
      let badAnswers = []

      answers.forEach((answer) => {
        if (!answer.is_correct == 1) {
          badAnswers.push(answer)
        }
      })

      return badAnswers
    },
    getOrder(answers) {
      return answers.sort((a, b) => (a.order > b.order) ? 1 : (a.order < b.order) ? -1 : 0);
    },
    getAnswersData() {
      if (this.moduleQuiz != undefined) {
        let data = {
          module_id: this.moduleQuiz.id,
          user_id: this.user.id
        }

        apiManager.getAnswersQuiz(data).then((response) => {
          this.questions = response.data.answers
          this.questionsText = response.data.questions
          this.corrects = response.data.corrects
        })
      }
    }
  },
  created() {
    apiManager
      .getModules(this.idAdventure, this.idThematic)
      .then((response) => {
        this.modules = response.data.data
        this.moduleQuiz = response.data.data.find(module => module.is_quizz == 1)
      })

    apiManager
      .getUserAnswer(this.idAdventure, this.idThematic, this.user.id)
      .then((response) => {
        this.userAnswers = response.data
      })

    apiManager
      .getCompletedModules(this.idAdventure, this.idThematic, this.user.id)
      .then((response) => {
        this.completedModules = response.data
      })

    apiManager.getAdventure(this.idAdventure).then((response) => {
      this.adventure = response.data.data
    })
    
    this.getAnswersData()
  },
  beforeUpdate() {
    this.getAnswersData()
  },
  // beforeRouteEnter(to, from, next) {
  //     next(vm => {
  //         // console.log(from)
  //         vm.prevRoute = from;
  //     });
  // },
}
</script>
