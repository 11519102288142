<template>
  <div class="absolute top-5 w-full flex h-24">
    <div class="flex justify-between w-11/12 ml-10" style="z-index: 49">
      <router-link
        v-if="module"
        :to="{
          name: 'Modules',
          params: {
            idAdventure: module.adventure_id,
            idThematic: module.thematic_id ? module.thematic_id : 0
          }
        }"
      >
        <img
          :src="retour_img"
          @mouseover="retour_img = '/img/btn_quit_hover.svg'"
          @mouseleave="retour_img = '/img/btn_quit.svg'"
          alt=""
          class="mt-5"
        />
      </router-link>

      <img
        :src="suivant_img"
        @click="nextStep()"
        @mouseover="suivant_img = '/img/btn_suivant_hover.svg'"
        @mouseleave="suivant_img = '/img/btn_suivant.svg'"
        alt=""
        class="w-44 cursor-pointer"
      />
    </div>
  </div>

  <div v-if="!isPlay" class="absolute bottom-[40%] w-full">
    <div
      class="flex justify-center dialog-container w-fit cursor-pointer"
      @click="playVideo()"
    >
      <p class="">Lancer la vidéo</p>
    </div>
  </div>
</template>

<script>
import StepMixin from '@/components/scene/components/mixins/StepMixin'

export default {
  emits: ['is-view', 'is-next-practice'],
  name: 'VideoScene',
  mixins: [StepMixin],
  components: {},
  data() {
    return {
      retour_img: '/img/btn_quit.svg',
      suivant_img: '/img/btn_suivant.svg',
      isPlay: false
    }
  },
  methods: {
    playVideo() {
      let video = document.getElementById(this.step.animation.uuid)
      video.play()
      this.isPlay = true
    }
  },
  watch: {
  },
  updated() {
  },
  created() {
    var audio = document.getElementById("audio-general");
    if (audio) {
      audio.volume = 0.;
    }
  },
  beforeUnmount() {
    var audio = document.getElementById("audio-general");
    if (audio) {
      audio.volume = 0.04;
    }
  }
}
</script>

<style scoped>
.dialog-container {
  box-shadow: 10px 10px 2px 1px rgb(255, 103, 119);
}

.dialog-container {
  @apply bg-purple rounded-full text-white text-2xl m-auto text-2xl p-6 text-center;
}
</style>
