<template>
  <div class="top-left-back-btn">
    <div>
      <router-link :to="{ name: 'Menu' }">
        <img src="/img/logo.svg" alt="" />
      </router-link>
    </div>
    <div>
      <router-link :to="{ name: route }">
        <img
          :src="retour_img"
          @mouseover="retour_img = '/img/btn_retour_hover.svg'"
          @mouseleave="retour_img = '/img/btn_retour.svg'"
          alt=""
          class="mt-5"
        />
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeReturn',
  data() {
    return {
      retour_img: '/img/btn_retour.svg'
    }
  },
  props: {
    route: [String, Number]
  },
  methods: {},
  created() {}
}
</script>

<style></style>
