<template>
  <div
    class="content bg-[url('@/assets/img/login_bg.svg')] bg-no-repeat bg-cover"
  >
    <div class="flex">
      <HomeReturn :route="'CommunExp'" />
    </div>

    <div class="text-6xl w-full text-center mb-6 font-semibold">
      <span class="text-blue">{{ adventure.character }}</span>
    </div>
    <div class="text-2xl uppercase w-full text-center mb-6">
      {{ adventure.name }}
    </div>

    <div class="flex items-end justify-evenly w-screen select-none">
      <img
        :src="'src/assets/img/training/' + getImageName(adventure.reference)"
        class="m-auto"
        alt=""
      />
      <div
        class="relative bg-white p-8 text-center text-2xl h-80 flex flex-col justify-evenly"
      >
        <p class="mb-2">{{ adventure.introduction }}</p>
        <router-link :to="{ name: 'Menu' }" class="button"
          >C'EST PARTI !</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import HomeReturn from '@/components/HomeReturn.vue'
export default {
  components: { HomeReturn },
  name: 'TrainingCommun',
  data() {
    return {
      retour_img: '/img/btn_retour.svg'
    }
  },
  props: {
    data: {
      type: String
    }
  },
  computed: {
    user() {
      return this.$store.state.account.data
    },
    adventure() {
      return JSON.parse(this.data)
    }
  },
  methods: {
    getImageName(reference) {
      if (reference == 'LOC') {
        return '/experience_loc.svg'
      }

      return '/experience_col.svg'
    }
  },
  created() {}
}
</script>
