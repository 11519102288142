<template>
  <div class="flex justify-center items-center h-screen">
    <div class="responsive-box bg-orange-light">
      <img src="" alt="" />
      <div
        class="bg-[url('@/assets/img/login_bg.svg')] bg-no-repeat w-full h-full flex justify-center items-center bg-bottom bg-contain"
      >
        <div
          class="bg-white xl:w-[45%] w-1/2 xl:h-fit lg:h-2/3 rounded-3xl shadow-custom shadow-green-light border border-gray"
        >
          <div class="p-5 xl:p-12 flex flex-col">
            <h2 class="title text-center !leading-7">Je me</h2>
            <h2 class="title text-center !mt-0">connecte</h2>
            <form class="flex flex-col">              
              <div v-if="errorMessage != ''" ref="error" class="text-center text-red font-bold mt-2">{{errorMessage}}</div>
              <div ref="success" class="text-center text-green font-bold">
                {{ message }}
              </div>

              <div>
                <input
                  ref="loginInput"
                  name="email"
                  type="email"
                  class="border border-blue w-full lg:text-2xl mt-2 lg:mt-6 lg:p-2"
                  placeholder="Email"
                  :rules="'required'"
                  v-model="input.email"
                  v-on:keyup.enter="login()"
                />
              </div>
              <div>
                <input
                  ref="passwordInput"
                  name="password"
                  type="password"
                  autocomplete="on"
                  class="border border-blue w-full lg:text-2xl mt-2 lg:mt-6 lg:p-2"
                  placeholder="Mot de passe"
                  :rules="'required'"
                  v-model="input.password"
                  v-on:keyup.enter="login()"
                />
              </div>
              <div>
                <div class="text-center py-2 mt-2 lg:mt-6">
                  <router-link
                    class="underline underline-offset-2"
                    :to="{ name: 'ForgetPassword' }"
                  >
                    J'ai oublié mon mot de passe
                  </router-link>
                </div>
              </div>
              <div class="w-full flex justify-center">
                <button
                  class="button sm:!text-lg !bg-red hover:!bg-orange rounded-full"
                  type="button"
                  @click="login()"
                >
                  SUIVANT
                </button>
                <!-- <button type="" class="px-20 py-5 bg-red text-white hover:bg-orange">SUIVANT</button> -->
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import taskManager from '../datas/taskManager'
import router from '../router'

export default {
  components: {
  },
  name: 'ViewLogin',
  data() {
    return {
      input: {
        email: '',
        password: ''
      },
      errorMessage : '',
    }
  },
  props: {
    message: [String]
  },
  computed: {
    user() {
      return this.$store.state.account.data
    }
  },
  methods: {
    login() {
      if (this.input.email != '' && this.input.password != '') {
        taskManager.login(
          this.input.email,
          this.input.password,
          this.sucessLogin,
          this.failedLogin
        )
      } else {
        this.displayError('Tous les champs doivent être complétés.')
      }
    },
    sucessLogin() {
      this.$refs.passwordInput.value = ''
      router.push({ name: 'Menu' })
    },
    failedLogin(error) {
      this.$refs.passwordInput.value = ''
      this.displayError(error.data.message ? error.data.message : 'Les identifiants sont incorrects !')
    },
    displayError(message) {
      this.errorMessage = message
    }
  },
  beforeCreate() {
    document.body.className = 'login'
  }
}
</script>
