const mixinBaseUrl = {
  data() {
    return {
    }
  },
  computed: {
    soundOn() {
      return this.$store.state.account.soundOn
    },
    baseUrl() {
      if (window.location.origin.includes('localhost')) {
        return 'http://localhost:8000'
      } else {
        return process.env.VUE_APP_API_URL
      }
    }
  }
}

export { mixinBaseUrl }
