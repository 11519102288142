<template>
  <div class="modal-box">
    <div class="modal-blur" @click="closePopup()"></div>
    <div class="modal-inner">
      <div class="flex justify-end cursor-pointer">
        <img
          src="/img/close.png"
          alt=""
          class="w-6 m-2"
          @click="closePopup()"
        />
      </div>
      <img src="/img/congrat.png" alt="" class=" w-[50%] lg:w-[80%] m-auto" />
      <p class="uppercase text-blue-dark font-bold text-sm lg:text-xl">
        vous avez complété le module
      </p>

      <div
        class="mt-6 bg-blue-lightest border-blue p-4 lg:px-12 border-r-8 border-b-8 rounded-xl w-fit m-auto text-left text-sm lg:text-2xl"
      >
        <p class="uppercase text-blue-dark font-bold">
          Session {{ moduleOrder }}
        </p>
        <p class="text-blue-dark font-semibold mt-3">{{ moduleName }}</p>
      </div>

      <div
        class="mt-6 bg-blue hover:bg-blue-hover rounded-full w-fit p-2 m-auto flex mb-6 cursor-pointer"
        @click="closePopup()"
      >
        <img src="/img/check.svg" alt="" class="w-7 mr-2" />
        <p class="uppercase text-white mr-3">Module validé</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  watch: {},
  name: 'ModalScene',
  methods: {
    closePopup() {
      this.$emit('close-popup')
    }
  },
  props: {
    moduleOrder: [String, Number],
    moduleName: [String, Number]
  },
  data() {}
}
</script>

<style scoped>
.overlay {
  @apply absolute top-0 h-full w-full
	bg-black opacity-20;
}

.modal-inner {
  @apply z-10 p-5 w-4/6 text-center
  border-green-light p-4 border-b-8
	bg-white rounded-3xl;
}
</style>
