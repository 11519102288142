<template>
  <div class="flex flex-col justify-center items-center h-screen">
    <div class="responsive-box">
      <HomeReturn :route="'Menu'" />
      <audio autoplay controls :muted="soundOn && fixSound ? false : true"
        class="absolute z-[999] w-48 h-48 top-0 hidden"  
        @loadeddata="loadAudio"
        ref="audio-col"
        :src="baseUrl + '/sounds/intro.mp3'"
        id="audio-col"
      ></audio>
      <div>
        <div class="text-center xl:mb-5 pt-[15vmin]">
          <h2 class="title">Formations</h2>
          <div class="w-full flex flex-col justify-center">
            <p class="page-desc mx-auto">
              Bienvenu dans Welc'home !
            </p>  
            <p class="page-desc mx-auto !w-5/6 mt-2">
              Le parcours d'intégration digital d'habitat 76. 
            </p>
            <p class="page-desc mx-auto !w-5/6 mt-2">
              Différentes missions t'attendent. Découvre-les vite en parcourant les modules « Welc'home chez habitat76 » 
            </p>
          </div>
        </div>
      </div>
      <div class="card-container">
        <div class="card !w-[60vmin]">
          <img src="/img/training_tc.png" alt="" class="w-[250px]"/>
          <h2 class="card-title">WELC'HOME CHEZ HABITAT 76 !</h2>
          <router-link
            :to="{ name: 'CommunExp' }"
            class="button !bg-red hover:!bg-orange rounded-full"
            >C'EST PARTI !</router-link
          >
        </div>
        <div
          v-if="user.job != null"
          class="card"
          :class="user.job != null ? '' : 'opacity-40'"
        >
          <img src="/img/training_im.png" alt="" class="w-[250px]" />
          <h2 class="card-title">J'EXPLORE MON JOB !</h2>
          <!-- <router-link
            :to="{ name: 'JobExp' }"
            v-if="user.job != null"
            href=""
            class="button !bg-red hover:!bg-orange rounded-full"
            >C'EST PARTI !</router-link
          > -->
          <router-link
            :to="{
              name: 'CommunJob',
              params: {
                id: 3
              }
            }"
            v-if="user.job != null"
            href=""
            class="button !bg-red hover:!bg-orange rounded-full"
            >C'EST PARTI !</router-link
          >
          <div v-else class="button !bg-red hover:!bg-orange rounded-full">
            C'EST PARTI !
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import HomeReturn from '@/components/HomeReturn.vue'
export default {
  components: { HomeReturn },
  name: 'ViewTraining',
  data() {
    return {
      fixSound : false,
    }
  },
  methods: {
    loadAudio() {
      this.fixSound = false
      this.timeout1 = setTimeout(() => {
        this.fixSound = true
      }, 500);

      if (this.$refs['audio-col'] && this.$refs['audio-col'].duration * 1000 - 500 > 0) {
        this.timeout2 = setTimeout(() => {
          this.fixSound = false
          var audio = document.getElementById("audio-general");
          if (audio) {
            audio.volume = 0.1;
          }
        }, this.$refs['audio-col'].duration * 1000 - 500);
      }
    }
  },
  computed: {
    user() {
      return this.$store.state.account.data
    }
  },
  beforeUnmount() {
    clearTimeout(this.timeout1)
    clearTimeout(this.timeout2)
  },
}
</script>
