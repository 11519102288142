<template>
 <overlay-scene
    v-if="isNext"
    @overlay-clicked="completedDialog()"
  ></overlay-scene>
  <!--	Section question-->
  <div class="absolute top-5 w-full flex h-24">
    <div class="flex ml-10 w-24 lg:w-52" style="z-index: 49">
      <router-link
        v-if="module"
        :to="{
          name: 'Modules',
          params: {
            idAdventure: module.adventure_id,
            idThematic: module.thematic_id ? module.thematic_id : 0
          }
        }"
      >
        <img
          :src="retour_img"
          @mouseover="retour_img = '/img/btn_quit_hover.svg'"
          @mouseleave="retour_img = '/img/btn_quit.svg'"
          alt=""
          class="lg:mt-5"
        />
      </router-link>
    </div>
  </div>

  <PracticeQuestion v-if="!step.options.isChoice && !step.options.isOrder" :subStep="subStep" :isNext="isNext" :nbGoodAnswers="nbGoodAnswers" :answers="answers" :step="step" @submit="submit" @nextPractice="nextStepPractice"/>

  <PracticeOrder v-if="step.options.isOrder" :subStep="subStep" :isNext="isNext" :nbGoodAnswers="nbGoodAnswers" :answers="answers" :step="step" @submit="submitOrder" @nextPractice="nextStepPractice"/>

  <PracticeChoice v-if="step.options.isChoice" :subStep="subStep" :isNext="isNext" :nbGoodAnswers="nbGoodAnswers" :answers="answers" :step="step" @submit="submit" @nextPractice="nextStepPractice"/>

  <div v-if="isNext" class="absolute bottom-5 w-full h-full flex justify-center">
    <div class="absolute bottom-5 w-2/3 flex justify-center lg:!pt-12 py-3">
      <div class="w-[95%]">
        <img v-if="step.options.think" src="/img/cloudup2.svg" alt="" class="w-[84%] mx-auto">
        <div class="dialog-container relative" :class="isContextOrEnd() +' '+ colorBorder()">
        <TypingDialog
          :lastScene="false"
          :dialog="decodeHTML(nextText)"
          :firstClick="firstClick"
          @isDebrief="debrief"
          :isDialogComplete="isDialogComplete"
          @completedDialog="completedDialog()"
        />
        </div>
        <img v-if="step.options.think" src="/img/clouddown2.svg" alt="" class="w-[84%] mx-auto">
      </div>
    </div>
    <!-- <div class="w-2/3 flex justify-center dialog-container !py-12">
      <TypingDialog
        :lastScene="false"
        :dialog="decodeHTML(nextText)"
        @isDebrief="debrief"
        :isDialogComplete="isDialogComplete"
      />
    </div> -->
  </div>
</template>

<script>
import StepMixin from '@/components/scene/components/mixins/StepMixin'
import apiManager from '../../../../datas/apiManager'
import TypingDialog from '@/components/scene/components/TypingDialog.vue'
import PracticeQuestion from '@/components/scene/components/step/PracticeQuestion.vue'
import PracticeOrder from '@/components/scene/components/step/PracticeOrder.vue'
import PracticeChoice from '@/components/scene/components/step/PracticeChoice.vue'
import store from '../../../../store/index'

export default {
  emits: ['is-view', 'is-next-practice'],
  name: 'QuestionYesNoScene',
  mixins: [StepMixin],  
  components: {
    TypingDialog,
    PracticeQuestion,
    PracticeOrder,
    PracticeChoice
  },
  data() {
    return {
      result: null,
      isNext: false,
      subStep: 0,
      nextText:"",
      goodAnswers: [],
      badAnswers: [],
      answersChoice : [],
      nbGoodAnswers: 0,
      nbGoodChoiceAnswers: 0,
      nbBadChoiceAnswers: 0,
      isDialogComplete: false,
      firstClick: false,
      retour_img: '/img/btn_quit.svg',
    }
  },
  computed: {
    user() {
      return this.$store.state.account.data
    }
  },
  methods: {
    colorBorder() {
      if (this.isContext() && !this.step.options.think) {
        return 'shadow-dialog-green'
      }
      if (this.step.options.think) {
        return ''
      } 
    
      return 'shadow-dialog-blue'
    },
    completedDialog() {
      this.isDialogComplete = true
      if (
        this.forceSkip &&
        (this.firstClick || this.nextText == null || this.nextText == '')
      ) {
        this.submit()
        // this.forceNextStep()
        return
      }
      // this.firstClick ? this.nextStep() : ''
      this.firstClick ? this.submit() : ''

      this.firstClick = !this.firstClick
    },
    isContextOrEnd() {
      if (this.lastScene) {
        return 'bg-[#00627B] !text-white'
      }   
      if (this.step.options.think) {
        return 'bg-white !text-blue-dark ' + 'thought'
      }
      if (this.isContext()) {
        return 'bg-white !text-blue-dark'
      }
      return 'border-blue border-2 lg:border-[3px] bg-blue-lightest !text-blue-dark ' + (this.step.options.arrowLeft && this.step.options.arrowLeft == true ? 'arrow-left' : 'arrow')
    },
    isContext() {
      const words = this.step.options.dialog ? this.step.options.dialog.split(']') : []
      if (words.length == 1) {
        return true
      }
      return false
    },
    decodeHTML(text) {
      var txt = document.createElement('textarea')
      txt.innerHTML = text

      return txt.value
    },
    debrief(value) {
      this.firstClick = value
    },
    nextStepPractice(isNext, nextText, answersChoice) {
      this.answersChoice = answersChoice
      this.isNext = isNext
      this.nextText = nextText
      this.$emit('is-next-practice', this.step.options.next_dialog_video ? this.step.options.next_dialog_video : null)
    },
    submit(answersChoice) {
      if (answersChoice) {
        this.answersChoice = answersChoice
      }
      this.answersChoice.forEach((answerChoice) => {
        if (answerChoice.id) {
          if (this.step.options.goodAnswers.includes(answerChoice.id)) {
            this.nbGoodChoiceAnswers++
          } else {
            this.nbBadChoiceAnswers++
          }
        } else {
          if (this.step.options.goodAnswers.includes(answerChoice)) {
            this.nbGoodChoiceAnswers++
          } else {
            this.nbBadChoiceAnswers++
          }
        }
        
      })

      this.result = !(
        this.nbGoodChoiceAnswers != this.nbGoodAnswers ||
        this.nbBadChoiceAnswers > 0
      )
     
      this.subStep = 1
      this.isNext = false
      this.nextText = ''
      //! il faudrait envoyé ici le tableau des réponses ...?
      this.send()
    },
    submitOrder(answersChoice) { 
      if (answersChoice) {
        this.answersChoice = answersChoice
      }
      this.answersChoice.forEach((answerChoice, index) => {
        if (answerChoice.order == (index+1)) {
          this.nbGoodChoiceAnswers++
        } else {
          this.nbBadChoiceAnswers++
        }
      })

      this.result = this.nbBadChoiceAnswers > 0 ? false : true

      this.subStep = 1
      this.isNext = false
      this.nextText = ''
      this.send()
    },
    displayAnswer() {},
    //! TODO
    // send the response here
    send() {
      let data = {
        is_correct: this.result,
        question_id: this.step.options.id,
        // answer_id: answersId,
        user_id: this.user.id
      }

      apiManager.sendAnswersGame(data).then(() => {
        if (this.step.options.forceGood && !this.result) {
          this.updateSteps(this.step.uuid, 'ConclusionScene', 'bad')
        }

        const indexStep = this.steps.findIndex(scriptStep => scriptStep.uuid == this.step.uuid);
        let afterScript = this.steps.slice(indexStep+1)
        const isPractice = afterScript.find(scriptStep => scriptStep.type == 'PracticeScene');
        //Si c'est ma dernière practice scène, il faut faut savoir si j'ai eu tout bon pour savoir quelle conclusion mettre
        if (!isPractice) {
          // let data = {
          //   module_id: this.module.id,
          //   set: this.step.options.set,
          //   user_id: this.user.id
          // }

          // apiManager.getAnswersSet(data).then(() => {
            //! ici bon si gesiton locative
            // if (this.module.thematic_id == 1) {
              this.updateSteps(this.step.uuid, 'ConclusionScene', 'good' , true)
            // } 
            // else {
            //   this.updateSteps(this.step.uuid, 'ConclusionScene', (response.data.isAllCorrect ? 'good' : 'bad'), true)
            // }
            if (this.forceSkip) {
              this.forceNextStep()
              return
            }
            this.nextStep()
          // })
          
          return
        }

        if (this.forceSkip) {
          this.forceNextStep()
          return
        }
        this.nextStep()
      })
    },
    getAnswersData() {
      let goodAnswers = []
      let badAnswers = []

      let nbGoodAnswers = 0

      this.answers.forEach((answer) => {
        if (answer.is_correct == 1) {
          goodAnswers.push(answer)
          nbGoodAnswers++
        } else {
          badAnswers.push(answer)
        }
      })
      this.nbGoodAnswers = nbGoodAnswers
      this.goodAnswers = goodAnswers
      this.badAnswers = badAnswers

      this.result = null
      this.subStep = 0
      this.nbGoodChoiceAnswers = 0
      this.nbBadChoiceAnswers = 0
    }
  },
  watch: {
    answers() {
      this.isDialogComplete = false
      this.firstClick = false
      this.isNext = false
      this.nextText = ''
      this.getAnswersData()
    }
  },
  created() {
    this.getAnswersData()
    store.commit('account/_set', { k: 'isAllCorrect', v: null })
  }
}
</script>

<style scoped>
.dialog-container {
  @apply rounded-xl text-sm my-auto lg:text-2xl p-4 lg:p-12 text-center w-full;
}

.question-container {
  @apply bg-blue-light rounded-3xl;
}

.answer-container {
  /* background-color: RGB(123, 43, 69); */
  user-select: none; /* standard syntax */
  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -khtml-user-select: none; /* webkit (konqueror) browsers */
  -ms-user-select: none; /* IE10+ */
}

.dialog-container {
  @apply text-white my-auto text-sm lg:text-2xl p-4 text-center lg:p-12 w-full;
}

.question-container {
  @apply text-white m-auto text-sm lg:text-xl p-4 text-center;
}
</style>
