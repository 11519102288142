<template>
  <template v-for="anim in animations" :key="anim.uuid">
    <template v-if="anim.type === 'video'">
      <video-controller
        :id="anim.uuid"
        :src="anim.path"
        :ref="'animation-' + anim.uuid"
        :step="step"
        :afterAnimShown="afterAnimShown"
        :muted="step && step.options.video ? false : true"
        :controls="step && step.options.video ? true : false"
        v-show="anim.uuid === ctAnimUuid"
        @endedVideo="next($event)"
        @loaded="loaded"
      />
    </template>
  </template>
</template>

<script>
import VideoController from '@/components/scene/components/animation/VideoController'

export default {
  name: 'AnimationManager',
  components: { VideoController },
  props: {
    animations: {
      type: Object
    },
    step: {
      type: Object
    },
    isNextPractice: {
      type: Boolean
    },
    isStart: {
      type: Boolean
    },
    nextVideoPractice: {
      type: String
    },
  },
  data() {
    // 'ct' for 'current'
    return {
      animLoaded: 0,
      ctAnimUuid: null,
      ctStepUuid: null,
      afterAnimShown: false
    }
  },
  methods: {
    loaded() {
      this.animLoaded++
      this.$emit('loading', this.animLoaded, null)
      if (this.animLoaded === this.animations.length) {
        this.$emit('loaded')
      }
    },
    next() {
      // filter invalid event
      // if (e === undefined) return

      // start the animation
      if (this.step) {
        // console.log(this.step)
        this.$emit('ended')
        // console.log(this.ctAnimUuid, this.step.animation.uuid)
        if (
          this.ctAnimUuid !== this.step.animation.uuid &&
          !this.afterAnimShown
        ) {
          this.playAnim(this.step.animation.uuid)
          this.ctAnimUuid = this.step.animation.uuid
          this.ctStepUuid = this.step.uuid
        }

        // start the animation that should play after the current
        else if (
          this.step.animation.after !== null &&
          !this.step.options.video
        ) {
          this.playAnim(this.step.animation.after)
          this.afterAnimShown = true
          this.ctAnimUuid = this.step.animation.after
        } 

        else if ( this.step.animation.after == null &&
          !this.step.options.video) {
          this.$emit('force-skip')
        }
      }
    },
    skip() {
      this.$refs['animation-' + this.ctAnimUuid][0].reset()
      this.next(true)
    },
    playAnim(ref) {
      if (!this.step.options.video && this.isStart) {
        console.log(
          '------Play ' +
            this.$refs['animation-' + (ref ?? this.ctAnimUuid)][0].src
        )

        this.$refs['animation-' + (ref ?? this.ctAnimUuid)][0].play()
      }
    }
  },
  updated() {
    // if (this.step) {
    //   // disable 'after' animation when step change
    //   if (this.ctStepUuid !== this.step.uuid) {
    //     this.afterAnimShown = false
    //     this.next(true)
    //   }

    //   // set initial value when data are loaded and start first animation
    //   if (this.ctStepUuid === null) {
    //     this.ctStepUuid = this.step.uuid
    //     this.ctAnimUuid = this.step.animation.uuid
    //     this.playAnim()
    //   }
    // }
  },
  watch: {
    isNextPractice(newValue) {
      if (newValue) {
        this.ctAnimUuid = this.nextVideoPractice
        this.playAnim(this.nextVideoPractice)
        this.afterAnimShown = false
      }
      this.$emit('next-end')
    },
    step(newValue) {
      if (newValue) {
        // disable 'after' animation when step change
        if (this.ctStepUuid !== newValue.uuid) {
          this.afterAnimShown = false
          this.next(true)
        }

        // set initial value when data are loaded and start first animation
        if (this.ctStepUuid === null) {
          this.ctStepUuid = newValue.uuid
          this.ctAnimUuid = newValue.animation.uuid
          this.playAnim()
        }
      }
    },
    isStart(newValue) {
      if (newValue) {
        this.playAnim()
      }
    },
  },
  emits: ['loaded', 'ended','next-end', 'loading', 'force-skip']
}
</script>

<style scoped></style>
