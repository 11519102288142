<template>
  <template v-if="user">
    <Integration v-if="user.game_mode == 'integration'" />
    <PreIntegration v-else />
  </template>
</template>

<script>
import Integration from './Menu/Integration.vue'
import PreIntegration from './Menu/PreIntegration.vue'

export default {
  components: {
    Integration,
    PreIntegration
  },
  name: 'ViewMenu',
  data() {
    return {}
  },
  computed: {
    user() {
      return this.$store.state.account.data
    }
  },
  methods: {},
  created() {}
}
</script>
