<template>
  <div class="flex justify-center items-center h-screen">
    <div
      class="responsive-box !bg-blue-light"
    >
      <HomeReturn :route="'CommunExp'" />
      <audio autoplay controls :muted="soundOn && fixSound ? false : true"
        class="absolute z-[999] w-48 h-48 top-0 hidden"
        @loadeddata="loadAudio"
        ref="audio-col"
        :src="baseUrl + '/sounds/intro_lea.mp3'"
        id="audio-col"
      ></audio>


      <div class="flex flex-col pt-[15vmin] xl:pt-[18vmin] h-full">

        <div class="flex justify-center select-none h-full lg:h-fit">
          <img
            src="/img/training/lea.gif"
            class="absolute z-10 w-[30%] left-[10%] hidden lg:block drop-shadow-lg"
            alt=""
          />
          <div
            class="relative bg-white p-4 xl:p-16 text-center text-sm lg:text-2xl h-full flex flex-col justify-between rounded-3xl w-8/12 lg:w-[46%] shadow-lg py-10 overflow-y-auto"
          >
            <div class="title w-full text-center lg:mb-4">
              <span class="title">Léa</span>
            </div>
            <div class="barlow page-desc uppercase !w-full text-center mb-4 mt-4 !text-lg lg:!text-xl">
              vis ma vie de locataire
            </div>
            
            <hr class="mb-4" style="height:2px;border:none;color:#68CAD7;background-color:#68CAD7;" >
            <p class="barlow text-blue-dark text-sm xl:text-lg">
              Voici Léa. 
            </p>
            <p class="barlow-no text-blue-dark text-sm xl:text-lg mt-4">
              Léa est étudiante, il est temps pour elle de voler de ses propres ailes.
            </p>
            <p class="barlow-no text-blue-dark text-sm xl:text-lg">
              Elle recherche son premier logement. 
            </p>
            <p class="barlow text-blue-dark text-sm xl:text-lg mt-4">
              Ta mission ? 
            </p>
            <p class="barlow-no text-blue-dark text-sm xl:text-lg mt-4">
              L’aider à prendre son envol en la guidant tout au long de son parcours locatif. 
            </p>
            <router-link
              :to="{
                name: 'Modules',
                params: {
                  idAdventure: 1,
                  idThematic: 0
                }
              }"
              class="button w-74 mx-auto !mt-4 xl:!mt-[25px] uppercase"
              >A toi de jouer !</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HomeReturn from '@/components/HomeReturn.vue'
export default {
  components: { HomeReturn },
  name: 'TrainingCommunLoc',
  data() {
    return {
      retour_img: '/img/btn_retour.svg',
      fixSound : false,
    }
  },
  computed: {
    user() {
      return this.$store.state.account.data
    }
  },
  methods: {
    loadAudio() {
      this.fixSound = false
      this.timeout1 = setTimeout(() => {
        this.fixSound = true
      }, 500);

      if (this.$refs['audio-col'] && this.$refs['audio-col'].duration * 1000 - 1000 > 0) {
        this.timeout2 = setTimeout(() => {
          this.fixSound = false
          var audio = document.getElementById("audio-general");
          if (audio) {
            audio.volume = 0.1;
          }
        }, this.$refs['audio-col'].duration * 1000 - 1000);
      }
    }
  },
  beforeUnmount() {
    clearTimeout(this.timeout1)
    clearTimeout(this.timeout2)
  },
}
</script>
