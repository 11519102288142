<template>
  <template v-for="step in audioSteps" :key="step.uuid">
    <audio controls audio-events :muted="soundOn && fixSound ? false : true"
      class="absolute z-[999] w-48 h-48 top-0 hidden"
      :ref="'audio-' + step.uuid"
      @loadeddata="loadAudio"
      :src="step.animation.sound"
      :id="'audio-' + step.uuid"
    ></audio>
  </template>
</template>

<script>
export default {
  name: 'AudioController',
  emits: ['loadedAudio', 'loading'],
  props: {
    script: {
      type: Object
    },
    step: {
      type: Object
    },
    isStart : {
      type : Boolean
    }
   
  },
  data() {
    return {
      audioSteps : [],
      loadedAudios : 0,
      fixSound : false,
    }
  },
  methods: {
    loadAudio(){
      this.loadedAudios++
      this.$emit('loading', null, this.loadedAudios)
      if (this.loadedAudios === this.audioSteps.length) {
        this.$emit('loadedAudio')
      }
    },
    playAudio() {
      this.$refs['audio-' + this.step.uuid][0].play();

      this.fixSound = false
      setTimeout(() => {
        this.fixSound = true
      }, 500);

      if (this.$refs['audio-' + this.step.uuid][0].duration * 1000 - 500 > 0) {
        this.timeout = setTimeout(() => {
          this.fixSound = false
        }, this.$refs['audio-' + this.step.uuid][0].duration * 1000 - 500);
      }
    }
  },
  mounted() {
    this.audioSteps = this.script.steps.filter(step => step.animation.sound)
    if (this.audioSteps.length === 0) {
      this.$emit('loadedAudio')
    }
  },
  watch : {
    step(newStep, oldStep) {
      if (oldStep && oldStep.animation.sound && this.$refs['audio-' + oldStep.uuid]) {
        this.$refs['audio-' + oldStep.uuid][0].pause();
      }
      if (newStep && this.isStart && newStep.animation.sound && this.$refs['audio-' + newStep.uuid] && this.$refs['audio-' + newStep.uuid][0]) {
        clearTimeout(this.timeout)
        this.playAudio()
      }
    },
    isStart(newValue) {
      if (newValue && this.$refs['audio-' + this.step.uuid] && this.$refs['audio-' + this.step.uuid][0]) {
        this.playAudio()
      }
    }
  }
}
</script>

<style scoped></style>
