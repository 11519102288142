import axios from 'axios'
import store from '../store/index'
import router from '../router'

const baseURL =
  process.env.NODE_ENV === 'production'
    ? process.env.VUE_APP_API_URL + '/api'
    : 'http://127.0.0.1:8000/api'

//todo
// const baseURL = 'https://api.welchome.digiworks.fr/api'


let api_token =
  store.state.account.data != null ? store.state.account.data.api_token : ''

const api = axios.create({
  baseURL,
  withCredentials: false,
  headers: { Authorization: 'Bearer ' + api_token }
})

api.interceptors.response.use(function (response) {
  return response
}, function (error) {
  catch401(error)
  return error;
})

function catch401 (error) {
  if (error.response && error.response.status == 401) {
    store.commit('account/_set', { k: 'data', v: null })
    router.push({ name: 'Login' })
  }
}


export default {
  refreshApiToken() {
    api.defaults.headers['Authorization'] =
      'Bearer ' + store.state.account.data.api_token
  },
  login(email, password) {
    return axios('login', {
      baseURL,
      method: 'post',
      data: { email, password }
    })
  },
  forgetPassword(email) {
    return axios('forget-password', {
      baseURL,
      method: 'post',
      data: { email }
    })
  },
  newPassword(password, password_confirmation, token) {
    return axios(`new-password`, {
      baseURL,
      method: 'post',
      data: { password, password_confirmation, token }
    })
  },
  getUser(idUser) {
    return api.get(`/users/${idUser}`)
  },
  getAdventures(refAdventureType) {
    return api.get(`/adventures/${refAdventureType}`)
  },
  getAdventure(idAdventure) {
    return api.get(`/adventure/${idAdventure}`)
  },

  getJobs(refAdventureType) {
    return api.get(`/jobs/${refAdventureType}`)
  },
  getThematics(idJob) {
    return api.get(`/thematics/${idJob}`)
  },
  getModules(idAdventure, idThematic) {
    return api.get(`/modules/${idAdventure}/${idThematic}`)
  },
  getQuestions(idModule) {
    return api.get(`/questions/quizz/${idModule}`)
  },
  getUserAnswer(idAdventure, idThematic, idUser) {
    return api.get(`/user-answers/${idAdventure}/${idThematic}/${idUser}`)
  },
  getCompletedModules(idAdventure, idThematic, idUser) {
    return api.get(`/completed-modules/${idAdventure}/${idThematic}/${idUser}`)
  },
  getDocuments(idUser) {
    return api.get(`/documents/${idUser}`)
  },
  getDocumentsModule(idModule) {
    return api.get(`/documents/module/${idModule}`)
  },
  getCollaborators() {
    return api.get(`/collaborators`)
  },
  getProgressions(idUser) {
    return api.get(`/progressions/${idUser}`)
  },
  getPerformances(idUser) {
    return api.get(`/performances/${idUser}`)
  },
  getPerformanceAdventure(idUser, idAdventure) {
    return api.get(`/performances/${idUser}/${idAdventure}`)
  },
  getStatistic(idUser, idAdventure) {
    return api.get(`/statistics/${idUser}/${idAdventure}`)
  },
  getGraphiques(idUser) {
    return api.get(`/graphiques/${idUser}`)
  },
  getScript(idModule) {
    return api.get(`/script/${idModule}`)
  },
  getPercentageModule(idUser) {
    return api.get(`/completed-modules/${idUser}`)
  },
  sendAnswers(answers) {
    return api.post(`/send-answers`,  {answers})
    // return axios('send-answers', {
    //   baseURL,
    //   method: 'post',
    //   data: { answers }
    // })
  },
  sendAnswersGame(data) {
    return api.post(`/send-answers-game`, data)
    // return axios('send-answers-game', {
    //   baseURL,
    //   method: 'post',
    //   data: { data }
    // })
  },
  getAnswersSet(data) {
    return api.post(`/get-answers-set`, data)
    // return axios('get-answers-set', {
    //   baseURL,
    //   method: 'post',
    //   data: data
    // })
  },
  getAnswersQuiz(data) {
    return api.post(`/get-answers-quiz`, data)
  },
  sendCompletedModule(data) {
    return api.post(`/send-completed-module`, data)
    // return axios('send-completed-module', {
    //   baseURL,
    //   method: 'post',
    //   data: { data }
    // })
  }
}
