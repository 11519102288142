<template>
  <div class="flex justify-center items-center h-screen">
    <div class="responsive-box bg-game_bg bg-no-repeat bg-cover">
      <div class="flex items-end justify-evenly h-full select-none relative">
        <div class="absolute top-[2%] left-[0%] w-[23%] h-fit assetsHomePage">
          <img src="@/assets/img/menu/int/logos.png" alt="" class="w-fit" />
        </div>
        <div class="w-[39%] assetsHomePage h-fit absolute -bottom-[2.5%] left-[1%]">
          <div class="w-fit  cursor-pointer">
            <div
              @click="
                openModal(
                  'Documents',
                  'menu/tool.png',
                  'Boite à outils',
                  'Consultez les documents d’intégration'
                )
              "
            >
              <img
                src="@/assets/img/menu/int/bibliotheque.png"
                alt=""
                class="hover:brightness-110"
              />
            </div>
          </div>
        </div>
        <div class="w-[13%] assetsHomePage h-fit absolute top-[1%] lg:right-[4%] right-[10%]">
          <div class="w-fit  cursor-pointer button">
            <div
              @click="logout()"
            >
              Déconnexion
            </div>
          </div>
        </div>
        <div class="w-[15%] assetsHomePage h-fit absolute top-[4%] right-[35%]">
          <div class="w-fit cursor-pointer">
            <div
              @click="
                openModal(
                  'Profile',
                  'menu/profile.png',
                  'Mon profil',
                  'Accédez à votre profil & vos statistiques'
                )
              "
            >
              <img
                src="@/assets/img/menu/int/diplome.png"
                alt=""
                class="hover:brightness-110"
              />
            </div>
          </div>
        </div>
        <div class="w-[10%] h-fit absolute -bottom-[0%] left-[31%]">
          <div class="w-fit ">
            <div
            >
              <img
                src="@/assets/img/menu/int/plante.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="w-[38%] assetsHomePage h-fit absolute -bottom-[1%] right-[25%]">
          <div class="w-fit cursor-pointer">
            <div
              @click="
                openModal(
                  'Training',
                  'menu/training.png',
                  'Parcours d\'intégration',
                  'Accéder à votre parcours d’intégration'
                )
              "
            >
              <img
                src="@/assets/img/menu/int/bureau.png"
                alt=""
                class="hover:brightness-110"
              />
            </div>
          </div>
        </div>
        
      </div>
    </div>
  </div>

  <Modal
    v-if="isModal"
    :path="activePath"
    :img="activeImg"
    :title="activeTitle"
    :text="activeText"
    @close-popup="closePopup"
  />
</template>

<script>
import Modal from '../../components/Modal.vue'
import taskManager from '../../datas/taskManager'

export default {
  components: {
    Modal
  },
  name: 'MenuIntegration',
  data() {
    return {
      activePath: '',
      activeImg: '',
      activeTitle: '',
      activeText: '',
      isModal: false
    }
  },
  computed: {
    user() {
      return this.$store.state.account.data
    }
  },
  methods: {
    closePopup() {
      this.isModal = false
    },
    openModal(path, img, title, text) {
      this.activePath = path
      this.activeImg = img
      this.isModal = true
      this.activeTitle = title
      this.activeText = text
    },
    logout() {
      taskManager.logout()
    }
  },
  created() {}
}
</script>
