<template>
  <div class="modal-box" id="modal-id">
    <div class="modal-blur" @click="closePopup()"></div>
    <div class="modal-inner relative shadow-dialog-orange h-full flex flex-col">
      <div class="flex justify-end cursor-pointer absolute right-2 lg:right-6">
        <img
          src="/img/close.png"
          alt=""
          class="w-3 xl:w-6 m-2"
          @click="closePopup()"
        />
      </div>
      <div class="flex flex-col h-full">
        <div class="text-lg xl:text-5xl w-full text-center mb-2 xl:mb-6 font-semibold">
        <span class="text-blue">Votre intégration en vidéo !</span>
      </div>

      <div class="flex flex-col h-full select-none overflow-y-auto">
          
        <video controls class="m-auto h-[90%]">
          <source src="/video/ignore/film_integration.mp4" type="video/mp4">
          Download the
          <a href="/video/ignore/film_integration.mp4">MP4</a>
          video.
        </video>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: { },
  emits: ['close-popup'],
  name: 'ViewCarte',
  data() {
    return {}
  },
  computed: {
    user() {
      return this.$store.state.account.data
    }
  },
  methods: {
    closePopup() {
      this.$emit('close-popup')
    },
    getBaseUrlFront() {
      return window.location.origin
    }
  },
  created() {
    var audio = document.getElementById("audio-general");
    if (audio) {
      audio.volume = 0.;
    }
  },
  beforeUnmount() {
    var audio = document.getElementById("audio-general");
    if (audio) {
      audio.volume = 0.04;
    }
  }
}
</script>

<style scoped>
.overlay {
  @apply absolute top-0 h-full w-full
	bg-black opacity-20;
}

.modal-inner {
  @apply z-10 w-[90%] h-[90%] xl:w-[75%] xl:h-[90%] text-center rounded-3xl p-2 xl:p-8 ;
}

.modal-inner {
  background-color: white;
}

</style>
