<template>
  <div v-show="subStep === 0 && !isNext" class="absolute bottom-5 w-full h-[90%]">
    <div class="m-auto w-11/12 lg:w-10/12 question-container h-full flex flex-col justify-between">
      <p class="w-full text-sm lg:text-2xl p-2 font-bold mb-4">{{ step.options.question }}</p>
      
      <div class="h-[65%] lg:h-[80%]">
        <div class="flex justify-center items-center h-full">
          <div class="flex w-[90%] m-auto max-h-full">
              <div class="w-1/2 flex flex-col bg-orange-lightest rounded-3xl p-2 lg:p-4 max-h-full overflow-auto">
                <h3 class="text-center font-bold text-sm lg:text-base">Actions disponibles</h3>
              <div class="m-3 flex-1">
                <draggable
                  class="list-group h-full p-2"
                    :list="listAnswers"
                    group="answer"
                    itemKey="text"
                    :disabled="isAnswers"
                >
                  <template #item="{ element }">
                    <div class="list-group-item text-xs lg:text-base text-left bg-white p-2 px-4 mb-2 lg:rounded-full rounded-lg border border-blue">{{ element.text }}</div>
                  </template>
                </draggable>
              </div>
            </div>
          

            <div class="w-1/2 flex flex-col border-2 border-blue ml-6 rounded-3xl p-2 lg:p-4 max-h-full overflow-auto">
              <h3 class="text-center font-bold text-sm lg:text-base">Ordonne par ordre de priorité les actions à mener</h3>
            <div class="m-3 flex-1">
              <draggable
                  class="list-group h-full p-2"
                  :list="answersChoice"
                  group="answer"
                  itemKey="text"
                  :disabled="isAnswers"
              >
               <template #item="{ element, index }">
                  <div class="flex mb-2">
                    <div v-if="isAnswers" class="bg-blue-light rounded-full h-[30px] lg:h-[50px] my-auto w-[30px] lg:w-[50px] lg:mr-4 flex flex-col justify-center text-xs lg:text-base" :class="isCorrect(index+1, element.order)">{{element.order}} </div>
                    <div v-else class="bg-blue-light rounded-full h-[30px] lg:h-[50px] my-auto w-[30px] lg:w-[50px] lg:mr-4 flex flex-col justify-center text-xs lg:text-base" :class="isCorrect(index+1, element.order)">{{index+1}}
                    </div>
                    <div class="w-5/6 list-group-item text-xs lg:text-base text-left bg-blue-light border border-blue p-2 px-4 lg:rounded-full rounded-lg flex flex-col justify-center" :class="isCorrect(index+1, element.order)"><p class="w-full">{{ element.text }}</p></div>
                  </div>
                </template>
              </draggable>
            </div>
          </div> 
        </div>
      </div>
    </div>

      <button v-if="!isAnswers"
        @click="answersChoice.length != answers.length ? '' : sawAnswers() "
        :class="
          'w-fit mt-2 lg:mt-4 mx-auto px-6 py-2 answer-container rounded-full uppercase text-white ' +
          (answersChoice.length != answers.length
            ? '!bg-gray-dark cursor-default '
            : '!bg-blue hover:!bg-blue-hover')
        "
      >
        <div class="flex">
          <img
            v-if="answersChoice.length == answers.length"
            src="/img/check.svg"
            alt=""
            class="w-4 lg:w-7 mr-2"
          />
          <p class="text-sm lg:text-base">Valider ma réponse</p>
        </div>
      </button>
      <button
        v-if="isAnswers"
        @click="nextStepPractice()"
        :class="
          'w-fit mx-auto px-6 py-2 answer-container rounded-full uppercase !bg-blue hover:!bg-blue-hover text-white mt-2 lg:mt-4'
        "
      >
        <div class="flex text-sm lg:text-base">
          <p>Suivant</p>
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import StepMixin from '@/components/scene/components/mixins/StepMixin'
import draggable from 'vuedraggable'

export default {
  emits: ['is-next-practice', 'next-practice', 'submit', 'next-text'],
  name: 'PracticeOrder',
  mixins: [StepMixin],  
  components: {
    draggable,
  },
  data() {
    return {
      goodAnswers: [],
      answersChoice: [],
      listAnswers: [],
      isAnswers : false
    }
  },
  props: {
    subStep: [Boolean, Number],
    isNext: [Boolean, Number],
    nextText: [String],
    step: [Object],
    answers: [Object],
  },
  computed: {
    user() {
      return this.$store.state.account.data
    },
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },
  methods: {
    isCorrect(index,order) {
      if (this.isAnswers && index == order) {
        return '!bg-[#C2E59B] !border-[#C2E59B]'
      }
      if (this.isAnswers && index != order) {
        return '!bg-[#FDA6A6] !border-[#FDA6A6]'
      }
      return ''
    },
    sawAnswers() {
      this.isAnswers = true
    },
    nextStepPractice() {
      this.$emit('submit', this.answersChoice)
      this.isAnswers = false
    },
  },
  watch: {
    answers() {
      this.listAnswers = JSON.parse(JSON.stringify(this.answers))
    }
  },
  created() {
    console.log(this.answers)
    this.listAnswers = JSON.parse(JSON.stringify(this.answers))
  }
}
</script>

<style scoped>
.question-container {
  @apply bg-white rounded-3xl;
}

.answer-container {
  /* background-color: RGB(123, 43, 69); */
  user-select: none; /* standard syntax */
  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -khtml-user-select: none; /* webkit (konqueror) browsers */
  -ms-user-select: none; /* IE10+ */
}
.question-container {
  @apply text-blue-dark text-2xl m-auto text-xl p-4 lg:p-10 text-center;
}
</style>
