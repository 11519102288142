<template>
  <div class="modal-box" id="modal-id">
    <div class="modal-blur" @click="closePopup()"></div>
    <div class="modal-inner relative shadow-dialog-orange h-full flex flex-col  bg-no-repeat bg-contain bg-center">
      <div class="flex justify-end cursor-pointer absolute right-2 lg:right-6">
        <img
          src="/img/close.png"
          alt=""
          class="w-3 xl:w-6 m-2"
          @click="closePopup()"
        />
      </div>


      <div class="flex flex-col h-[90%] w-[90%] m-auto overflow-y-auto z-10">
        <div class="text-lg xl:text-5xl w-full text-center mb-2 xl:mb-6 font-semibold">
          <span class="text-blue">Bienvenue {{user.first_name}}</span>
        </div>

        <div class="flex flex-col xl:justify-center select-none xl:text-lg xl:mt-6">
          <p>
            Vous ferez prochainement partie de <span class="font-bold">l'équipe {{user.team}}</span> et l'ensemble des membres est impatient de vous accueillir <span v-if="user.arrive_at != null">le <span class="font-bold">{{new Date(user.arrive_at).toLocaleDateString()}}</span> prochain</span> ! 
          </p>
          <p class="mt-4">
            Nous vous attendrons à <span class="font-bold">9h00</span> à l'accueil de l'office. 
          </p>
          <p v-if="user.rh != null">
            Dans un premier temps, vous ferez le point avec <span class="font-bold">{{user.rh.first_name}} du service RH</span> sur les éléments administratifs de votre dossier du personnel. Ensuite, nous vous remettrons l'ensemble du matériel nécessaire à votre arrivée (badge, casier, boite de rangement…).
          </p>
          <p v-else>
            Dans un premier temps, vous ferez le point avec une personne du service RH sur les éléments administratifs de votre dossier du personnel. Ensuite, nous vous remettrons l'ensemble du matériel nécessaire à votre arrivée (badge, casier, boite de rangement…).
          </p>
          <p class="mt-4">
            Accompagné.e de votre manager vous rencontrerez le service informatique afin de vous remettre votre matériel informatique. Aussi, afin de limiter les risques liés aux attaques cybercriminelle ; dans le cadre de votre fonction vous réaliserez une séance de sensibilisation d'environ 45min sur le sujet.
          </p>
          <p class="mt-4">
            Vous serez ensuite conduit.e auprès de votre équipe. Votre manager vous indiquera la suite des différentes étapes de votre intégration. Tout sera prévu pour vous permettre de prendre vos marques dans un milieu dynamique et collaboratif. 
          </p>
          <p class="mt-4">
            Encore une fois, nous sommes heureux de vous accueillir et espérons que vous vous plairez parmi nous ! 
          </p>
          <p v-if="user.rh != null">
            N'hésitez pas à revenir vers <span class="font-bold">{{user.rh.first_name}}</span> si vous avez des questions avant votre arrivée. 
          </p>
          <p v-else>
            N'hésitez pas à revenir vers nous si vous avez des questions avant votre arrivée. 
          </p>
          <p class="mt-4">
            NB : pas de Dresscode particulier 😉
          </p>
          <p>
            A bientôt ! 
          </p>
          <p class="font-bold">
            Le service ressources humaines 
          </p>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: { },
  emits: ['close-popup'],
  name: 'ViewCarte',
  data() {
    return {
      widthImg : 0,
      heightImg : 0,
    }
  },
  computed: {
    user() {
      return this.$store.state.account.data
    }
  },
  methods: {
    closePopup() {
      this.$emit('close-popup')
    },
    getBaseUrlFront() {
      return window.location.origin
    }
  },
  mounted() {
  },
  created() {
  }
}
</script>

<style scoped>
.overlay {
  @apply absolute top-0 h-full w-full
	bg-black opacity-20;
}

.modal-inner {
  @apply z-10 w-[500px] h-[90%] xl:w-[900px] xl:h-[90%] text-center rounded-3xl p-2 xl:p-8 ;
}

.modal-inner {
  background-color: white;
}

</style>
