<template>
  <div v-if="performance" class="flex justify-center items-center h-screen">
    <div class="responsive-box overflow-hidden">
      <HomeReturn :route="id != 3 ? 'PerfAdventure' : 'Perf'" />
      <div class="flex flex-col pt-[10vmin] h-full">
        <div>
          <div class="text-center">
            <h2 class="title">Mon profil</h2>
          </div>

          <div class="page-desc uppercase !w-full text-center">
            {{ performance.name }}
          </div>
        </div>
        <div class="flex mt-10 justify-center h-[70%]">
          <div
            class="p-8 flex flex-row justify-between items-center bg-white rounded-3xl w-10/12 shadow-lg"
          >
            <div class="w-2/3 flex flex-col text-blue-dark font-semibold">
              <div class="mt-2">
                <h2 class="title uppercase !text-[4vmin]">Mes connaissances</h2>
                <div class="w-full border border-b-1 mb-2"></div>
              </div>

              <p class="page-desc !barlow !w-full mt-2">
                Progressez dans les différentes thématiques pour améliorer vos
                statistiques !
              </p>

              <div class="flex items-center mt-2">
                <div class="w-12 border border-b-2 border-blue mr-2"></div>
                <p class="page-desc !barlow">Résultat initial</p>
              </div>

              <div class="flex items-center mt-2">
                <div class="w-12 border border-b-2 border-red mr-2"></div>
                <p class="page-desc !barlow">Meilleur résultat obtenu</p>
              </div>
            </div>
            <RadarChart
              :labels="labels"
              :scores="scores"
              :firstScores="firstScores"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiManager from '../../datas/apiManager'
import RadarChart from '../../components/RadarChart.vue'
import HomeReturn from '@/components/HomeReturn.vue'

export default {
  components: {
    RadarChart,
    HomeReturn
  },
  name: 'ProfilePerfGraph',
  data() {
    return {
      data: [],
      prevRoute: null,
      retour_img: '/img/btn_retour.svg',
      performance: []
    }
  },
  props: {
    id: [String, Number]
  },
  computed: {
    user() {
      return this.$store.state.account.data
    },
    labels() {
      let labels = []
      this.data.forEach((thematic) => {
        // labels.push([thematic.name, thematic.score / 20 + "/5"]);
        labels.push([thematic.name])
      })

      return labels
    },
    scores() {
      let scores = []
      this.data.forEach((thematic) => {
        scores.push(thematic.score)
      })

      return scores
    },
    firstScores() {
      let firstScores = []
      this.data.forEach((thematic) => {
        firstScores.push(thematic.firstScores)
      })

      return firstScores
    },
    prevRoutePath() {
      return this.prevRoute ? this.prevRoute.name : ''
    },
    prevRouteParams() {
      return this.prevRoute ? this.prevRoute.params : null
    }
  },
  methods: {},
  created() {
    apiManager.getGraphiques(this.user.id).then((response) => {
      this.data = response.data
    })

    apiManager
      .getPerformanceAdventure(this.user.id, this.id)
      .then((response) => {
        this.performance = response.data
      })
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from
    })
  }
}
</script>
