<template>
  <div class="flex justify-center items-center h-screen">
    <div class="responsive-box">
      <!-- <HomeReturn :route="'JobExp'" /> -->
      <HomeReturn :route="'CommunJob'" />

      <div class="flex flex-col pt-[20vmin] h-full">
        <div class="text-6xl w-full text-center mb-6 font-semibold">
          <span class="title">Choisir ma thématique </span>
        </div>

        <div class="flex items-end justify-evenly select-none">
          <div
            v-for="(thematic,index) of thematics"
            :key="thematic.id"
            @click="goTo('Modules', id, thematic.id)"
            class="card lg:text-2xl cursor-pointer"
          >
            <img v-if="index == 0" src="/img/profile/gestion-icon.png" alt="" class="w-[230px]" />
            <img v-else-if="index == 1" src="/img/profile/technique-icon.png" alt="" class="w-[230px]" />
            <img v-else src="/img/profile/management-icon.png" alt="" class="w-[230px]" />
            <p class="uppercase barlow">{{ thematic.name }}</p>
            <p class="barlow text-blue">{{getPercentage(index)}}% effectués</p>
            <div @click="goTo('Modules', id, thematic.id)" class="button">
              JOUER
            </div>
          </div>
        </div>
        <!-- <div @click="goTo('Modules', id, thematic.id)" class="button uppercase !w-fit mx-auto pb-4">
          Visualiser mes résultats
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import apiManager from '../../datas/apiManager'
import router from '../../router'
import HomeReturn from '@/components/HomeReturn.vue'

export default {
  components: { HomeReturn },
  name: 'TrainingThematics',
  data() {
    return {
      thematics: [],
      retour_img: '/img/btn_retour.svg',
      percentages : []
    }
  },
  props: {
    id: [String, Number]
  },
  computed: {
    user() {
      return this.$store.state.account.data
    }
  },
  methods: {
    getPercentage(index) {
      let percentage = this.percentages[index == 0 ? 'gs' : (index == 1 ? 't' : 'm')]
      return percentage > 100 ? 100 : percentage
    },
    goTo(route, id, thematicId) {
      router.push({
        name: route,
        params: {
          idAdventure: id,
          idThematic: thematicId
        }
      })
    }
  },
  created() {
    apiManager.getThematics(this.id).then((response) => {
      this.thematics = response.data.data
    })
    apiManager.getPercentageModule(this.user.id).then((response) => {
      this.percentages = response.data
    })
  }
}
</script>
