<template>
  <div class="flex justify-center items-center h-screen">
    <div class="responsive-box bg-orange-light">
      <HomeReturn :route="'Login'" />
      <div
        class="bg-[url('@/assets/img/login_bg.svg')] bg-no-repeat w-full h-full flex justify-center items-center bg-bottom bg-contain"
      >
        <div
          class="bg-white xl:w-[45%] w-1/2 xl:h-fit lg:h-2/3 rounded-3xl shadow-custom shadow-green-light border border-gray"
        >
          <div class="p-5 xl:p-12 flex flex-col">
            <h2 class="title text-center xl:!leading-[30px] !text-4xl">
              Réinitialisation mot de passe
            </h2>
            <form class="flex gap-5 flex-col">
              <div v-if="errorMessage != ''" ref="error" class="text-center text-red font-bold mt-2">{{errorMessage}}</div>
              <div v-if="successMessage != ''" ref="success" class="text-center text-green font-bold">{{successMessage}}</div>

              <div>
                <input
                  ref="loginInput"
                  name="email"
                  type="email"
                  class="border border-blue w-full !text-lg xl:text-2xl xl:mt-6 lg:p-2"
                  placeholder="Email"
                  :rules="'required'"
                  v-model="input.email"
                  v-on:keyup.enter="newPassword()"
                />
              </div>

              <div class="w-full flex justify-center">
                <button
                  class="button sm:!text-lg !bg-red hover:!bg-orange rounded-full"
                  type="button"
                  @click="newPassword()"
                >
                  Envoyer un lien de réinitialisation
                </button>
                <!-- <button type="" class="px-20 py-5 bg-red text-white hover:bg-orange">SUIVANT</button> -->
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import taskManager from '../datas/taskManager'
import HomeReturn from '@/components/HomeReturn.vue'

export default {
  components: {HomeReturn},
  name: 'ViewForgetPassword',
  data() {
    return {
      input: {
        email: '',
      },
      errorMessage : '',
      successMessage: ''
    }
  },
  computed: {
    user() {
      return this.$store.state.account.data
    }
  },
  methods: {
    newPassword() {
      if (this.input.email != '') {
        taskManager.forgetPassword(
          this.input.email,
          this.sucessForgetPassword,
          this.failedForgetPassword
        )
      } else {
        this.displayError('Tous les champs doivent être complétés.')
      }
    },
    sucessForgetPassword() {
      this.input.email = ''
      this.$router.push({
        name: 'Login',
        params: {
          message: "L'email a bien été envoyé"
        }
      })
      this.displaySucess("L'email a bien été envoyé")
    },
    failedForgetPassword(error) {
      this.displayError( error.data.message ? error.data.message : "Une erreur s'est produite")
    },
    displayError(message) {
      this.errorMessage = message
    },
    displaySucess(message) {
      this.successMessage = message
    }
  },
  beforeCreate() {
    document.body.className = 'login'
  }
}
</script>
