<template>
  <div class="modal-box" id="modal-id">
    <div class="modal-blur" @click="closePopup()"></div>
    <div class="modal-inner relative shadow-dialog-orange">
      <div class="flex justify-end cursor-pointer">
        <img
          src="/img/close.png"
          alt=""
          class="w-3 xl:w-6 m-2"
          @click="closePopup()"
        />
      </div>
      <img :src="'/img/' + img" alt="" class="m-auto h-[30%] xl:h-none" />
      <p class="xl:text-5xl lg:text-3xl barlow text-blue font-bold" :class="title != 'Parcours d\'intégration' ? 'mt-6' : 'mt-2'">
        {{ title }}
      </p>
      <p
        class="xl:text-2xl w-2/3 m-auto mt-3 barlow text-blue-dark font-semibold"
      >
        {{ text }}
      </p>

      <div v-if="title != 'Espace CEGID'"
        @click="goto()"
        class="button !bg-red hover:!bg-orange rounded-full w-fit h-fit !my-auto absolute bottom-10 xl:bottom-14 -translate-x-[50%] left-[50%] !top-auto"
      >
        C'EST PARTI !
      </div>
      <a v-else href="https://habitat76-login.pn.cegid.cloud/sse_generico/generico_login.jsp" target="_blank" class="button !bg-red hover:!bg-orange rounded-full w-fit h-fit !my-auto absolute bottom-0 xl:bottom-14 -translate-x-[50%] left-[50%]"
      >
        C'EST PARTI !
      </a>
    </div>
  </div>
</template>

<script>
import router from '../router'

export default {
  components: {},
  emits: ['close-popup'],
  name: 'ComponentModal',
  data: () => ({}),
  props: {
    path: {
      type: String
    },
    img: {
      type: String
    },
    title: {
      type: String
    },
    text: {
      type: String
    }
  },
  methods: {
    closePopup() {
      this.$emit('close-popup')
    },
    goto() {
      router.push({ name: this.path })
    }
  },
  created() {},
  computed: {}
}
</script>

<style scoped>

.overlay {
  @apply absolute top-0 h-full w-full
	bg-black opacity-20;
}

.modal-inner {
  @apply z-10 p-5 xl:w-[520px] xl:h-[530px] w-[320px] h-[300px] text-center xl:p-8 rounded-3xl;
}

.modal-inner {
  background-color: white;
}
</style>
