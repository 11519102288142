<template>
  <div v-if="script" class="flex justify-center items-center bg-black h-full">
    <p id="loading-text" v-show="!ctStep && loadPercent != '100%'" class="text-white font-bold !text-lg xl:!text-3xl relative ">Chargement en cours... {{loadPercent}}</p>

    <div v-show="loadPercent == '100%' && !isStart && isLoadSounds" class="bg-blue-dark bg-opacity-50 z-[9999] absolute text-white font-bold !text-lg xl:!text-3xl h-full w-full flex flex-col justify-center">
      <p class="text-white mx-auto mb-4">Clique pour commencer la session !</p>
      <img @click="startGame()" src="/img/arrow.svg" alt="" class="w-40 mx-auto cursor-pointer">
    </div>
    <!-- && isStart -->
    <div v-show="ctStep && isStart" class="relative scene-container">


      <AudioController
        :script="script"
        :step="animStep"
        :isStart="isStart"
        @loading="loading"
        @loadedAudio="isLoadSounds = true"
      ></AudioController>

      <animation-manager
        ref="animationManager"
        :animations="script.animations"
        :step="animStep"
        @loaded="loaded"
        @loading="loading"
        @ended="playNextStep()"
        @forceSkip="forceNextStepTransi"
        @nextEnd="nextEnd"
        :isNextPractice="isNextPractice"
        :nextVideoPractice="nextVideoPractice"
        :isStart="isStart"
      ></animation-manager>

      <component
        v-if="ctStep"
        :is="ctStep.type"
        :step="ctStep"
        :answers="answers"
        @is-next-practice="isNextVideoPractice"
        @next-step="validateNextStep"
        @force-next-step="forceNextStep"
        @update-steps="updateSteps"
        :module="module"
        :lastScene="lastScene"
        :steps="script.steps"
      ></component>
    </div>
  </div>
</template>

<script>
import AudioController from '@/components/scene/components/animation/AudioController'
import AnimationManager from '@/components/scene/components/animation/AnimationManager'
import DialogScene from '@/components/scene/components/step/DialogScene'
import TransitionScene from '@/components/scene/components/step/TransitionScene'
import VideoScene from '@/components/scene/components/step/VideoScene'
import QuestionYesNoScene from '@/components/scene/components/step/QuestionYesNoScene'
import QuestionQCMScene from '@/components/scene/components/step/QuestionQCMScene'
import QuestionChoiceScene from '@/components/scene/components/step/QuestionChoiceScene'
import PracticeScene from '@/components/scene/components/step/PracticeScene'
import ConclusionScene from '@/components/scene/components/step/ConclusionScene'
import DebriefScene from '@/components/scene/components/step/DebriefScene'
import router from '../../router'
import apiManager from '../../datas/apiManager'

export default {
  emits: ['update-steps'],
  name: 'SceneContainer',
  components: {
    AudioController,
    AnimationManager,
    DialogScene,
    TransitionScene,
    VideoScene,
    QuestionYesNoScene,
    QuestionQCMScene,
    QuestionChoiceScene,
    PracticeScene,
    ConclusionScene,
    DebriefScene
  },
  props: {
    script: {
      type: Object
    },
    module: {
      type: Object
    }
  },
  data() {
    // 'ct' for 'current' & 'nt' for 'next'
    return {
      isLoadSounds: false,
      contentLoaded: 0,
      ctStep: null,
      ctComponent: null,
      answers: null,
      ready: false,
      lastScene: false,
      ConclusionView: false,
      isNextPractice: false,
      nextVideoPractice: null,
      loadPercent: '0%',
      isStart : false,
      loadedAnims : 0,
      loadedSounds : 0,
    }
  },
  computed: {
    user() {
      return this.$store.state.account.data
    },
    animStep() {
      return this.ready ? this.getStep(1) : this.ctStep
    },
    isAllCorrect() {
      return this.$store.state.account.isAllCorrect
    }
  },
  methods: {
    startGame() {
      this.isStart = true
    },
    loading(loadedAnims, loadedSounds){
      if (loadedAnims) {
        this.loadedAnims = loadedAnims
      }

      if (loadedSounds) {
        this.loadedSounds = loadedSounds
      }

      let audios = this.script.steps.filter(step => step.animation.sound)
      this.loadPercent = Math.round((this.loadedAnims + this.loadedSounds) / (this.script.animations.length + audios.length)* 100) + '%'
    },
    nextEnd() {
      this.isNextPractice = false
      this.nextVideoPractice = null
    },
    isNextVideoPractice(videoUuid) {
      if (videoUuid) {
        this.isNextPractice = true
        this.nextVideoPractice = videoUuid
      }
    },
    updateSteps(newSteps) {
      this.$emit('update-steps', newSteps)
    },
    isConclusion() {
      if (this.ctStep && this.ctStep.type != 'ConclusionScene') {
        return true
      }

      if (this.isAllCorrect != null) {
        if (
          this.ctStep &&
          this.ctStep.type == 'ConclusionScene' &&
          this.isAllCorrect &&
          this.ctStep.options.type.name == 'good'
        ) {
          return true
        }

        if (
          this.ctStep &&
          this.ctStep.type == 'ConclusionScene' &&
          !this.isAllCorrect &&
          this.ctStep.options.type.name == 'bad'
        ) {
          return true
        }

        this.forceNextStep()

      }

      return false
    },
    loaded() {
      this.contentLoaded = 100
      if (this.contentLoaded === 100) {
        this.ready = true
        this.playNextStep()
      }
    },
    getStep(rank = 1) {
      let ctStepIndex = 0
      let steps = this.script.steps

      if (this.ctStep !== null) {
        ctStepIndex =
          steps.findIndex((step) => step.uuid === this.ctStep.uuid) + rank
      }

      return steps[ctStepIndex]
    },
    validateNextStep() {
      this.ready = true
    },
    forceNextStepTransi() {
      this.ready = true
      this.playNextStep()
    },
    forceNextStep() {
      this.ready = true
      this.playNextStep()
      this.$refs.animationManager.skip()
    },
    playNextStep() {
      if (!this.ready) return
      this.ready = false

      let ctStepIndex = 0
      let steps = this.script.steps

      if (this.ctStep !== null) {
        ctStepIndex =
          steps.findIndex((step) => step.uuid === this.ctStep.uuid) + 1
      }
      console.log(ctStepIndex)

      if (ctStepIndex == steps.length - 1) {
        this.lastScene = true
      }

      if (steps[ctStepIndex]) {
        this.answers = steps[ctStepIndex].options.answers
      }

      if (ctStepIndex == steps.length) {
        let data = {
          module_id: this.module.id,
          user_id: this.user.id,
          thematic_id: this.module.thematic_id,
          adventure_id: this.module.adventure_id
        }

        apiManager.sendCompletedModule(data).then(() => {
          router.push({
            name: 'Modules',
            params: {
              idAdventure: this.module.adventure_id,
              idThematic: this.module.thematic_id ? this.module.thematic_id : 0,
              moduleOrder: this.module.order,
              moduleName: this.module.name
            }
          })
        })

       
      }
      if (this.ctStep && this.ctStep.type == "VideoScene") {
        console.log(this.ctStep)
        let video = document.getElementById(this.ctStep.animation.uuid)
        video.pause()
      }
      this.ctStep = steps[ctStepIndex]
    }
  },
  created() {
  }
}
</script>

<style scoped>
.scene-container {
  @apply h-fit;
}

@media screen and (min-aspect-ratio: 16/9) {
  .scene-container {
    @apply h-full;
  }
}
</style>
