<template>
  <div
    v-if="performances && performances.length > 0"
    class="flex justify-center items-center h-screen"
  >
    <div class="responsive-box">
      <HomeReturn :route="'Profile'" />
      <div class="flex flex-col justify-center items-center h-full">
        <div>
          <div class="text-center">
            <h2 class="title">Mes performances</h2>
          </div>
        </div>
        <div class="w-full h-3/5 flex mt-10 px-20 justify-center">
          <div
            v-for="performance of performances"
            :key="performance.name"
            class="card-colorless ml-10"
          >
            <h2 class="card-title text-center min-h-[20%]">
              {{ performance.name }}
            </h2>
            <object
              type="image/svg+xml"
              :data="
                '/img/profile/' +
                performance.ref +
                (performance.isValidate == '1' ? '' : '_grey') +
                '.svg'
              "
              class="w-full p-3"
            >
              <param name="percent" :value="performance.best_percent" />
            </object>
            <router-link
              :to="{
                name: performance.id == 3 ? 'PerfGraph' : 'PerfAdventure',
                params: {
                  id: performance.id
                }
              }"
              href=""
              class="button"
              >MES STATISTIQUES</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiManager from '../../datas/apiManager'
import HomeReturn from '@/components/HomeReturn.vue'

export default {
  components: { HomeReturn },
  name: 'ProfilePerf',
  data() {
    return {
      performances: [],
      retour_img: '/img/btn_retour.svg'
    }
  },
  computed: {
    user() {
      return this.$store.state.account.data
    }
  },
  methods: {
    getBgColor(adventure) {
      if (adventure == 'LOC') {
        return 'bg-red'
      }

      if (adventure == 'COL') {
        return 'bg-orange'
      }

      if (adventure == 'CS') {
        return 'bg-orange-dark'
      }

      return 'bg-red'
    }
  },
  created() {
    apiManager.getPerformances(this.user.id).then((response) => {
      if (this.user.job == null) {
        let performanceNoJob = []
        response.data.forEach((performance) => {
          if (performance.ref != 'CS') {
            performanceNoJob.push(performance)
          }
          this.performances = performanceNoJob
        })
      } else {
        this.performances = response.data
      }
    })
  }
}
</script>
