<template>
  <div class="flex justify-center items-center h-screen">
    <div class="responsive-box bg-wave bg-no-repeat bg-cover">
      <HomeReturn :route="'Training'" />

      <div class="title-container">
        <div class="text-6xl w-full text-center font-semibold">
          <span class="title">Welc'Home </span>
          <span class="title !text-red">chez Habitat 76 !</span>
        </div>
        <div class="page-desc uppercase !w-full text-center">
          Choisissez votre expérience
        </div>

        <div class="chara-card">
          <div
            v-for="adventure of adventures"
            :key="adventure.id"
            class="relative bg-white p-[1vmin] text-center w-[30vmin] text-2xl rounded-xl shadow-md group"
          >
            <!-- <router-link :to="{ 
                          name: 'Commun',
                              params: {
                                  data: JSON.stringify(adventure)
                              }
                          }"> -->
            <router-link
              :to="{ name: getRouteName(adventure.reference) }"
              class=""
            >
              <p
                class="mb-2 text-red font-semibold group-hover:scale-125 transition-all"
              >
                {{ adventure.character }}
              </p>
              <img
                :src="'/img/training/' + getImageName(adventure.reference)"
                class="m-auto w-[10vmin]"
                alt=""
              />
              <p
                class="mt-2 uppercase whitespace-normal !w-full h-[3vh] leading-none page-desc"
              >
                {{ adventure.name }}
              </p>
              <div
                class="group-hover:opacity-100 group-hover:h-fit h-0 opacity-0 transition-all ease-in-out mt-5"
              >
                <div class="button !px-0">C'EST PARTI !</div>
              </div>
              <!-- <router-link :to="{ name:'Menu'}" class="button">C'EST PARTI !</router-link> -->
            </router-link>
          </div>
          <!-- <div class="relative bg-white p-8 text-center text-2xl">
                      <router-link :to="{ name:'CommunCol'}">
                          <p class="mb-2 text-red font-semibold">Léo</p>
                          <img src="@/assets/img/training/experience_col.svg" class="m-auto" alt="">
                          <p class="mt-2 uppercase">Vis ma vie de collaborateur</p>
                      </router-link>
                  </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiManager from '../../datas/apiManager'
import HomeReturn from '@/components/HomeReturn.vue'

export default {
  components: { HomeReturn },
  name: 'TrainingCommunExp',
  data() {
    return {
      adventures: [],
      retour_img: '/img/btn_retour.svg'
    }
  },
  computed: {
    user() {
      return this.$store.state.account.data
    }
  },
  methods: {
    getRouteName(reference) {
      if (reference == 'LOC') {
        return 'CommunLoc'
      }

      return 'CommunCol'
    },
    getImageName(reference) {
      if (reference == 'LOC') {
        return '/experience_loc.svg'
      }

      return '/experience_col.svg'
    }
  },
  created() {
    apiManager.getAdventures('TC').then((response) => {
      // console.log(response.data)
      this.adventures = response.data.data
    })
  }
}
</script>
