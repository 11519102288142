<script setup></script>

<template>
  <div v-if="user != null && user.game_mode != 'pre_integration'" class="absolute flex justify-center items-center h-screen w-screen">
    <div class="responsive-box-sound flex justify-end" >
        <img @click="changeSound()" :src="soundOn ? '/img/sound_on.png' : '/img/sound_off.png'" alt="" class="z-[99] w-12 absolute bottom-0 cursor-pointer h-fit m-8">
    </div>
  </div>

  <audio autoplay controls :muted="soundOn && isSound ? false : true" loop :volume="getVolume"
    class="absolute z-[999] w-48 h-48 top-0 hidden"
    ref="audio-general"
    :src="'/background_sound.mp3'"
    id="audio-general"
  ></audio>
    <RouterView />
</template>

<script>
import apiManager from './datas/apiManager'
import store from './store/index'
export default {
  components: {
  },
  props: {
  },
  computed: {
    getVolume() {
      if (
        this.$route.name === "CommunLoc" || 
        this.$route.name === "CommunCol" || 
        this.$route.name === "Commun" || 
        this.$route.name === "Training" ||
        this.$route.name === "Game"
        ) {
        return 0.04;
      } else if (
        this.$route.name === "Login" || 
        this.$route.name === "ForgetPassword" || 
        this.$route.name === "NewPassword" 
      ) {
        return  0;
      } else {
        return 0.1;
      }

    },
    user() {
      return this.$store.state.account.data
    },
    isSound() {
      var audio = document.getElementById("audio-general");
      if (audio) {
        if (
          this.$route.name === "Login" || 
          this.$route.name === "ForgetPassword" || 
          this.$route.name === "NewPassword" 
          ) {
          audio.pause()
          return false
        }

        audio.play()
      }
      return true
    }
  },
  methods: {
    changeSound() {
      store.commit('account/_set', { k: 'soundOn', v: !this.soundOn })
    },
  },
  async created() {
    console.log(this.user)
    if (this.user != null) {
      let user = await apiManager.getUser(
        this.user.id,
      )
      if (user.data && user.data.data) {
        store.commit('account/_set', { k: 'data', v: user.data.data })
      }
    } 
    document.addEventListener("mouseup", () => {
      var audio = document.getElementById("audio-general");
      if (audio && audio.paused) {
        audio.play()
      }
    });
  },
}
</script>
