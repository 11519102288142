<template>
  <div
    class="absolute z-10 h-full w-full top-0 cursor-pointer"
    @click="$emit('overlay-clicked')"
  ></div>
</template>

<script>
export default {
  name: 'OverlayScene'
}
</script>

<style scoped></style>
