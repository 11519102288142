<template>
  <div class="content">
    <div class="flex">
      <HomeReturn :route="'Menu'" />
    </div>

    <div class="flex flex-col pt-[10vmin] h-full">
      <div class="text-6xl w-full text-center mb-6 font-semibold">
        <span class="text-blue">Mode d'emploi</span>
      </div>
      <div
        class="text-2xl text-blue-dark font-semibold uppercase w-full text-center mb-6"
      >
        Blablabl
      </div>

      <div class="flex flex-col justify-evenly select-none m-12 mx-24">
        Blablabla l'entreprise
      </div>
    </div>
  </div>
</template>

<script>
import HomeReturn from '@/components/HomeReturn.vue'
export default {
  components: { HomeReturn },
  name: 'ViewManual',
  data() {
    return {}
  },
  computed: {
    user() {
      return this.$store.state.account.data
    }
  },
  methods: {},
  created() {}
}
</script>
