<template>
  <overlay-scene v-if="!lastScene" @overlay-clicked="completedDialog()"></overlay-scene>
  <div class="absolute top-5 w-full flex h-16 lg:h-24">
    <div class="flex ml-10 w-24 lg:w-52" style="z-index: 49">
      <router-link
        v-if="module"
        :to="{
          name: 'Modules',
          params: {
            idAdventure: module.adventure_id,
            idThematic: module.thematic_id ? module.thematic_id : 0
          }
        }"
      >
        <img
          :src="retour_img"
          @mouseover="retour_img = '/img/btn_quit_hover.svg'"
          @mouseleave="retour_img = '/img/btn_quit.svg'"
          alt=""
          class="lg:mt-5"
        />
      </router-link>
    </div>
  </div>

  <div id="debriefScene" v-if="step.options.debrief != null" class="absolute bottom-0 w-full h-full flex justify-center" :class="'flex-row' + (lastScene ? ' backdrop-blur' : '') ">
    <div class="w-2/3 flex justify-center lg:!pt-12 max-h-[90%] overflow-auto py-3" :class="'absolute bottom-5' ">
      <div class="w-[95%]">
        <img v-if="step.options.think" src="/img/cloudup2.svg" alt="" class="w-[84%] mx-auto">
        <div class="dialog-container relative" :class="isContextOrEnd() +' '+ colorBorder()">
          <TypingDialog
            :debriefScene="true"
            :dialog="longDebrief ? decodeHTML(longDebrief[indexDebrief-1]) :  decodeHTML(step.options.debrief)"
            :isDialogComplete="isDialogComplete"
            :firstClick="firstClick"
            @isDebrief="debrief"
            @completedDialog="completedDialog()"
          />
        </div>
        <img v-if="step.options.think" src="/img/clouddown2.svg" alt="" class="w-[84%] mx-auto">
      </div>
      <div v-if="longDebrief || lastScene"
        @click="completedDialog()"
        class="absolute bottom-5 lg:bottom-16 right-[1%] border-green border-2 bg-blue rounded-full p-2 w-8 lg:w-16 h-8 lg:h-16 z-50 cursor-pointer"
      >
        <img src="/img/right-arrow-w.png" alt="" />
      </div>
      <div
        v-if="longDebrief && indexDebrief > 1" @click="indexDebrief > 1 ? indexDebrief-- : ''"
        class="absolute bottom-5 lg:bottom-16 left-[0%] border-green border-2 bg-blue rounded-full p-2 w-8 lg:w-16 h-8 lg:h-16 z-50 cursor-pointer"
      >
        <img src="/img/left-arrow-w.png" alt="" />
      </div>
    </div>
    
  </div>

 
</template>

<script>
import StepMixin from '@/components/scene/components/mixins/StepMixin'
import TypingDialog from '@/components/scene/components/TypingDialog.vue'

export default {
  emits: ['is-view', 'is-next-practice'],
  name: 'DebriefScene',
  mixins: [StepMixin],
  components: {
    TypingDialog
  },
  data() {
    return {
      type: '',
      dialog: '',
      preDialog: '',
      // forceSkip: false,
      isDialogComplete: false,
      firstClick: false,
      retour_img: '/img/btn_quit.svg',
      indexDebrief : 1,
    }
  },
  computed: {
    longDebrief() {
      let textDebrief = this.step.options.debrief ? this.step.options.debrief.split('£cut£') : []
      if (textDebrief.length > 1) {
        return textDebrief
      }
      return false
    },
  },
  methods: {
    colorBorder() {
      if (this.isContext()) {
        return 'shadow-dialog-green'
      }
      if (this.step.options.think) {
        return ''
      } 
    
      return 'shadow-dialog-blue'
    },
    decodeHTML(text) {
      var txt = document.createElement('textarea')
      txt.innerHTML = text

      return txt.value
    },
    completedDialog() {
      if (this.longDebrief && this.indexDebrief < this.longDebrief.length ) {
        this.indexDebrief++
        return
      }

      this.isDialogComplete = true
      if (
        this.forceSkip &&
        (this.firstClick || this.step.options.debrief == null)
      ) {
        this.forceNextStep()
        return
      }
      this.firstClick ? this.nextStep() : ''

      this.firstClick = !this.firstClick
    },
    isContextOrEnd() {
      if (this.isContext()) {
        return 'bg-[#00627B] !text-white'
      }    
      if (this.step.options.think) {
        return 'bg-white !text-blue-dark ' + 'thought'
      }
      return 'border-blue border-2 lg:border-[3px] bg-blue-lightest !text-blue-dark ' + (this.step.options.arrowLeft && this.step.options.arrowLeft == true ? 'arrow-left' : 'arrow')
    },
    isContext() {
      const words = this.step.options.debrief ? this.step.options.debrief.split(']') : []
      if (words.length == 1) {
        return true
      }
      return false
    },
    decodeHtml(html) {
      var txt = document.createElement('textarea')
      txt.innerHTML = html
      return txt.value
    },
    debrief(value) {
      this.firstClick = value
    }
  },
  watch: {
    step() {
      this.isDialogComplete = false
      this.firstClick = false
    }
  },
  updated() {
  },
  created() {
    // this.forceSkip = this.step.options.forceSkip
    //   ? this.step.options.forceSkip
    //   : false
  }
}
</script>

<style scoped>

.dialog-container {
  @apply rounded-xl text-sm my-auto lg:text-2xl p-4 lg:p-12 text-center w-full;
}

</style>
