<template>
  <div class="modal-box" id="modal-id">
    <div class="modal-blur" @click="closePopup()"></div>
    <div class="modal-inner relative shadow-dialog-orange h-full flex flex-col">
      <div class="flex justify-end cursor-pointer absolute right-2 lg:right-6">
        <img
          src="/img/close.png"
          alt=""
          class="w-3 xl:w-6 m-2"
          @click="closePopup()"
        />
      </div>
      <div class="flex flex-col h-full">
        <div class="text-lg xl:text-5xl w-full text-center mb-2 xl:mb-6 font-semibold">
          <span class="text-blue">Comment est reparti le patrimoine d'habitat 76 ? </span>
        </div>

        <div class="flex flex-col justify-start select-none h-full overflow-y-auto">
          <iframe class="h-full w-full" :src="getBaseUrlFront() + '/Carte.pdf#view=fit'"></iframe>
          <!-- <iframe class="h-full w-full" :src="getBaseUrlFront() + '/Carte.pdf#toolbar=0&view=fit'"></iframe> -->
          <!-- <img class="h-[500px] lg:h-full mx-auto" src="/Carte.png" alt="communes avec patrimoine habitat76"> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: { },
  emits: ['close-popup'],
  name: 'ViewCarte',
  data() {
    return {}
  },
  computed: {
    user() {
      return this.$store.state.account.data
    }
  },
  methods: {
    closePopup() {
      this.$emit('close-popup')
    },
    getBaseUrlFront() {
      return window.location.origin
    }
  },
  created() {
  }
}
</script>

<style scoped>
.overlay {
  @apply absolute top-0 h-full w-full
	bg-black opacity-20;
}

.modal-inner {
  @apply z-10 w-[90%] h-[90%] xl:w-[75%] xl:h-[90%] text-center rounded-3xl p-2 xl:p-8 ;
}

.modal-inner {
  background-color: white;
}

</style>
