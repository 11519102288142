<template>
  <!--	Section question-->
  <div class="absolute top-5 w-full flex h-16 lg:h-24">
    <div class="flex ml-10 w-24 lg:w-52" style="z-index: 49">
      <router-link
        v-if="module"
        :to="{
          name: 'Modules',
          params: {
            idAdventure: module.adventure_id,
            idThematic: module.thematic_id ? module.thematic_id : 0
          }
        }"
      >
        <img
          :src="retour_img"
          @mouseover="retour_img = '/img/btn_quit_hover.svg'"
          @mouseleave="retour_img = '/img/btn_quit.svg'"
          alt=""
          class="lg:mt-5"
        />
      </router-link>
    </div>
  </div>

  <div v-show="subStep === 0" class="absolute bottom-0 w-full h-full flex justify-center">
    <div class="absolute bottom-0 lg:bottom-5 w-2/3 question-container max-h-full overflow-auto">
      <p class="w-full text-sm lg:text-2xl p-2 !text-blue-dark">{{ step.options.question }}</p>
      <p class="italic !text-blue-dark">
        {{
          nbGoodAnswers > 1
            ? 'Plusieurs bonnes réponses sont possibles'
            : 'Une seule bonne réponse est possible'
        }}
      </p>
      <div class="flex flex-col justify-center p-2">
        <div
          @click="chooseAnswer(answer.id)"
          v-for="answer in answers"
          :key="answer.id"
          class="px-6 py-2 answer-container mb-3 cursor-pointer rounded-full text-left font-semibold flex text-blue-dark border-b-4 border-blue-light hover:border-blue-dark"
          :class="divActive(answer.id)"
        >
          <img
            :src="
              '/img/check-' +
              (circleActive(answer.id) ? 'full2' : 'empty2') +
              '.png'
            "
            alt=""
            class="w-5 h-5 my-auto mr-2"
          />
          <p v-html="answer.text"></p>
        </div>
      </div>
      <button
        @click="answersChoice.length == 0 ? '' : submit()"
        :class="
          'px-6 py-2 answer-container rounded-full uppercase ' +
          (answersChoice.length == 0
            ? '!bg-gray-dark cursor-default '
            : '!bg-blue hover:!bg-blue-hover')
        "
      >
        <div class="flex">
          <img
            v-if="answersChoice.length != 0"
            src="/img/check.svg"
            alt=""
            class="w-4 lg:w-7 mr-2"
          />
          <p>Valider ma réponse</p>
        </div>
      </button>
    </div>
  </div>

  <!--	Section Answer-->
  <modal-scene v-show="subStep === 1">
    <div class="bg-white my-2 w-11/12 lg:w-10/12 m-auto">
      <p
        :class="resutWord == 'VRAI' ? 'text-green' : 'text-red'"
        class="text-lg lg:text-4xl font-bold my-5"
        v-text="resutWord == 'VRAI' ? (nbGoodAnswers > 1 ? 'Bonnes réponses !' : 'Bonne réponse !') : (nbGoodAnswers > 1 ? 'Mauvaises réponses !' : 'Mauvaise réponse !')"
      ></p>

      <div
        v-for="answer in resutWord == 'VRAI' ? goodAnswers : badAnswers"
        :key="answer.id"
        class="flex justify-center w-full mb-1"
      >
        <div class="w-1/12">
          <img
            class="w-4 lg:w-8"
            :src="resutWord == 'VRAI' ? '/img/check.svg' : '/img/cross.svg'"
            alt=""
          />
        </div>
        <p v-html="answer.text" class="w-10/12 text-left text-blue-dark font-semibold text-sm lg:text-xl">
        </p>
      </div>

      <div
        v-if="resutWord == 'VRAI'"
        class="flex justify-center w-full text-blue-dark font-normal text-sm lg:text-lg !text-black"
      >
        <div class="w-1/12"></div>
        <p class="w-10/12 text-left" v-html="step.options.description"></p>
      </div>

      <hr class="mt-4 mb-4" />

      <div
        v-for="answer in resutWord == 'VRAI' ? badAnswers : goodAnswers"
        :key="answer.id"
        class="flex justify-center w-full mb-1"
      >
        <div class="w-1/12">
          <img
            class="w-4 lg:w-8"
            :src="resutWord == 'VRAI' ? '/img/cross.svg' : '/img/check.svg'"
            alt=""
          />
        </div>
        <p v-html="answer.text" class="w-10/12 text-left text-blue-dark font-semibold text-sm lg:text-xl">
        </p>
      </div>

      <div
        v-if="resutWord != 'VRAI'"
        class="flex justify-center w-full text-blue-dark font-normal text-sm lg:text-lg !text-black"
      >
        <div class="w-1/12"></div>
        <p class="w-10/12 text-left" v-html="step.options.description"></p>
      </div>
    </div>
    <button
      @click="completedQCM()"
      class="bg-blue hover:bg-blue-hover rounded-full text-white px-6 py-2 my-2"
    >
      Suivant
    </button>
  </modal-scene>
</template>

<script>
import StepMixin from '@/components/scene/components/mixins/StepMixin'
import apiManager from '../../../../datas/apiManager'

export default {
  emits: ['is-view', 'is-next-practice'],
  name: 'QuestionYesNoScene',
  mixins: [StepMixin],
  data() {
    return {
      result: null,
      subStep: 0,
      goodAnswers: [],
      badAnswers: [],
      nbGoodAnswers: 0,
      nbGoodChoiceAnswers: 0,
      nbBadChoiceAnswers: 0,
      answersChoice: [],
      retour_img: '/img/btn_quit.svg',
    }
  },
  computed: {
    resutWord() {
      return this.result ? 'VRAI' : 'FAUX'
    },
    user() {
      return this.$store.state.account.data
    }
  },
  methods: {
    completedQCM() {
      if (this.forceSkip) {
        this.forceNextStep()
        return
      }
      this.nextStep()
    },
    // check the response and throw the next action
    divActive(answerId) {
      if (this.answersChoice.includes(answerId)) {
        return 'bg-beige border-b-4 border-blue'
      }
      return 'bg-beige'
    },
    circleActive(answerId) {
      if (this.answersChoice.includes(answerId)) {
        return true
      }
      return false
    },
    chooseAnswer(answerId) {
      if (!this.answersChoice.includes(answerId)) {
        if (this.nbGoodAnswers == 1) {
          this.answersChoice = []
        }
        this.answersChoice.push(answerId)
      } else {
        let index = this.answersChoice.indexOf(answerId)
        this.answersChoice.splice(index, 1)
      }
    },
    submit() {
      this.answersChoice.forEach((answerChoice) => {
        if (this.step.options.goodAnswers.includes(answerChoice)) {
          this.nbGoodChoiceAnswers++
        } else {
          this.nbBadChoiceAnswers++
        }
      })

      this.result = !(
        this.nbGoodChoiceAnswers != this.nbGoodAnswers ||
        this.nbBadChoiceAnswers > 0
      )

      this.subStep = 1
      //! il faudrait envoyé ici le tableau des réponses ...?
      this.send()
    },
    displayAnswer() {},
    //! TODO
    // send the response here
    send() {
      let data = {
        is_correct: this.result,
        question_id: this.step.options.id,
        // answer_id: answersId,
        user_id: this.user.id
      }

      apiManager.sendAnswersGame(data).then(() => {})
    },
    getAnswersData() {
      let goodAnswers = []
      let badAnswers = []

      let nbGoodAnswers = 0

      this.answers.forEach((answer) => {
        if (answer.is_correct == 1) {
          goodAnswers.push(answer)
          nbGoodAnswers++
        } else {
          badAnswers.push(answer)
        }
      })
      this.nbGoodAnswers = nbGoodAnswers
      this.goodAnswers = goodAnswers
      this.badAnswers = badAnswers

      this.result = null
      this.subStep = 0
      this.nbGoodChoiceAnswers = 0
      this.nbBadChoiceAnswers = 0
      this.answersChoice = []
    }
  },
  watch: {
    answers() {
      this.getAnswersData()
    }
  },
  created() {
    this.getAnswersData()
  }
}
</script>

<style scoped>
.question-container {
  @apply bg-blue-light rounded-3xl text-white text-sm m-auto lg:text-xl p-4 text-center;
}

.answer-container {
  /* background-color: RGB(123, 43, 69); */
  user-select: none; /* standard syntax */
  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -khtml-user-select: none; /* webkit (konqueror) browsers */
  -ms-user-select: none; /* IE10+ */
}

</style>
