<template>
  <div v-if="documents" class="flex justify-center items-center h-screen">
    <div class="responsive-box">
      <HomeReturn :route="'Menu'" />
      <div class="flex flex-col pt-[10vmin] h-full">
        <div class="text-center">
          <h2 class="title">Boîte à outils</h2>
          <!-- <div class="w-full flex justify-center">
            <p class="page-desc !w-full">
              {{
                user.job != null ? user.job.adventure.name : 'Pré-Intégration'
              }}
            </p>
          </div> -->
        </div>

        <div
          class="flex flex-col justify-evenly select-none bg-white m-10 mx-24 shadow-lg h-[70%]"
        >
          <div
            v-if="user.game_mode == 'integration'"
            class="flex flex-row justify-between text-2xl text-blue-dark"
          >
            <!-- <p
              @click="changeActive('')"
              :class="isActive == '' ? '!text-white bg-blue' : ''"
              class="!w-full my-auto page-desc border-b border-r boder-blue text-center p-1 cursor-pointer"
            >
              Général
            </p> -->
            <p
              @click="changeActive(category)"
              v-for="category of categories"
              :key="category"
              :class="isActive == category ? '!text-white bg-blue' : ''"
              class="!w-full my-auto page-desc border-b border-r boder-blue text-center p-1 cursor-pointer"
            >
              {{ category }}
            </p>
            <!-- <p
              @click="changeActive(thematic.id)"
              v-for="thematic of thematics"
              :key="thematic.id"
              :class="isActive == thematic.id ? '!text-white bg-blue' : ''"
              class="!w-full my-auto page-desc border-b border-r boder-blue text-center p-1 cursor-pointer"
            >
              {{ thematic.name }}
            </p> -->
          </div>

          <div
            class="flex items-stretch flex-wrap justify-center select-none bg-white m-10 h-full overflow-y-auto m-auto"
          >
            <a
              v-for="document of documents[isActive]"
              target="_blank"
              :key="document.id"
              :href="
                document.url != null
                  ? baseUrl + '/api/files/' + document.url
                  : null
              "
              class="w-3/12 max-h-72 bg-orange-lightest relative rounded-xl lg:p-6 text-center cursor-pointer m-3"
            >
              <div class="flex flex-col justify-evenly h-full">
                <img
                  :src="getIllustration(document)"
                  class="h-[10vmin] mx-auto"
                  alt=""
                />
                <p class="font-semibold page-desc !w-full mt-3">
                  {{ document.title }}
                </p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiManager from '../../datas/apiManager'
import HomeReturn from '@/components/HomeReturn.vue'

export default {
  components: { HomeReturn },
  name: 'TollboxDocument',
  data() {
    return {
      documents: [],
      thematics: null,
      isActive: 'Général',
      retour_img: '/img/btn_retour.svg'
    }
  },
  computed: {
    categories() {
      if (this.user.job && this.user.job.id) {
        return [
          'Général',
          'Chartes',
          'Sécurité',

          'Gestion locative',
          'Management',
          'Technique',
        ]
      }
      
      return [
        'Général',
        'Chartes',
        'Sécurité',
      ]
    },
    user() {
      return this.$store.state.account.data
    }
  },
  methods: {
    changeActive(id) {
      this.isActive = id
    },
    getIllustration (document) {
      if (document.illustration) {
        return this.baseUrl + '/api/files/' + document.illustration
      }
      return  '/img/' + (document.document_type.reference == 'DOC' ? 'doc.svg' : 'arrow.svg')
    }
  },
  created() {
    //! attention les doc sont trié par thematic id (ou '' si pas de thematic id)
    apiManager.getDocuments(this.user.id).then((response) => {
      this.documents = response.data
    })
    // if (this.user.job) {
    //   apiManager.getThematics(this.user.job.id).then((response) => {
    //     this.thematics = response.data.data
    //   })
    // }
  }
}
</script>
