import OverlayScene from '@/components/scene/components/OverlayScene'
import ModalScene from '@/components/scene/components/modal/ModalScene'

let StepMixin = {
  components: { OverlayScene, ModalScene },
  props: {
    step: { type: Object },
    module: { type: Object },
    answers: { type: Object },
    steps: { type: Object },
    lastScene: { type: Boolean }
  },
  methods: {
    nextStep() {
      this.subStep = 0
      this.$emit('next-step')
    },
    forceNextStep() {
      this.subStep = 0
      this.$emit('force-next-step')
    },
    updateSteps(uuid, SceneType, ConclusionType, endPractice) {

      const indexStep = this.steps.findIndex(step => step.uuid == uuid);
      let beforeScript = this.steps.slice(0, indexStep+1)
      let afterScript = this.steps.slice(indexStep+1)
      let afterCleanScript = []

      afterScript.forEach((step) => {
        if (endPractice) {
          if (step.type != SceneType || (step.type == SceneType && step.options.type.name == ConclusionType)) {
            afterCleanScript.push(step)
          }
        } else {
          if (step.type == SceneType && step.options.type.name == ConclusionType) {
            afterCleanScript.push(step)
          }
        }
       
      });

      const newSteps = beforeScript.concat(afterCleanScript);
      this.$emit('update-steps', newSteps)
      
    }
  },
  computed: {
    forceSkip() {
      return this.step.options.forceSkip ? this.step.options.forceSkip : false
    }
  },
  emits: ['next-step', 'force-next-step', 'update-steps']
}

export default StepMixin
