<template>
  <!--	Section question-->
  <div class="absolute top-5 w-full flex h-24">
    <div class="flex ml-10 w-24 lg:w-52" style="z-index: 49">
      <router-link
        v-if="module"
        :to="{
          name: 'Modules',
          params: {
            idAdventure: module.adventure_id,
            idThematic: module.thematic_id ? module.thematic_id : 0
          }
        }"
      >
        <img
          :src="retour_img"
          @mouseover="retour_img = '/img/btn_quit_hover.svg'"
          @mouseleave="retour_img = '/img/btn_quit.svg'"
          alt=""
          class="lg:mt-5"
        />
      </router-link>
    </div>
  </div>

  <div v-show="subStep === 0" class="absolute bottom-5 w-full">
    <div class="m-auto w-1/2 question-container">
      <p class="w-full text-2xl p-2">{{ step.options.question }}</p>
      <div class="flex justify-center p-2">
        <button
          v-for="answer in answers"
          :key="answer.id"
          @click="submit(answer.id)"
          class="px-6 py-2 answer-container"
        >
          {{ answer.text }}
        </button>

        <!-- <button @click="submit('yes')" class="bg-green-400 px-6 py-2">
          Oui
        </button>
        <button @click="submit('no')" class="bg-red-400 px-6 py-2">Non</button> -->
      </div>
    </div>
  </div>

  <!--	Section Answer-->
  <modal-scene v-show="subStep === 1">
    <div class="bg-white my-2">
      <p
        :class="resutWord == 'VRAI' ? 'text-green' : 'text-red'"
        class="text-2xl font-bold my-2"
        v-text="resutWord == 'VRAI' ? 'Bonne réponse !' : 'Mauvaise réponse !'"
      ></p>
      <p v-text="step.options.description"></p>
    </div>
    <button
      @click="nextStep()"
      class="bg-blue hover:bg-blue-hover text-white px-6 py-2 my-2"
    >
      Suivant
    </button>
  </modal-scene>
</template>

<script>
import StepMixin from '@/components/scene/components/mixins/StepMixin'
import apiManager from '../../../../datas/apiManager'

export default {
  emits: ['is-view', 'is-next-practice'],
  name: 'QuestionYesNoScene',
  mixins: [StepMixin],
  data() {
    return {
      result: false,
      subStep: 0,
      retour_img: '/img/btn_quit.svg',
    }
  },
  computed: {
    resutWord() {
      return this.result ? 'VRAI' : 'FAUX'
    },
    user() {
      return this.$store.state.account.data
    }
  },
  methods: {
    // check the response and throw the next action
    submit(value) {
      if (value === this.step.options.answer) {
        this.result = true
      }
      this.subStep = 1
      this.send(value)
    },
    closeModal() {
      this.subStep = 0
    },
    displayAnswer() {},

    //! TODO
    // send the response here
    send(answerId) {
      let data = {
        is_correct: this.result,
        question_id: this.step.options.id,
        answer_id: answerId,
        user_id: this.user.id
      }

      apiManager.sendAnswersGame(data).then(() => {})
    }
  }
}
</script>

<style scoped>
.dialog-container {
  background-color: RGB(123, 43, 69);
  box-shadow: 10px 10px 2px 1px rgb(243, 182, 183);
}

.question-container {
  background-color: #ff6777;
}

.answer-container {
  background-color: RGB(123, 43, 69);
}

.dialog-container {
  @apply text-white text-2xl m-auto text-2xl p-4 text-center;
}

.question-container {
  @apply text-white text-2xl m-auto text-2xl p-4 text-center;
}
</style>
