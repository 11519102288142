<template>
  <div class="flex flex-col justif-center w-full h-full">
    <img class="w-[28px] lg:w-[80px] absolute -top-[13px] lg:-top-[35px]" style="left: 50%;transform: translate(-50%, 0);" src="/img/intropractice.svg" alt="" v-if="contextPractice">
    <p :class="(isContext() ? '' : 'underline') + (debriefScene ? ' dialog-end' : ' dialog-text')" v-html="preDialog"></p>
    <p :class="'dialog-text'" v-html="type"></p>
     <p :class="'dialog-text'" v-html="textDebrief"></p>

    <div class="button !w-fit mx-auto" v-if="contextPractice">À toi de jouer !</div>
  </div>
</template>

<script>
import StepMixin from '@/components/scene/components/mixins/StepMixin'

export default {
  name: 'TypingDialog',
  mixins: [StepMixin],
  emits: ['isDebrief', 'completedDialog'],
  data() {
    return {
      allDialog: '',
      type: '',
      preDialog: '',
      debrief: false
    }
  },
  props: {
    dialog: [String, Object],
    textDebrief: [String, Object],
    debriefScene: [Boolean],
    isDialogComplete: [Boolean],
    firstClick: [Boolean],
    contextPractice: [Boolean],
  },
  methods: {
    completedDialog() {
      clearInterval(this.timer)
      this.type = this.allDialog
      // this.$emit('completedDialog')
    },
    printer() {
      this.stoper()
      let i = -1
      this.timer = setInterval(() => {
        i++
        const x = i % this.allDialog.length
        this.type += this.allDialog[x]
      }, 60)
    },
    stoper() {
      clearInterval(this.timer)
      this.type = ''
    },
    setUpDialog() {
      const words = this.dialog ? this.dialog.split(']') : []
      if (words.length > 1) {
        let regex = /<\/?[^>]+(>|$)/g;
        this.preDialog = words[0].replace(']', '').replace('[', '')
        this.allDialog = words[1].replace(regex,'')
        this.debrief = false
        this.printer()
        this.$emit('isDebrief', false)
      } else {
        this.stoper()
        this.preDialog = words[0]
        this.allDialog = ''
        this.debrief = true
        this.$emit('isDebrief', true)
      }
    },
    isContext() {
      const words = this.dialog ? this.dialog.split(']') : []
      if (words.length == 1) {
        return true
      }
      return false
    },
  },
  watch: {
    isDialogComplete(value) {
      if (value) {
        this.completedDialog()
      }
    },
    type() {
      if (this.type.length == this.allDialog.length) {
        if (!this.firstClick && !this.isContext()) {
          this.$emit('completedDialog')
        }
        // this.$emit('completedDialog')
        clearInterval(this.timer)
      }
    },
    dialog() {
      clearInterval(this.timer)
      this.setUpDialog()
    }
  },
  created() {
    this.setUpDialog()
  }
}
</script>

<style>
.dialog-text * {
  color: #2A134B !important;
}
.dialog-end *  {
  color: white !important;
}

.know-block {
  @apply bg-blue-lightest rounded-3xl p-6 lg:p-8 flex flex-col justify-center mt-4 !text-[#2A134B]
}
.know-block * {
  @apply !text-[#2A134B]
}
</style>
