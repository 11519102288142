<template>
  <div class="flex justify-center items-center h-screen">
    <div class="responsive-box bg-orange-light">
      <HomeReturn :route="'Login'" />
      <div
        class="bg-[url('@/assets/img/login_bg.svg')] bg-no-repeat w-full h-full flex justify-center items-center bg-bottom bg-contain"
      >
        <div
          class="bg-white xl:w-[45%] w-1/2 xl:h-fit lg:h-2/3 rounded-3xl shadow-custom shadow-green-light border border-gray"
        >
          <div class="p-5 xl:p-12 flex flex-col">
            <h2 class="title text-center xl:!leading-[30px] !text-4xl">
              Changer de mot de passe
            </h2>
            <p class="mt-6">Veuillez saisir et confirmer votre nouveau mot de passe : </p>
            <form class="flex flex-col">
              <div v-if="errorMessage != ''" ref="error" class="text-center text-red font-bold mt-2">{{errorMessage}}</div>
              <div v-if="successMessage != ''" ref="success" class="text-center text-green font-bold mt-2">{{successMessage}}</div>
              <div>
                <input
                  ref="passwordInput"
                  name="password"
                  type="password"
                  autocomplete="on"
                  class="border border-blue w-full !text-lg xl:text-2xl xl:mt-6 lg:p-2"
                  placeholder="Mot de passe"
                  :rules="'required'"
                  v-model="input.password"
                  v-on:keyup.enter="newPassword()"
                />
              </div>

              <div>
                <input
                  ref="passwordInput"
                  name="password_confirmation"
                  type="password"
                  class="border border-blue w-full !text-lg xl:text-2xl xl:mt-6 lg:p-2"
                  placeholder="Confirmer mot de passe"
                  :rules="'required'"
                  v-model="input.password_confirmation"
                  v-on:keyup.enter="newPassword()"
                />
              </div>

              <div class="hidden">
                <input
                  ref="passwordInput"
                  name="token"
                  type="text"
                  class="border border-blue w-full text-2xl"
                  placeholder="token"
                  :rules="'required'"
                  v-model="input.token"
                  v-on:keyup.enter="newPassword()"
                />
              </div>

              <div class="w-full flex justify-center">
                <button
                  class="button sm:!text-lg !bg-red hover:!bg-orange rounded-full"
                  type="button"
                  @click="newPassword()"
                >
                  Changer de mot de passe
                </button>
                <!-- <button type="" class="px-20 py-5 bg-red text-white hover:bg-orange">SUIVANT</button> -->
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import taskManager from '../datas/taskManager'
import HomeReturn from '@/components/HomeReturn.vue'

export default {
  components: {HomeReturn},
  name: 'ViewNewPassword',
  data() {
    return {
      input: {
        password: '',
        password_confirmation: '',
        token: ''
      },
      errorMessage : '',
      successMessage: ''
    }
  },
  props: {
    token: [String, Number]
  },
  computed: {
    user() {
      return this.$store.state.account.data
    }
  },
  methods: {
    newPassword() {
      if (this.input.password != '' && this.input.password_confirmation != '') {
        if (this.input.password == this.input.password_confirmation) {
          taskManager.newPassword(
            this.input.password,
            this.input.password_confirmation,
            this.sucessForgetPassword,
            this.failedForgetPassword,
            this.token
          )
        } else {
          this.displayError('Le mot de passe choisi et sa confirmation doivent être identiques.')
        }
      } else {
        this.displayError('Tous les champs doivent être complétés.')
      }
    },
    sucessForgetPassword() {
      this.input.password = ''
      this.input.password_confirmation = ''
      this.errorMessage = ''
      this.$router.push({
        name: 'Login',
        params: {
          message: 'Le mot de passe a bien été changé !'
        }
      })
    },
    failedForgetPassword(error) {
      this.displayError( error.data.message ? error.data.message : "Une erreur s'est produite")
    },
    displayError(message) {
      this.errorMessage = message
    },
    displaySucess(message) {
      this.successMessage = message
    }
  },
  beforeCreate() {
    document.body.className = 'login'
  },
  created() {
    this.input.token = this.token
  }
}
</script>
