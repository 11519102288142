<template>
  <Radar
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :width="width"
    :height="height"
  />
</template>

<script>
import { Radar } from 'vue-chartjs'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  RadialLinearScale,
  LineElement,
  PointElement
} from 'chart.js'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  RadialLinearScale,
  LineElement,
  PointElement
)

export default {
  name: 'RadarChart',
  components: { Radar },
  props: {
    width: {
      type: Number,
      default: 700
    },
    height: {
      type: Number,
      default: 700
    },
    chartId: {
      type: String,
      default: 'radar-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    labels: {
      type: Array,
      Object
    },
    scores: {
      type: Array,
      Object
    },
    firstScores: {
      type: Array,
      Object
    },
  },
  data() {
    return {}
  },
  computed: {
    chartData() {
      let data = {
        labels: this.labels,
        datasets: [
          {
            label: 'Résultat initial',
            data: this.firstScores,
            borderColor: '#009ab3',
            backgroundColor: '#FFFFFF00',
            pointBorderColor: '#009ab3',
            pointBackgroundColor: '#009ab3'
          },
          {
            label: 'Meilleur résultat obtenu',
            data: this.scores,
            backgroundColor: '#FFFFFF00',
            borderColor: '#ce3b3d',
            pointBorderColor: '#ce3b3d',
            pointBackgroundColor: '#ce3b3d'
          },
        ]
      }
      return data
    },
    chartOptions() {
      let data = {
        responsive: true,
        plugins: {
          title: {
            display: false
          },
          legend: {
            display: false
          },
          tooltip: {
            callbacks: {
              label: function(context) {
                return context.dataset.label + ': ' +context.raw + '%';
              }
            }
          }
        },
        scales: {
          r: {
            pointLabels: {
              font: {
                size: 18
              }
            },
            max: 100,
            min: 0
          }
        }, 
      }
      return data
    }
    // width() {
    //   return parseInt(200)
    // },
    // height() {
    //   return parseInt(200)
    // }
  },
  mounted() {},
  created() {}
  // watch: {
  //   scores() {
  //     this.height = 300
  //     this.width = 300
  //   }
  // }
}

// bar-chart
</script>
