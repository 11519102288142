<template>
  <div class="flex justify-center items-center h-screen">
    <scene-container v-if="!error" :script="script" :module="module" @update-steps="updateSteps" />
    <div v-else class="responsive-box">
      <div class="top-left-back-btn">
        <div>
          <router-link :to="{ name: 'Menu' }">
            <img src="/img/logo.svg" alt="" />
          </router-link>
        </div>
        <div>
          <div @click="goBack()">
            <img
              :src="retour_img"
              @mouseover="retour_img = '/img/btn_retour_hover.svg'"
              @mouseleave="retour_img = '/img/btn_retour.svg'"
              alt=""
              class="mt-5"
            />
          </div>
        </div>
      </div>

      <div
        class="text-2xl w-full h-full font-semibold flex justify-center items-center"
      >
        <span class="text-red">
          Ce module est en cours de construction. Revenez plus tard.
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import SceneContainer from '@/components/scene/SceneContainer'
import apiManager from '../datas/apiManager'
import router from '../router'
// import test from '@/assets/script/video/1/1'

export default {
  name: 'ViewGame',
  components: { SceneContainer },
  data() {
    return {
      script: null,
      module: null,
      error: false,
      retour_img: '/img/btn_retour.svg',
      retour_hover_img: '/img/btn_retour_hover.svg'
    }
  },
  props: {
    idModule: [String, Number]
  },
  methods: {
    goBack() {
      router.go(-1)
    },
    updateSteps(steps) {
      this.script.steps = steps
    },
  },
  created() {
    //! ici module _id
    apiManager.getScript(this.idModule).then((response) => {
      if (response.data != false) {
        this.script = JSON.parse(response.data.data)
        this.module = response.data.module
        this.error = false
      } else {
        this.error = true
      }
    })
  }
}
</script>

<style></style>
