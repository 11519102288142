export default {
  namespaced: true,
  state: () => ({
    data: null,
    isAllCorrect: null,
    soundOn: true,
  }),
  getters: {
    loggedIn: (state) => {
      return state.data != null
    }
  },
  mutations: {
    _set(state, data) {
      state[data.k] = data.v
    },
    _delete(state, data) {
      state[data] = null
    }
  },
  actions: {
    _deleteAll(context) {
      for (let state in context.state) {
        context.commit('_delete', state)
      }
    }
  }
}
