<template>
  <div v-if="performance" class="flex justify-center items-center h-screen">
    <div class="responsive-box">
      <HomeReturn :route="'Perf'" />
      <div class="flex flex-col justify-center items-center h-full">
        <div>
          <div class="text-center">
            <h2 class="title">Mon profil</h2>
          </div>
          <div class="page-desc uppercase !w-full text-center">
            {{ performance.name }}
          </div>
        </div>
        <div
          v-if="performance"
          class="w-full h-3/5 flex mt-10 justify-center h-full"
        >
          <div
            class="p-8 flex flex-row justify-evenly items-center bg-white rounded-3xl w-9/12 shadow-lg"
          >
            <div class="w-8/12">
              <object
                type="image/svg+xml"
                :data="
                  '/img/profile/' +
                  performance.ref +
                  (performance.isValidate == '1' ? '' : '_grey') +
                  '.svg'
                "
                class="w-full p-3"
              >
                <param name="percent" :value="performance.best_percent" />
              </object>
            </div>

            <div class="flex flex-col w-full lg:ml-6">
              <div class="flex">
                <img src="/img/progress.png" class="w-[5vmin] mr-1" alt="" />
                <p class="page-desc !w-full my-auto mt-2">
                  Progression
                  <span class="uppercase font-bold"
                    >{{ stats.parcours.percent }} %</span
                  >
                </p>
              </div>

              <!-- <div class="flex">
                <img src="/img/conexion.png" class="w-14 mr-1" alt="" />
                <p class="text-blue-dark text-3xl">
                  Nombre de connexions
                  <span class="uppercase font-bold">{{
                    stats.nbConnexion ?? "NA"
                  }}</span>
                </p>
              </div> -->

              <!-- <div class="flex">
                <img src="/img/time.png" class="w-14 mr-1" alt="" />
                <p class="text-blue-dark text-3xl">
                  Temps passé
                  <span class="uppercase font-bold">??</span>
                </p>
              </div> -->

              <div
                class="w-full border border-blue-dark mt-2 border-b-1 mb-2"
              ></div>

              <div>
                <p class="page-desc !w-full mt-2">
                  Dernier score au quiz
                  <span class="uppercase font-bold"
                    >{{
                      stats.parcours.percent == 100 ? stats.result.score : '--'
                    }}
                    %</span
                  >
                </p>
              </div>

              <div>
                <p class="page-desc !w-full mt-2">
                  Meilleur score au quiz
                  <span class="uppercase font-bold"
                    >{{ stats.result.best_percent }} %</span
                  >
                </p>
              </div>

              <div v-if="stats.parcours.percent == 100">
                <p class="page-desc !w-full mt-2">
                  Nombre de tentatives
                  <span class="uppercase font-bold">{{
                    stats.result.nbTent
                  }}</span>
                </p>
              </div>

              <!-- <div>
                <p class="text-blue-dark text-3xl">
                  Score de validation
                  <span class="uppercase font-bold"
                    >{{ performance.min_score_module }} %</span
                  >
                </p>
              </div> -->

              <div v-if="stats.parcours.percent == 100">
                <p class="page-desc !w-full mt-2">
                  Statut :
                  <span class="uppercase font-bold">{{
                    performance.isValidate == 1 ? 'Validé' : 'Non validé'
                  }}</span>
                </p>
              </div>

              <div v-else>
                <p class="page-desc !w-full mt-2">
                  Statut :
                  <span class="uppercase font-bold">Non terminé</span>
                </p>
              </div>

              <div class="flex flex-row mt-10">
                <div
                  class="button !rounded-none !cursor-default mt-2"
                  :class="
                    performance.isValidate == 1 ? '!bg-yellow' : '!bg-red'
                  "
                >
                  Statut :
                  <span class="uppercase font-bold">{{
                    performance.isValidate == 1 ? 'Validé' : 'Non validé'
                  }}</span>
                </div>
                <router-link
                  v-if="performance.ref == 'CS'"
                  :to="{
                    name: 'PerfGraph',
                    params: {
                      id: performance.id
                    }
                  }"
                  href=""
                  class="button !bg-red hover:!bg-orange rounded-full ml-2"
                  >Voir mes connaissances</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiManager from '../../datas/apiManager'
import HomeReturn from '@/components/HomeReturn.vue'

export default {
  components: { HomeReturn },
  name: 'ProfilePerfAdventure',
  data() {
    return {
      performance: '',
      stats: [],
      retour_img: '/img/btn_retour.svg',
      prevRoute: ''
    }
  },
  props: {
    id: [String, Number]
  },
  computed: {
    user() {
      return this.$store.state.account.data
    }
  },
  methods: {},
  created() {
    apiManager.getStatistic(this.user.id, this.id).then((response) => {
      this.stats = response.data
    })

    apiManager
      .getPerformanceAdventure(this.user.id, this.id)
      .then((response) => {
        this.performance = response.data
      })
  }
}
</script>
