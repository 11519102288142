<template>
  <!--	Section question-->
  <div class="absolute top-5 w-full flex h-24">
    <div class="flex ml-10 w-24 lg:w-52" style="z-index: 49">
      <router-link
        v-if="module"
        :to="{
          name: 'Modules',
          params: {
            idAdventure: module.adventure_id,
            idThematic: module.thematic_id ? module.thematic_id : 0
          }
        }"
      >
        <img
          :src="retour_img"
          @mouseover="retour_img = '/img/btn_quit_hover.svg'"
          @mouseleave="retour_img = '/img/btn_quit.svg'"
          alt=""
          class="lg:mt-5"
        />
      </router-link>
    </div>
  </div>

  <div v-show="subStep === 0" class="absolute bottom-5 w-full h-[90%]">
    <div class="m-auto w-11/12 lg:w-10/12 question-container h-full flex flex-col justify-between">
      <p class="w-full text-sm lg:text-2xl p-2 font-bold mb-4">{{ step.options.question }}</p>
      <p class="italic text-sm xl:text-lg">Plusieurs bonnes réponses possibles</p>
      
      <div class="h-[65%] lg:h-[80%]">
        <div class="flex justify-center items-center h-full">
          <div class="flex w-[90%] m-auto max-h-full">
            <div class="w-1/2 flex flex-col bg-orange-lightest rounded-3xl p-2 lg:p-4 max-h-full overflow-auto">
              <h3 class="text-center font-bold text-sm lg:text-base">Liste de réponses</h3>
              <div class="m-3 flex-1">
                <draggable
                  class="list-group h-full p-2"
                    :list="listAnswers"
                    group="answer"
                    itemKey="text"
                >
                  <template #item="{ element }">
                  <div class="list-group-item text-xs lg:text-base text-left bg-white p-2 px-4 mb-2 lg:rounded-full rounded-lg border border-blue">{{ element.text }}</div>
                  </template>
                </draggable>
              </div>
            </div>
            

            <div class="w-1/2 flex flex-col border-2 border-blue ml-6 rounded-3xl p-2 lg:p-4 max-h-full overflow-auto">
              <h3 class="text-center font-bold text-sm lg:text-base">Positionne ici les bonnes réponses</h3>
              <div class="m-3 flex-1">
                <draggable
                    class="list-group h-full p-2"
                    :list="answersChoice"
                    group="answer"
                    itemKey="text"
                >
                  <template #item="{ element }">
                  <div class="list-group-item text-xs lg:text-base text-left bg-blue-light border border-blue p-2 px-4 lg:rounded-full rounded-lg mb-2">{{ element.text }}</div>
                  </template>
                </draggable>
              </div>
            </div> 
          </div>
        </div>
      </div>

      <button
        @click="answersChoice.length == 0 ? '' : submit()"
        :class="
          'w-fit mt-4 mx-auto px-6 py-2 answer-container rounded-full uppercase text-white ' +
          (answersChoice.length == 0
            ? '!bg-gray-dark cursor-default '
            : '!bg-blue hover:!bg-blue-hover')
        "
      >
        <div class="flex">
          <img
            v-if="answersChoice.length != 0"
            src="/img/check.svg"
            alt=""
            class="w-4 lg:w-7 mr-2"
          />
          <p class="text-sm lg:text-base">Valider ma réponse</p>
        </div>
      </button>
    </div>
  </div>

  <!--	Section Answer-->
  <modal-scene v-show="subStep === 1">
    <div class="bg-white my-2 w-10/12 m-auto">
      <p
        :class="resutWord == 'VRAI' ? 'text-green' : 'text-red'"
        class="text-4xl font-bold my-5"
        v-text="resutWord == 'VRAI' ? (nbGoodAnswers > 1 ? 'Bonnes réponses !' : 'Bonne réponse !') : (nbGoodAnswers > 1 ? 'Mauvaises réponses !' : 'Mauvaise réponse !')"
      ></p>

      <div
        v-for="answer in resutWord == 'VRAI' ? goodAnswers : badAnswers"
        :key="answer.id"
        class="flex justify-center w-full mb-1"
      >
        <div class="w-1/12">
          <img
            class="w-8"
            :src="resutWord == 'VRAI' ? '/img/check.svg' : '/img/cross.svg'"
            alt=""
          />
        </div>
        <p v-html="answer.text" class="w-10/12 text-left text-blue-dark font-semibold text-xl">
        </p>
      </div>

      <div
        v-if="resutWord == 'VRAI'"
        class="flex justify-center w-full text-blue-dark font-normal text-lg !text-black"
      >
        <div class="w-1/12"></div>
        <p class="w-10/12 text-left" v-html="step.options.description"></p>
      </div>

      <hr class="mt-4 mb-4" />

      <div
        v-for="answer in resutWord == 'VRAI' ? badAnswers : goodAnswers"
        :key="answer.id"
        class="flex justify-center w-full mb-1"
      >
        <div class="w-1/12">
          <img
            class="w-8"
            :src="resutWord == 'VRAI' ? '/img/cross.svg' : '/img/check.svg'"
            alt=""
          />
        </div>
        <p v-html="answer.text" class="w-10/12 text-left text-blue-dark font-semibold text-xl">
        </p>
      </div>

      <div
        v-if="resutWord != 'VRAI'"
        class="flex justify-center w-full text-blue-dark font-normal text-lg !text-black"
      >
        <div class="w-1/12"></div>
        <p class="w-10/12 text-left" v-html="step.options.description"></p>
      </div>
    </div>
    <button
      @click="completedQCM()"
      class="bg-blue hover:bg-blue-hover rounded-full text-white px-6 py-2 my-2"
    >
      Suivant
    </button>
  </modal-scene>
</template>

<script>
import StepMixin from '@/components/scene/components/mixins/StepMixin'
import apiManager from '../../../../datas/apiManager'
import draggable from 'vuedraggable'

export default {
  emits: ['is-view', 'is-next-practice'],
  name: 'QuestionYesNoScene',
  mixins: [StepMixin],
  components: {
    draggable,
  },
  data() {
    return {
      result: null,
      subStep: 0,
      goodAnswers: [],
      badAnswers: [],
      nbGoodAnswers: 0,
      nbGoodChoiceAnswers: 0,
      nbBadChoiceAnswers: 0,
      answersChoice: [],
      retour_img: '/img/btn_quit.svg',
      listAnswers: [],
    }
  },
  computed: {
    resutWord() {
      return this.result ? 'VRAI' : 'FAUX'
    },
    user() {
      return this.$store.state.account.data
    }
  },
  methods: {
    completedQCM() {
      if (this.forceSkip) {
        this.forceNextStep()
        return
      }
      this.nextStep()
    },
    // check the response and throw the next action
    divActive(answerId) {
      if (this.answersChoice.includes(answerId)) {
        return 'bg-beige border-b-4 border-blue'
      }
      return 'bg-beige'
    },
    circleActive(answerId) {
      if (this.answersChoice.includes(answerId)) {
        return true
      }
      return false
    },
    chooseAnswer(answerId) {
      if (!this.answersChoice.includes(answerId)) {
        if (this.nbGoodAnswers == 1) {
          this.answersChoice = []
        }
        this.answersChoice.push(answerId)
      } else {
        let index = this.answersChoice.indexOf(answerId)
        this.answersChoice.splice(index, 1)
      }
    },
    submit() {
      this.answersChoice.forEach((answerChoice) => {
        if (this.step.options.goodAnswers.includes(answerChoice.id)) {
          this.nbGoodChoiceAnswers++
        } else {
          this.nbBadChoiceAnswers++
        }
      })

      this.result = !(
        this.nbGoodChoiceAnswers != this.nbGoodAnswers ||
        this.nbBadChoiceAnswers > 0
      )

      this.subStep = 1
      //! il faudrait envoyé ici le tableau des réponses ...?
      this.send()
    },
    displayAnswer() {},
    //! TODO
    // send the response here
    send() {
      let data = {
        is_correct: this.result,
        question_id: this.step.options.id,
        // answer_id: answersId,
        user_id: this.user.id
      }

      apiManager.sendAnswersGame(data).then(() => {})
    },
    getAnswersData() {
      let goodAnswers = []
      let badAnswers = []

      let nbGoodAnswers = 0

      this.answers.forEach((answer) => {
        if (answer.is_correct == 1) {
          goodAnswers.push(answer)
          nbGoodAnswers++
        } else {
          badAnswers.push(answer)
        }
      })
      this.nbGoodAnswers = nbGoodAnswers
      this.goodAnswers = goodAnswers
      this.badAnswers = badAnswers

      this.result = null
      this.subStep = 0
      this.nbGoodChoiceAnswers = 0
      this.nbBadChoiceAnswers = 0
      this.answersChoice = []
      
      this.listAnswers = JSON.parse(JSON.stringify(this.answers))
    }
  },
  watch: {
    answers() {
      this.getAnswersData()
    }
  },
  created() {
    this.getAnswersData()
  }
}
</script>

<style scoped>
.question-container {
  @apply bg-white rounded-3xl text-blue-dark m-auto text-sm lg:text-xl p-4 lg:p-10 text-center;
}

.answer-container {
  /* background-color: RGB(123, 43, 69); */
  user-select: none; /* standard syntax */
  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -khtml-user-select: none; /* webkit (konqueror) browsers */
  -ms-user-select: none; /* IE10+ */
}

</style>
