import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist'
import account from './account'

// const vuexLocal = new VuexPersistence({
//   storage: window.localStorage
// })

let modules = {
  account
}

export default createStore({
  state: {
    loadingData: false
  },
  getters: {},
  mutations: {
    _set(state, data) {
      state[data.k] = data.v
    },
    _delete(state, data) {
      state[data] = null
    }
  },
  actions: {
    _deleteAlls() {
      Object.keys(modules).forEach((moduleName) => {
        this.dispatch(moduleName + '/_deleteAll')
      })
    }
  },
  modules: modules,
  plugins: [new VuexPersistence().plugin]
})
