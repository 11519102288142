<template>
  <div v-show="subStep === 0 && !isNext" class="absolute bottom-5 w-full h-[90%]">
    <div class="m-auto question-container h-full flex flex-col justify-between" :class="step.options.id != 177 ? 'w-11/12 lg:w-10/12' : 'w-11/12 lg:w-11/12'">
      <p class="w-full text-sm lg:text-2xl p-2 font-bold">{{ step.options.question }}</p>
      <p class="italic text-sm xl:text-lg">Plusieurs bonnes réponses possibles</p>

      <p v-if="isAnswers && step.options.id == 177" class="my-2 text-green font-bold">N'hésites pas à descendre pour voir les bonnes et les mauvaises réponses !</p>
      
      <div v-if="step.options.id != 177" class="h-[65%] lg:h-[80%] mt-4">
        <div class="flex justify-center items-center h-full">
          <div class="flex w-[90%] m-auto max-h-full">
            <div class="w-1/2 flex flex-col bg-orange-lightest rounded-3xl p-2 lg:p-4 max-h-full overflow-auto">
              <h3 class="text-center font-bold text-sm lg:text-base">Liste de réponses</h3>
              <div class="m-3 flex-1">
                <draggable
                  class="list-group h-full p-2"
                    :list="listAnswers"
                    group="answer"
                    itemKey="text"
                    :disabled="isAnswers"
                >
                  <template #item="{ element }">
                  <div class="list-group-item text-xs lg:text-base text-left bg-white p-2 px-4 mb-2 lg:rounded-full rounded-lg border border-blue" :class="isCorrect(element.is_correct, true)">{{ element.text }}</div>
                  </template>
                </draggable>
              </div>
            </div>
            

            <div class="w-1/2 flex flex-col border-2 border-blue ml-6 rounded-3xl p-2 lg:p-4 max-h-full overflow-auto">
              <h3 class="text-center font-bold text-sm lg:text-base">Positionne ici les bonnes réponses</h3>
              <div class="m-3 flex-1">
                <draggable
                    class="list-group h-full p-2"
                    :list="answersChoice"
                    group="answer"
                    itemKey="text"
                    :disabled="isAnswers"
                >
                  <template #item="{ element }">
                  <div class="list-group-item text-xs lg:text-base text-left bg-blue-light border border-blue p-2 px-4 lg:rounded-full rounded-lg mb-2" :class="isCorrect(element.is_correct)">{{ element.text }}</div>
                  </template>
                </draggable>
              </div>
            </div> 
          </div>
        </div>
      </div>

      <div v-else class="h-[65%] lg:h-[80%]">
        <div class="flex justify-center items-center h-full">
          <div class="flex w-full m-auto max-h-full">
            <div class="w-1/2 flex flex-col max-h-full">
              <h3 class="text-center font-bold text-sm lg:text-base mb-4">Liste d'actions disponibles</h3>
              <div class="flex-1 bg-gray-lightest p-2 lg:p-6 overflow-auto ">
                <draggable
                  class="mx-auto list-group h-full p-2 flex flex-wrap !justify-around" style="align-content: flex-start"
                    :list="listAnswers"
                    group="answer"
                    itemKey="text"
                    :disabled="isAnswers"
                >
                  <template #item="{ element }">
                  <div class="list-group-item text-xs lg:text-base text-left p-2 px-4 mb-2 bg-center bg-no-repeat h-[170px] w-[150px] lg:h-[200px] lg:w-[160px] flex flex-col justify-center m-4" :class="'post' + element.id">
                    <img
                      v-if="isAnswers && element.is_correct"
                      src="/img/check.svg"
                      alt=""
                      class="w-5 lg:w-7 mx-auto"
                    /> 
                    <img
                      v-if="isAnswers && !element.is_correct"
                      src="/img/cross.svg"
                      alt=""
                      class="w-5 lg:w-7 mx-auto"
                    />
                    <p class="m-auto w-fit text-xs">{{ element.text }}</p>
                  </div>
                  </template>
                </draggable>
              </div>
            </div>
            

            <div class="w-1/2 flex flex-col ml-6 lg:p-4 max-h-full bg-[#E3E25A]">
              <div class="w-11/12 flex flex-col h-full m-auto bg-white border border-8 border-gray overflow-auto">
                <h3 class="text-center font-bold text-sm lg:text-base mb-4 mt-4">Placer les actions sur le tableau</h3>
                <div class="flex-1">
                  <draggable
                      class="mx-auto list-group h-full p-2 flex flex-wrap !justify-around" style="align-content: flex-start"
                      :list="answersChoice"
                      group="answer"
                      itemKey="text"
                      :disabled="isAnswers"
                  >
                    <template #item="{ element }">
                    <div class="list-group-item text-xs lg:text-base text-left p-2 px-4 mb-2 bg-center bg-no-repeat h-[170px] w-[150px] lg:h-[200px] lg:w-[160px] flex flex-col justify-center m-4" :class="'post' + element.id">
                    <img
                      v-if="isAnswers && element.is_correct"
                      src="/img/check.svg"
                      alt=""
                      class="w-5 lg:w-7 mx-auto"
                    />
                    <img
                      v-if="isAnswers && !element.is_correct"
                      src="/img/cross.svg"
                      alt=""
                      class="w-5 lg:w-7 mx-auto"
                    />
                    <p class="m-auto w-fit text-xs">{{ element.text }}</p>
                    </div>
                    </template>
                  </draggable>
                </div>
              </div>
              <div class="w-full bg-black h-3"></div>
            </div> 
          </div>
        </div>
      </div>

      <button
        v-if="!isAnswers"
        @click="answersChoice.length == 0 ? '' : sawAnswers() "
        :class="
          'w-fit mt-2 lg:mt-4 mx-auto px-6 py-2 answer-container rounded-full uppercase text-white ' +
          (answersChoice.length == 0
            ? '!bg-gray-dark cursor-default '
            : '!bg-blue hover:!bg-blue-hover')
        "
      >
        <div class="flex">
          <img
            v-if="answersChoice.length > 0"
            src="/img/check.svg"
            alt=""
            class="w-4 lg:w-7 mr-2"
          />
          <p class="text-sm lg:text-base">Valider ma réponse</p>
        </div>
      </button>
       <button
        v-if="isAnswers"
        @click="nextStepPractice()"
        :class="
          'w-fit mx-auto px-6 py-2 answer-container rounded-full uppercase !bg-blue hover:!bg-blue-hover text-white mt-2 lg:mt-4'
        "
      >
        <div class="flex text-sm lg:text-base">
          <p>Suivant</p>
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import StepMixin from '@/components/scene/components/mixins/StepMixin'
import draggable from 'vuedraggable'

export default {
  emits: ['is-next-practice', 'next-practice', 'submit', 'next-text'],
  name: 'PracticeChoice',
  mixins: [StepMixin],  
  components: {
    draggable,
  },
  data() {
    return {
      goodAnswers: [],
      answersChoice: [],
      answersChoiceReal: [],
      listAnswers: [],
      isAnswers : false,
    }
  },
  props: {
    subStep: [Boolean, Number],
    isNext: [Boolean, Number],
    nextText: [String],
    step: [Object],
    answers: [Object],
  },
  computed: {
    user() {
      return this.$store.state.account.data
    },
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },
  methods: {
    isCorrect(isSelect, isLeft) {
      if (this.isAnswers && isSelect) {
        return '!bg-[#C2E59B] !border-[#C2E59B]'
      }
      if (this.isAnswers && !isSelect && !isLeft) {
        return '!bg-[#FDA6A6] !border-[#FDA6A6]'
      }
      if (this.isAnswers && isLeft) {
        return '!bg-[#FDA6A6] !border-[#FDA6A6]'
      }
      return ''
    },
    sawAnswers() {
      this.isAnswers = true
      // this.answersChoiceReal = this.answersChoice
      // let correctsChoice = this.answersChoice.filter(choice => choice.is_correct == 1)
      // correctsChoice.map(choice => choice.is_select = true)
      // let correctsRest = this.listAnswers.filter(choice => choice.is_correct == 1)
      // correctsRest.map(choice => choice.is_select = false)
      
      // let badChoice = this.answersChoice.filter(choice => choice.is_correct != 1)
      // let badRest = this.listAnswers.filter(choice => choice.is_correct != 1)

      // this.answersChoice = correctsChoice.concat(correctsRest)
      // this.listAnswers = badChoice.concat(badRest)
    },
    nextStepPractice() {
      this.$emit('submit', this.answersChoice)
      this.isAnswers = false
    },
  },
  watch: {
    answers() {
      this.listAnswers = JSON.parse(JSON.stringify(this.answers))
    }
  },
  created() {
    this.listAnswers = JSON.parse(JSON.stringify(this.answers))
  }
}
</script>

<style scoped>
.question-container {
  @apply bg-white rounded-3xl;
}

.answer-container {
  /* background-color: RGB(123, 43, 69); */
  user-select: none; /* standard syntax */
  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -khtml-user-select: none; /* webkit (konqueror) browsers */
  -ms-user-select: none; /* IE10+ */
}


.question-container {
  @apply text-blue-dark m-auto text-sm lg:text-xl p-4 lg:p-10 text-center;
}

.post573 {
  background-image: url("/src/assets/img/post/post573.svg");
}
.post574 {
  background-image: url("/src/assets/img/post/post574.svg");
}
.post575 {
  background-image: url("/src/assets/img/post/post575.svg");
}
.post576 {
  background-image: url("/src/assets/img/post/post576.svg");
}
.post577 {
  background-image: url("/src/assets/img/post/post577.svg");
}
.post578 {
  background-image: url("/src/assets/img/post/post578.svg");
}
.post579 {
  background-image: url("/src/assets/img/post/post579.svg");
}
.post580 {
  background-image: url("/src/assets/img/post/post580.svg");
}
.post581 {
  background-image: url("/src/assets/img/post/post581.svg");
}
.post582 {
  background-image: url("/src/assets/img/post/post582.svg");
}
.post583 {
  background-image: url("/src/assets/img/post/post583.svg");
}
.post584 {
  background-image: url("/src/assets/img/post/post584.svg");
}
.post585 {
  background-image: url("/src/assets/img/post/post585.svg");
}
.post586 {
  background-image: url("/src/assets/img/post/post586.svg");
}


</style>
