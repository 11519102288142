<template>
  <video
    ref="player"
    :controls="controls"
    :loop="loop"
    :muted="muted"
    class="max-h-full"
    @ended.prevent="ended"
    @loadeddata="loaded"
  >
    <source :src="src" :type="type" />
    Votre navigateur ne supporte pas les vidéos.
  </video>
</template>

<script>
export default {
  name: 'VideoController',
  emits: ['loaded', 'endedVideo'],
  props: {
    src: {
      type: String
    },
    type: {
      type: String,
      default: 'video/mp4'
    },
    controls: {
      type: Boolean,
      default: false
    },
    loop: {
      type: Boolean,
      default: false
    },
    muted: {
      type: Boolean,
      default: true
    },
    afterAnimShown: {
      type: Boolean,
      default: true
    },
    step: {
      type: Object,
    },
    visible: {}
  },
  data() {
    return {
      id: Math.random()
    }
  },
  methods: {
    play() {
      this.$refs.player.play()
    },
    pause() {
      this.$refs.player.pause()
    },
    stop() {
      this.pause()
      this.reset()
    },
    reset() {
      this.currentTime(0)
    },
    currentTime(seconds) {
      this.$refs.player.currentTime = seconds
    },
    ended() {
      if (this.afterAnimShown && this.$refs.player.id == this.step.animation.after ) {
        this.$emit('endedVideo')
      } else if (!this.afterAnimShown && this.$refs.player.id == this.step.animation.uuid) {
        this.$emit('endedVideo')
      }
    },
    loaded() {
      this.$emit('loaded')
    }
  }
}
</script>

<style scoped></style>
