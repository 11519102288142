<template>
  <div class="flex justify-center items-center h-screen">
    <div
      class="responsive-box !bg-blue-light"
    >
      <HomeReturn :route="'CommunExp'" />
      <audio autoplay controls :muted="soundOn && fixSound ? false : true"
        class="absolute z-[999] w-48 h-48 top-0 hidden"
        @loadeddata="loadAudio"
        ref="audio-col"
        :src="baseUrl + '/sounds/intro_leo.mp3'"
        id="audio-col"
      ></audio>


      <div class="flex flex-col pt-[15vmin] xl:pt-[18vmin] h-full">

        <div class="flex justify-center select-none h-full lg:h-fit">
          <img
            src="/img/training/leo.gif"
            class="absolute z-10 w-[30%] left-[10%] hidden lg:block drop-shadow-lg"
            alt=""
          />
          <div
            class="relative bg-white p-4 xl:p-16 text-center text-sm lg:text-2xl h-full flex flex-col justify-between rounded-3xl w-8/12 lg:w-[46%] shadow-lg py-10 overflow-y-auto"
          >
            <div class="title w-full text-center lg:mb-4">
              <span class="title">Léo</span>
            </div>
            <div class="barlow page-desc uppercase !w-full text-center mb-4 !text-lg lg:!text-xl">
              vis ma vie de collaborateur
            </div>

            <hr class="mb-4" style="height:2px;border:none;color:#68CAD7;background-color:#68CAD7;" >

            <p class="barlow text-blue-dark text-sm xl:text-lg">
              Voici Léo.
            </p> 
            <p class="barlow-no text-blue-dark text-sm xl:text-lg mt-4">
              Léo est monteur d’opérations immobilières. Aujourd’hui, c’est son premier jour au sein d’Habitat 76. Il est enthousiaste et à la fois, un peu stressé d’intégrer l’office. 
            </p> 
            <p class="barlow text-blue-dark text-sm xl:text-lg mt-4">
            Ta mission ?
            </p> 
            <p class="barlow-no text-blue-dark text-sm xl:text-lg mt-4">
              Accompagner Léo au fil du parcours collaborateur afin qu’il soit tout à fait intégré au sein de l’office et qu’il puisse mener à bien son 1er projet : la construction de la résidence des “Acacias” !
            </p>
            <router-link
              :to="{
                name: 'Modules',
                params: {
                  idAdventure: 2,
                  idThematic: 0
                }
              }"
              class="button w-74 mx-auto !mt-4 xl:!mt-[25px] uppercase"
              >A toi de jouer !</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HomeReturn from '@/components/HomeReturn.vue'

export default {
  components: { HomeReturn },
  name: 'TrainingCommunCol',
  data() {
    return {
      retour_img: '/img/btn_retour.svg',
      fixSound : false,
    }
  },
  computed: {
    user() {
      return this.$store.state.account.data
    }
  },
  methods: {
    loadAudio() {
      this.fixSound = false
      this.timeout1 = setTimeout(() => {
        this.fixSound = true
      }, 500);

      if (this.$refs['audio-col'] && this.$refs['audio-col'].duration * 1000 - 500 > 0) {
        this.timeout2 = setTimeout(() => {
          this.fixSound = false
          var audio = document.getElementById("audio-general");
          if (audio) {
            audio.volume = 0.1;
          }
        }, this.$refs['audio-col'].duration * 1000 - 500);
      }
    }
  },
  beforeUnmount() {
    clearTimeout(this.timeout1)
    clearTimeout(this.timeout2)
  },
}
</script>
