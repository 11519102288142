<template>
  <div class="flex justify-center items-center h-screen">
    <div class="responsive-box bg-wave bg-no-repeat bg-contain bg-bottom">
      <HomeReturn :route="'Menu'" />
      <div class="flex flex-col justify-center items-center h-full">
        <div class="text-center">
          <h2 class="title">Boîte à outils</h2>
          <div class="w-full flex justify-center">
            <p class="page-desc !w-full">
              Consultez les documents d’intégration !
            </p>
          </div>
        </div>
        <div class="card !w-[60vmin] !shadow-orange">
          <img src="@/assets/img/training_im_logo.svg" alt="" />
          <h2 class="card-title uppercase">J'explore mon job !</h2>
          <router-link :to="{ name: 'Documents' }" class="button"
            >EN SAVOIR PLUS</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import HomeReturn from '@/components/HomeReturn.vue'
export default {
  name: 'ViewToolbox',
  components: { HomeReturn },
  data() {
    return {}
  }
}
</script>
