<template>
  <div class="flex justify-center items-center h-screen">
    <div class="responsive-box bg-game_bg_pre bg-no-repeat bg-cover">
      <div class="flex items-end justify-evenly h-full select-none relative">
        <div class="absolute top-[2%] left-[0%] w-[23%] h-fit assetsHomePage">
          <img src="@/assets/img/menu/int/logos.png" alt="" class="w-fit" />
        </div>
        <div class="w-[15%] assetsHomePage h-fit absolute top-[17%] left-[5%]">
          <div class="w-fit  cursor-pointer">
            <div
              @click="isModalCarte = true"
            >
              <img
                src="@/assets/img/menu/pre/territoire.png"
                alt=""
                class="hover:brightness-110"
              />
            </div>
          </div>
        </div>
        <div class="w-[13%] assetsHomePage h-fit absolute top-[1%] lg:right-[4%] right-[10%]">
          <div class="w-fit  cursor-pointer button">
            <div
              @click="logout()"
            >
              Déconnexion
            </div>
          </div>
        </div>
        <div class="w-[13%] assetsHomePage h-fit absolute top-[44%] left-[31%]">
          <div class="w-fit  cursor-pointer">
            <div
              @click="
                openModal(
                  'Espace CEGID',
                  'menu/ordinateur.png',
                  'Espace CEGID',
                  'Consulter l\'espace CEGID'
                )
              "
            >
              <img
                src="@/assets/img/menu/pre/ordinateur.png"
                alt=""
                class="hover:brightness-110"
              />
            </div>
          </div>
        </div>
        <div class="w-[22%] assetsHomePage h-fit absolute top-[12%] left-[38%]">
          <div class="w-fit  cursor-pointer">
            <div
              @click="isModalVideo=true"
            >
              <img
                src="@/assets/img/menu/pre/television.png"
                alt=""
                class="hover:brightness-110"
              />
            </div>
          </div>
        </div>
        <div class="w-[17%] assetsHomePage h-fit absolute top-[14%] right-[22%]">
          <div class="w-fit  cursor-pointer">
            <div
              @click="
                openModal(
                  'Carousel',
                  'menu/img.png',
                  'HABITAT 76 EN IMAGE',
                  'Consulter les photos'
                )
              "
            >
              <img
                src="@/assets/img/menu/pre/photos.png"
                alt=""
                class="hover:brightness-110"
              />
            </div>
          </div>
        </div>
        <div class="w-[20%] assetsHomePage h-fit absolute bottom-[11%] right-[11%]">
          <div class="w-fit  cursor-pointer">
            <div
              @click="isModalWelcome = true"
            >
              <img
                src="@/assets/img/menu/pre/tableau.png"
                alt=""
                class="hover:brightness-110"
              />
            </div>
          </div>
        </div>
    </div>
  </div>

  <Modal
    v-if="isModal"
    :path="activePath"
    :img="activeImg"
    @close-popup="closePopup"
    :title="activeTitle"
    :text="activeText"
  />
  <ModalCarte
    v-if="isModalCarte"
    @close-popup="isModalCarte = false"
  />
  <ModalWelcome
    v-if="isModalWelcome"
    @close-popup="isModalWelcome = false"
  />
  <ModalVideo
    v-if="isModalVideo"
    @close-popup="isModalVideo = false"
  />
  </div>
</template>

<script>
import Modal from '../../components/Modal.vue'
import ModalCarte from '../../components/ModalCarte.vue'
import ModalWelcome from '../../components/ModalWelcome.vue'
import ModalVideo from '../../components/ModalVideo.vue'
import taskManager from '../../datas/taskManager'

export default {
  components: {
    Modal,
    ModalCarte,
    ModalWelcome,
    ModalVideo
  },
  name: 'MenuPreIntegration',
  data() {
    return {
      activePath: '',
      activeImg: '',
      activeTitle: '',
      activeText: '',
      isModal: false,
      isModalCarte: false,
      isModalWelcome: false,
      isModalVideo: false,
    }
  },
  computed: {
    user() {
      return this.$store.state.account.data
    }
  },
  methods: {
    closePopup() {
      this.isModal = false
    },
    openModal(path, img, title, text) {
      this.activePath = path
      this.activeImg = img
      this.isModal = true
      this.activeTitle = title
      this.activeText = text
    },
    logout() {
      taskManager.logout()
    }
  }
}
</script>
