<template>
  <div class="flex justify-center items-center h-screen">
    <div class="responsive-box bg-wave bg-no-repeat bg-cover">
      <HomeReturn :route="'Training'" />

      <div class="flex flex-col pt-[20vmin] h-full">
        <div class="text-6xl w-full text-center mb-6 font-semibold">
          <span class="title">Welc'Home </span>
          <span class="title !text-red">chez Habitat 76 !</span>
        </div>
        <div class="page-desc uppercase !w-full text-center mb-6">
          Choisissez votre expérience
        </div>

        <div class="flex items-end justify-evenly select-none">
          <div
            v-for="job of jobs"
            :key="job.id"
            class="card text-3xl cursor-pointer"
            :class="user.job.slug == 'Cds' ? '' : 'opacity-40'"
            @click="goTo('Thematics', job.id)"
          >
            <!-- <img src="@/assets/img/training/experience_loc.svg" class="m-auto" alt=""> -->
            <p class="mt-2 uppercase barlow">{{ job.name }}</p>
            <div @click="goTo('Thematics', job.id)" class="button">
              C'EST PARTI !
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiManager from '../../datas/apiManager'
import router from '../../router'
import HomeReturn from '@/components/HomeReturn.vue'

export default {
  components: { HomeReturn },
  name: 'TrainingJobExp',
  data() {
    return {
      jobs: [],
      retour_img: '/img/btn_retour.svg'
    }
  },
  computed: {
    user() {
      return this.$store.state.account.data
    }
  },
  methods: {
    goTo(route, id) {
      router.push({
        name: route,
        params: {
          id: id
        }
      })
    }
  },
  created() {
    apiManager.getJobs('CS').then((response) => {
      this.jobs = response.data.data
    })
  }
}
</script>
