import apiManager from './apiManager'
import store from '../store/index'
import router from '../router'

export default {
  login(email, password, onSuccess = null, onError = null) {
    apiManager
      .login(email, password)
      .then((response) => {
        store.commit('account/_set', { k: 'data', v: response.data.data })

        //resfresh api token from user
        apiManager.refreshApiToken()
        // execute success callback from login page
        if (onSuccess) {
          onSuccess()
        }
      })
      .catch((error) => {
        if (onError) {
          onError(error.response)
        }
      })
  },
  forgetPassword(email, onSuccess = null, onError = null) {
    apiManager
      .forgetPassword(email)
      .then(() => {
        if (onSuccess) {
          onSuccess()
        }
      })
      .catch((error) => {
        if (onError) {
          onError(error.response)
        }
      })
  },
  newPassword(
    password,
    password_confirmation,
    onSuccess = null,
    onError = null,
    token
  ) {
    apiManager
      .newPassword(password, password_confirmation, token)
      .then(() => {
        if (onSuccess) {
          onSuccess()
        }
      })
      .catch((error) => {
        if (onError) {
          onError(error.response)
        }
      })
  },
  logout() {
    store.dispatch('_deleteAlls')
    router.push({ name: 'Login' })
  }
}
