<template>
  <div class="flex justify-center items-center h-screen">
    <div
      class="responsive-box !bg-blue-light"
    >
      <HomeReturn :route="'Training'" />

      <div class="flex flex-col pt-[15vmin] xl:pt-[18vmin] h-full">

        <div class="flex justify-center select-none h-full lg:h-fit">
          <img
            src="/img/training/CS.gif"
            class="absolute z-10 w-[20%] left-[14%] hidden lg:block drop-shadow-lg"
            alt=""
          />
          <div
            class="relative bg-white p-4 xl:p-16 text-center text-sm lg:text-2xl h-full flex flex-col justify-between rounded-3xl w-8/12 lg:w-[46%] shadow-lg py-10 overflow-y-auto"
          >
            <div class="title w-full text-center lg:mb-4">
              <span class="title">Bienvenue !</span>
            </div>

            <hr class="mb-4" style="height:2px;border:none;color:#68CAD7;background-color:#68CAD7;" >

            <p class="barlow text-blue-dark text-sm xl:text-lg">
              Tu viens d'arriver chez Habitat 76 au poste de chargé(e) de site. Félicitations, tu es le/la référent(e) terrain de ton secteur. 
            </p> 
            <p class="barlow-no text-blue-dark text-sm xl:text-lg mt-4">
              Après deux semaines avec le/la chargé(e) de site itinérant(e) qui t'as formé et accompagné, tu es prêt(e) à être immergé(e) dans la réalité du terrain.
            </p> 
            <p class="barlow text-blue-dark text-sm xl:text-lg mt-4">
            Vas-tu prendre les bonnes décisions ?
            </p>
            <router-link
              :to="{
                name: 'Thematics',
                params: {
                  id: 3
                }
              }"
              class="button w-74 mx-auto !mt-4 xl:!mt-[25px] uppercase"
              >A toi de jouer !</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HomeReturn from '@/components/HomeReturn.vue'

export default {
  components: { HomeReturn },
  name: 'TrainingCommunCol',
  data() {
    return {
      retour_img: '/img/btn_retour.svg'
    }
  },
  computed: {
    user() {
      return this.$store.state.account.data
    }
  },
  methods: {}
}
</script>
