<template>
  <div class="modal-box">
    <div class="modal-blur"></div>
    <div class="modal-inner">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  watch: {},
  name: 'ModalScene',
  methods: {
    close() {}
  },
  data() {
    return {
      da: 28
    }
  }
}
</script>

<style scoped>
.overlay {
  @apply absolute top-0 h-full w-full
	bg-black opacity-20;
}

.modal-inner {
  @apply z-10 p-5 w-11/12 lg:w-4/6 text-center max-h-full overflow-auto
	bg-white rounded-3xl;
}
</style>
