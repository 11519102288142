<template>
  <div class="modal-box">
    <div class="modal-blur" @click="closePopup()"></div>
    <div class="modal-inner">
      <div class="flex !p-8">
        <div class="w-5/12">
          <object
            type="image/svg+xml"
            :data="
              '/img/profile/' +
              adventureRef +
              (isValidate == 1 ? '' : '_grey') +
              '.svg'
            "
            class="w-full p-3"
          >
            <param name="percent" :value="percent ?? 0" />
          </object>

          <p class="uppercase text-blue-dark font-bold text-xl">
            {{ adventureName }}
          </p>
        </div>

        <div class="w-7/12">
          <div class="flex justify-end cursor-pointer">
            <img
              src="/img/close.png"
              alt=""
              class="w-6 m-2"
              @click="closePopup()"
            />
          </div>
          <div class="flex flex-col h-5/6 justify-evenly">
            <p class="text-blue text-4xl font-bold">
              {{ isValidate == 1 ? 'Félicitations !' : 'Hélas !' }}
            </p>
            <p class="uppercase text-blue-dark font-bold mt-4 text-2xl">
              Vous avez obtenu {{ percent ?? 0 }}% de bonnes réponses
            </p>

            <!-- <div class="w-8/12 overflow-hidden flex mt-3 m-auto">
              <div
                style="background-color: #eb7664"
                class="text-center h-[5vmin] flex justify-end"
                :style="'width: ' + (percent ?? 0) + '%'"
              >
                <p class="uppercase text-white font-bold text-2xl m-auto">
                  {{ percent ?? 0 }} %
                </p>
                <div
                  v-if="(percent ?? 0) != 100"
                  class="bg-white"
                ></div>
              </div>

              <div
                style="background-color: #8dcdd7"
                class="text-center h-[5vmin] flex justify-start ml-3"
                :style="'width: ' + (100 - (percent ?? 0)) + '%'"
              >
                <div
                  v-if="100 - (percent ?? 0) != 100"
                  class="bg-white"
                ></div>
                <p class="uppercase text-white font-bold text-2xl m-auto">
                  {{ 100 - (percent ?? 0) }} %
                </p>
              </div>
            </div> -->

            <!-- <p class="uppercase text-blue-dark font-bold mt-4 text-2xl">
              De bonne réponses
            </p> -->

            <p class="text-blue-dark font-semibold mt-4 text-2xl">
              {{
                isValidate == 1
                  ? "Vous validez l'expérience ! !"
                  : "Vous ne validez pas l'expérience"
              }}
            </p>
          </div>
        </div>
      </div>

      <div class="flex justify-center">
        <div
          class="bg-red rounded-full w-fit p-3 text-white uppercase font-semibold hover:!bg-orange cursor-pointer"
          @click="closePopup()"
        >
          Refaire le jeu
        </div>
        <div
          class="bg-red rounded-full w-fit p-3 text-white uppercase font-semibold ml-4 hover:!bg-orange cursor-pointer"
          @click="$router.go(-1)"
        >
          Refaire le quiz
        </div>
        <div
          class="bg-red rounded-full w-fit p-3 text-white uppercase font-semibold ml-4 hover:!bg-orange cursor-pointer"
          @click="openScore()"
        >
          Voir les réponses
        </div>
        
      </div>
    </div>
  </div>
  
</template>

<script>
export default {
  watch: {},
  name: 'ModalScene',
  methods: {
    closePopup() {
      this.$emit('close-popup-quiz')
    },
    openScore() {
      this.$emit('open-score')
    }
  },
  props: {
    adventureName: [String, Number],
    adventureRef: [String, Number],
    isValidate: [Boolean, Number, String],
    percent: [String, Number],
    min: [String, Number, String],
    module : [Object]
  },
  computed: {
    user() {
      return this.$store.state.account.data
    }
  },
  data() {
    return {
      isScore:false,
    }
  },
  created() {
  }
}
</script>

<style scoped>

.overlay {
  @apply absolute top-0 h-full w-full
	bg-black opacity-20;
}

.modal-inner {
  @apply z-10 p-5 w-4/6 text-center
  border-green-light p-4 border-b-8
	bg-white rounded-3xl;
}
</style>
