<template>
  <div class="flex justify-center items-center h-screen">
    <div
      class="responsive-box bg-quiz bg-no-repeat bg-cover h-full overflow-y-auto"
      style="background-size: 120%; background-position: bottom"
      :class="introQuiz ? '' : 'hidden'"
      v-if="this.questions.length > 0"
    >
      <div class="top-left-back-btn">
        <div>
          <router-link :to="{ name: 'Menu' }">
            <img src="/img/logo.svg" alt="" />
          </router-link>
        </div>

        <div>
          <router-link
            :to="{
              name: 'Modules',
              params: {
                idAdventure: this.module.adventure_id,
                idThematic:
                  this.module.thematic_id != null ? this.module.thematic_id : 0
              }
            }"
          >
            <img
              :src="retour_img"
              @mouseover="retour_img = '/img/btn_retour_hover.svg'"
              @mouseleave="retour_img = '/img/btn_retour.svg'"
              alt=""
              class="mt-5"
            />
          </router-link>
        </div>
      </div>

      <div class="flex flex-col xl:pt-[10vmin] h-full">
        <div class="w-full text-center xl:mb-6 mb-1 font-semibold">
          <span class="title !text-5xl">{{ module.name }}</span>
          <p class="text-blue-dark text-xl">
            Répondez aux {{ this.questions.length }} questions du quiz
          </p>
          <p class="text-blue-dark text-xl">et évaluez vos connaissances !</p>

          <div
            @click="introQuiz = false"
            class="mt-4 button w-fit m-auto shadow-xl"
          >
            C'EST PARTI !
          </div>
        </div>
      </div>
    </div>

    <div
      class="responsive-box bg-waveorange bg-no-repeat lg:bg-bottom bg-top h-full overflow-y-auto"
      :class="introQuiz ? 'hidden' : ''"
      v-if="this.questions.length > 0"
    >
      <div class="top-left-back-btn">
        <div>
          <router-link :to="{ name: 'Menu' }">
            <img src="/img/logo.svg" alt="" />
          </router-link>
        </div>

        <div v-if="index - 1 < 0">
          <router-link
            :to="{
              name: 'Modules',
              params: {
                idAdventure: this.module.adventure_id,
                idThematic:
                  this.module.thematic_id != null ? this.module.thematic_id : 0
              }
            }"
          >
            <img
              :src="retour_img"
              @mouseover="retour_img = '/img/btn_retour_hover.svg'"
              @mouseleave="retour_img = '/img/btn_retour.svg'"
              alt=""
              class="mt-5"
            />
          </router-link>
        </div>
        <div v-else>
          <div @click="previousQuestion()">
            <img
              :src="retour_img"
              @mouseover="retour_img = '/img/btn_retour_hover.svg'"
              @mouseleave="retour_img = '/img/btn_retour.svg'"
              alt=""
              class="mt-5"
            />
          </div>
        </div>
      </div>

      <div class="flex flex-col xl:pt-[8vmin] h-full">
        <div class="w-full text-center mb-1 font-semibold">
          <span class="title !text-5xl">{{ module.name }}</span>
        </div>
        <div class="title lg:!text-5xl w-full text-center mb-1 font-semibold">
          <span class="text-blue"
            >Question {{ index + 1 }} / {{ questions.length }}</span
          >
        </div>

        <div
          class="flex flex-col flex-wrap items-center justify-center select-none"
        >
          <div
            class="w-4/6 text-center m-auto font-semibold relative xl:p-6 p-2 text-blue-dark lg:text-3xl flex flex-col"
          >
            <p class="barlow lg:text-lg xl:text-2xl mb-1">
              {{ questions[index].text }}
            </p>
            <p class="italic text-sm xl:text-lg mb-5">
              {{
                getNbAnswers(questions[index].answers) > 1
                  ? 'Plusieurs bonnes réponses sont possibles'
                  : 'Une seule bonne réponse est possible'
              }}
            </p>
            <div
              v-for="(answer, indexAnswer) of questions[index].answers"
              :key="answer.id"
              @click="
                chooseAnswer(answer.id, questions[index].id, answer.is_correct, questions[index].answers)
              "
              class="flex text-left rounded-full xl:p-6 p-2 px-4 cursor-pointer border-b-8 border-orange-lightest hover:border-blue mb-4"
              :class="divActive(answer.id, questions[index].id)"
            >
              <p
                class="rounded-full mr-3 w-8 h-8 lg:w-12 lg:h-12 pt-1 text-center align-middle"
                :class="indexActive(answer.id, questions[index].id)"
              >
                {{ indexAnswer + 1 }}
              </p>

              <p class="my-auto w-full lg:text-2xl">{{ answer.text }}</p>
            </div>
          </div>

          <button
            v-if="index + 1 < questions.length"
            class="!text-base lg:!text-xl rounded-full uppercase mb-5"
            :class="
              isAnswered(questions[index].id)
                ? 'button !bg-blue hover:!bg-orange'
                : 'disabled-button'
            "
            type="button"
            @click="isAnswered(questions[index].id) ? nextQuestion() : ''"
          >
            <div class="flex">
              <img
                v-if="isAnswered(questions[index].id)"
                src="/img/check.svg"
                alt=""
                class="w-7 mr-2"
              />
              <p>Valider ma réponse</p>
            </div>
          </button>

          <button
            v-else
            class="button lg:!text-xl rounded-full uppercase mb-5"
            :class="
              isAnswered(questions[index].id)
                ? 'button !bg-blue hover:!bg-orange'
                : 'disabled-button'
            "
            type="button"
            @click="isAnswered(questions[index].id) ? answerQuizz() : ''"
          >
            <div class="flex">
              <img
                v-if="isAnswered(questions[index].id)"
                src="/img/check.svg"
                alt=""
                class="w-7 mr-2"
              />
              <p>Valider le quizz</p>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiManager from '../../datas/apiManager'

export default {
  components: {},
  name: 'TrainingQuizz',
  data() {
    return {
      questions: [],
      answered: 0,
      module: null,
      answers: [],
      index: 0,
      introQuiz: true,
      retour_img: '/img/btn_retour.svg'
    }
  },
  props: {
    idModule: [String, Number]
  },
  computed: {
    user() {
      return this.$store.state.account.data
    }
  },
  methods: {
    getNbAnswers(answers) {
      let nbCorrect= 0
      answers.forEach(answer => {
        nbCorrect += answer.is_correct
      });
      return nbCorrect
    },
    nextQuestion() {
      if (this.index + 1 < this.questions.length) {
        this.index++
        this.answered = 0
      }
    },
    previousQuestion() {
      if (this.index - 1 >= 0) {
        this.index--
      }
    },
    divActive(idAnswer, idQuestion) {
      let isChecked = 0
      if (this.answers[idQuestion]) {
        this.answers[idQuestion].forEach((answer) => {
          if (answer.answer_id == idAnswer) {
            isChecked = 1
          }
        })
      }
      return isChecked ? 'bg-blue-lightest border-b-8 border-blue' : 'bg-white'
    },
    indexActive(idAnswer, idQuestion) {
      let isChecked = 0
      if (this.answers[idQuestion]) {
        this.answers[idQuestion].forEach((answer) => {
          if (answer.answer_id == idAnswer) {
            isChecked = 1
          }
        })
      }
      return isChecked ? 'bg-blue-dark text-white' : 'bg-blue-lightest text-blue'
    },
    chooseAnswer(idAnswer, idQuestion, is_correct, answers) {
      let nbCorrect = 0
      answers.forEach(answer => {
        nbCorrect += answer.is_correct
      });

      if (this.answers[idQuestion]) {
        if (this.answers[idQuestion][idAnswer]) {
          delete this.answers[idQuestion][idAnswer]
          this.answered--
          
        } else {
          if (nbCorrect == 1) {
            
            this.answers[idQuestion] = []
          }
          this.answers[idQuestion][idAnswer] = {
            answer_id: idAnswer,
            is_correct: is_correct,
            question_id: idQuestion,
            adventure_id: this.module.adventure_id,
            module_id: this.module.id,
            thematic_id:
              this.module.thematic_id != null ? this.module.thematic_id : 0,
            user_id: this.user.id
          }

          this.answered++
        }
      } else {
        this.answers[idQuestion] = []
        this.answers[idQuestion][idAnswer] = {
          answer_id: idAnswer,
          is_correct: is_correct,
          question_id: idQuestion,
          adventure_id: this.module.adventure_id,
          module_id: this.module.id,
          thematic_id:
            this.module.thematic_id != null ? this.module.thematic_id : 0,
          user_id: this.user.id
        }
        this.answered++
      }

    },
    isAnswered(idQuestion) {
      if (this.answers[idQuestion] && this.answered > 0) {
        return true
      }
      return false
    },
    answerQuizz() {
      let cleanAnswers = this.answers.filter((n) => n)
      cleanAnswers.forEach((answer, index) => {
        cleanAnswers[index] = answer.filter((n) => n)
      })

      apiManager.sendAnswers(cleanAnswers.filter((n) => n)).then((response) => {
        let isValidate = 0

        console.log(Math.round(response.data.percent))
        console.log(this.module.min_score)
        console.log(Math.round(response.data.percent) >= this.module.min_score)
        if (Math.round(response.data.percent) >= this.module.min_score) {
          isValidate = 1
        }

        this.$router.push({
          name: 'Modules',
          params: {
            idAdventure: this.module.adventure_id,
            idThematic:
              this.module.thematic_id != null ? this.module.thematic_id : 0,
            isValidate: isValidate,
            percent: Math.round(response.data.percent),
            min: this.module.min_score
          }
        })
      })

      // location.reload();
      // router.push({
      //   name: 'Menu',
      // })
    }
  },
  created() {
    apiManager.getQuestions(this.idModule).then((response) => {
      this.questions = response.data.questions
      this.module = response.data.module
    })
  }
}
</script>
