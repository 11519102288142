<template>
  <div class="flex justify-center items-center h-screen">
    <div class="responsive-box bg-orange-light">
      
      <div class="flex flex-col h-full justify-center">
        <HomeReturn :route="'Menu'" />
        <div>
          <div class="text-center mb-5">
            <h2 class="title">Mon profil</h2>
            <div class="w-full flex justify-center">
              <p class="page-desc">
                Suivez votre évolution et les résultats détaillés de vos
                performances dans les diffèrents parcours !
              </p>
            </div>
          </div>
        </div>
        <div class="row flex justify-center mb-5">
          <router-link :to="{ name: 'Progression' }" class="pointer">
            <img
              src="@/assets/img/progression.svg"
              alt=""
              class="hover:filter hover:grayscale-[0.2]"
            />
          </router-link>
          <router-link :to="{ name: 'Perf' }" class="pointer">
            <img
              src="@/assets/img/performance.svg"
              alt=""
              class="hover:filter hover:grayscale-[0.2]"
            />
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HomeReturn from '@/components/HomeReturn.vue'
export default {
  name: 'ViewProfile',
  components: { HomeReturn },
  data() {
    return {}
  }
}
</script>
