<template>
  <div class="flex justify-center items-center h-screen">
    <div class="responsive-box bg-orange-light">
      <HomeReturn :route="'Profile'" />
      <div class="flex flex-col justify-center items-center h-full">
        <div class="text-center mb-10">
          <h2 class="title">Ma progression</h2>
        </div>
        <div class="flex flex-col justify-center items-center w-1/2">
          <div
            v-for="progression of progressions"
            :key="progression.name"
            class="w-full mt-3"
          >
            <div class="flex justify-between">
              <p class="progression-desc">{{ progression.name }}</p>
              <p class="progression-desc justify-end">
                {{ progression.percent > 100 ? 100 : progression.percent }} %
              </p>
            </div>
            <div class="w-full bg-blue-light overflow-hidden">
              <div
                :class="getBgColor(progression.ref)"
                class="text-center h-[5vmin] flex justify-end grow"
                :style="'width: ' + (progression.percent > 100 ? 100 : progression.percent) + '%'"
              >
                <div
                  v-if="progression.percent < 100"
                  class="bg-blue-light h-[5vmin] w-[5vmin] triangle"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiManager from '../../datas/apiManager'
import HomeReturn from '@/components/HomeReturn.vue'

export default {
  components: { HomeReturn },
  name: 'ProfileProgression',
  data() {
    return {
      progressions: [],
      retour_img: '/img/btn_retour.svg'
    }
  },
  computed: {
    user() {
      return this.$store.state.account.data
    }
  },
  methods: {
    getBgColor(adventure) {
      if (adventure == 'LOC') {
        return 'bg-red'
      }

      if (adventure == 'COL') {
        return 'bg-orange'
      }

      if (adventure == 'CS') {
        return 'bg-orange-dark'
      }

      return 'bg-red'
    }
  },
  created() {
    apiManager.getProgressions(this.user.id).then((response) => {
      if (this.user.job == null) {
        let progressionsNoJob = []
        response.data.forEach((performance) => {
          if (performance.ref != 'CS') {
            progressionsNoJob.push(performance)
          }
          this.progressions = progressionsNoJob
        })
      } else {
        this.progressions = response.data
      }
    })
  }
}
</script>
