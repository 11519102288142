<template>
  <div class="content">
    <div class="flex">
      <HomeReturn :route="'Menu'" />
    </div>
    <div class="flex flex-col pt-[10vmin] h-full">
      <div class="text-6xl w-full text-center mb-6 font-semibold">
        <span class="text-blue">Habitat 76 en image</span>
      </div>
      <Carousel :items-to-show="2.5" :wrap-around="true">
        <Slide v-for="(index, img) of 15" :key="img.id">
          <div
            class="carousel__item bg-white w-11/12 relative shadow-xl p-6 text-center text-blue-dark text-2xl"
          >
            <img
              :src="'img/presentation/img' + index + '.jpg'"
              class="w-30 m-auto mb-3"
              alt=""
            />
            <p>{{text[index-1]}}</p>
          </div>
        </Slide>

        <template #addons>
          <Navigation />
        </template>
      </Carousel>
    </div>
  </div>
</template>

<script>
import { Carousel, Navigation, Slide } from 'vue3-carousel'
import HomeReturn from '@/components/HomeReturn.vue'

import 'vue3-carousel/dist/carousel.css'

export default {
  components: {
    Carousel,
    Slide,
    Navigation,
    HomeReturn
  },
  name: 'ViewCarousel',
  data() {
    return {
      imgs: [],
      text : [
        'Café de l\'alternance',
        'Octobre rose',
        'Afterwork - Les collaborateurs font du ski',
        'Habitat 76 à la Fabique Du Changement',
        'habitat 76 soutient les bleus',
        'L\'analyse des facilitateurs',
        'L\'intelligence collaborative au service d\'habitat 76',
        'L\'intelligence collaborative en image',
        'Petite pause Babyfoot pour se rebooster',
        'Séminaire cadre',
        'Soirée année 80 ! Place aux plus beaux déguisements',
        'Soirée des 100 ans habitat 76 ',
        'VICTOIRE des co acteurs engagés - Hackathon',
        '24h pour innover',
        'Défis 24h pour innover',
      ]
    }
  },
  computed: {
    user() {
      return this.$store.state.account.data
    }
  },
  methods: {},
  mounted() {
    let buttons = document.querySelectorAll('.carousel > button')
    buttons.forEach((button) => {
      button.style.backgroundColor = 'RGB(28, 160, 180)'
      button.style.width = '50px'
      button.style.height = '50px'
    })
  },
  watch: {
    // whenever question changes, this function will run
    imgs() {
      let buttons = document.querySelectorAll('.carousel > button')
      buttons.forEach((button) => {
        button.style.backgroundColor = 'RGB(28, 160, 180)'
        button.style.width = '50px'
        button.style.height = '50px'
      })
    }
  }
}
</script>

<style scoped>
.carousel > button {
  background-color: black !important;
}
.carousel {
  width: 80% !important;
  margin: auto;
  padding: 8px;
}
.carousel__slide > .carousel__item {
  transform: scale(1);
  opacity: 0.5;
  transition: 0.5s;
}
.carousel__slide--visible > .carousel__item {
  opacity: 1;
  transform: rotateY(0);
}
.carousel__slide--next > .carousel__item {
  transform: scale(0.9) translate(-10px);
}
.carousel__slide--prev > .carousel__item {
  transform: scale(0.9) translate(10px);
}
.carousel__slide--active > .carousel__item {
  /* transform: scale(1.1); */
}
</style>
