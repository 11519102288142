<template>
  <div class="h-full bg-white">
    <!-- <div class="m-auto bg-white">
      <p class="mb-4 text-center">Mettre dans l'odre du plus calorique au moins calorique</p>
      <div class="flex justify-center items-center bg-white">
        <draggable
          class="list-group"
          item-key="order"
          :component-data="{ tag: 'ul', name: 'flip-list', type: 'transition' }"
          v-model="list"
          v-bind="dragOptions"
          @start="isDragging = true"
          @end="isDragging = false"
        >
          <template #item="{ element }">
            <li class="list-group-item">
              <i
                :class="
                  element.fixed ? 'fa fa-anchor' : 'glyphicon glyphicon-pushpin'
                "
                @click="element.fixed = !element.fixed"
                aria-hidden="true"
              ></i>
              {{ element.name }}
            </li>
          </template>
        </draggable>
      </div>
    </div> -->

    <div class="m-auto bg-white mt-10">
      <p class="mb-4 text-center">Mettre dans l'odre du plus calorique au moins calorique (ordering)</p>
      <div class="flex justify-center items-center bg-white">
        <div class="flex w-[50%] m-auto">
          <div class="w-1/2 flex flex-col">
            <h3 class="text-center">Liste</h3>
            <div class="m-3 flex-1">
              <draggable
                class="list-group h-full border p-2"
                  :list="list1"
                  group="people"
                  @change="log"
                  itemKey="text"
              >
                <template #item="{ element }">
                <div class="list-group-item">{{ element.text }}</div>
                </template>
              </draggable>
            </div>
          </div>
          

          <div class="w-1/2 flex flex-col">
            <h3 class="text-center">Réponses</h3>
            <div class="m-3 flex-1">
              <draggable
                  class="list-group h-full border p-2"
                  :list="list2"
                  group="people"
                  @change="log"
                  itemKey="text"
              >
                <template #item="{ element, index }">
                <div class="list-group-item"> {{ index+1 }}. {{ element.text }}</div>
                </template>
              </draggable>
            </div>
          </div> 
        </div>
      </div>
      <div class="m-auto w-fit mt-4">
        {{list2}}
      </div>
    </div>

    <div class="m-auto bg-white mt-10">
      <p class="mb-4 text-center">Qui mange au chinois jeudi (choices)</p>
      <div class="flex justify-center items-center bg-white">
        <div class="flex w-[50%] m-auto">
          <div class="w-1/2 flex flex-col">
            <h3 class="text-center">Liste</h3>
            <div class="m-3 flex-1">
            <draggable
              class="list-group h-full border p-2"
                :list="list3"
                group="people"
                @change="log"
                itemKey="text"
            >
              <template #item="{ element }">
              <div class="list-group-item">{{ element.text }}</div>
              </template>
            </draggable>     
            </div>
          </div>

          <div class="w-1/2 flex flex-col">
            <h3 class="text-center">Réponses</h3>
            <div class="m-3 flex-1">
            <draggable
                class="list-group h-full border p-2"
                :list="list4"
                group="people"
                @change="log"
                itemKey="text"
            >
              <template #item="{ element }">
              <div class="list-group-item">{{ element.text }}</div>
              </template>
            </draggable>
            </div>
          </div> 
        </div>
      </div>
      <div class="m-auto w-fit mt-4">
        {{list4}}
      </div>
    </div>
  </div>
</template>

<script>  
import draggable from 'vuedraggable'

export default {
  watch: {
  },
  emits: ['update-steps'],
  name: 'SceneContainer',
  components: {
    draggable,

  },
  props: {
  },
  data() {
    return {
        drag: false,
        list : [
            {
                "name": "patates",
                "order": 2
            },
            {
                "name": "riz",
                "order": 3
            },
            {
                "name": "pattes",
                "order": 4
            },
            {
                "name": "poissons",
                "order": 1
            },
            {
                "name": "choux",
                "order": 5
            },
            {
                "name": "endives",
                "order": 6
            },
            {
                "name": "lardon",
                "order": 7
            }
        ],
        list1: [
            {
                "text": "Concombre",
                "id": 5,
                "order": 4
            },
            {
                "text": "Endives",
                "id": 2,
                "order": 3
            },
            {
                "text": "Riz",
                "id": 3,
                "order": 2
            },
            {
                "text": "Lardons",
                "id": 4,
                "order": 1
            }
        ],
        list2: [],
        list3: [
            {
                "text": "Florent",
                "id": 5,
                "is_correct": 1
            },
            {
                "text": "Valentin",
                "id": 2,
                "is_correct": 0
            },
            {
                "text": "Romain",
                "id": 3,
                "is_correct": 0
            },
            {
                "text": "Clement",
                "id": 4,
                "is_correct": 1
            }
        ],
        list4: [],
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },
  methods: {
    log: function(evt) {
      window.console.log(evt);
    }
  },
  beforeUpdate() {
   
  }
}
</script>

<style>
.button {
  margin-top: 35px;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}
</style>