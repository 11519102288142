<template>
  <overlay-scene
    v-if="!isLastSceneConclusion"
    @overlay-clicked="completedDialog()"
  ></overlay-scene>
  <div class="absolute top-5 w-full flex h-16 lg:h-24">
    <div class="flex ml-10" style="z-index: 49">
      <router-link
        v-if="module"
        :to="{
          name: 'Modules',
          params: {
            idAdventure: module.adventure_id,
            idThematic: module.thematic_id ? module.thematic_id : 0
          }
        }"
      >
        <img
          :src="retour_img"
          @mouseover="retour_img = '/img/btn_quit_hover.svg'"
          @mouseleave="retour_img = '/img/btn_quit.svg'"
          alt=""
          class="lg:mt-5"
        />
      </router-link>
    </div>
  </div>

  

  <div v-if="step.options.dialog != null && !isLastSceneConclusion" class="absolute bottom-0 w-full h-full flex justify-center">
    <div class="absolute bottom-5 w-2/3 lg:!pt-12 flex justify-center max-h-[80%] overflow-auto py-3">
      <div class="w-[95%]">
        <img v-if="step.options.think" src="/img/cloudup2.svg" alt="" class="w-[84%] mx-auto">
        <div class="dialog-container relative" :class="isContextOrEnd() +' '+ colorBorder()">
          <TypingDialog
            :lastScene="false"
            :dialog="decodeHTML(step.options.dialog)"
            :isDialogComplete="isDialogComplete"
            :firstClick="firstClick"
            @completedDialog="completedDialog()"
            @isDebrief="debrief"
          />
        </div>
        <img v-if="step.options.think" src="/img/clouddown2.svg" alt="" class="w-[84%] mx-auto">
      </div>
    </div>
  </div>
  
  <div v-if="step.options.dialog != null && isLastSceneConclusion" class="absolute bottom-0 w-full h-full flex justify-center backdrop-blur">
    <div class="relative m-auto w-2/3 flex justify-center lg:!pt-12 max-h-full overflow-auto py-3"> 
      <div class="dialog-container !bg-white !text-blue-dark w-[95%] relative" :class="isContextOrEnd() +' '+ (isContext() && !step.options.think ? 'shadow-dialog-green' : 'shadow-dialog-blue')">
        <TypingDialog
          :lastScene="false"
          :dialog="decodeHTML(step.options.dialog)"
          :textDebrief="longDebrief ? longDebrief[indexDebrief-1] : decodeHTML(step.options.debrief)"
          :isDialogComplete="isDialogComplete"
          :firstClick="firstClick"
          @completedDialog="completedDialog()"
          @isDebrief="debrief"
        />
        <div class="flex mt-4 justify-center">
          <div
            class="!w-fit flex justify-center dialog-container bg-white border-red border-2 text-blue-dark !rounded-full !p-2 !px-4 cursor-pointer uppercase lg:!text-lg m-4"
            @click="isScore = true"
          >
            voir votre score
          </div>
          <!-- <div
            v-if="this.step.options.type.name == 'good'"
            class="!w-fit flex justify-center dialog-container bg-white border-red border-2 text-blue-dark !rounded-full !p-2 !px-4 cursor-pointer uppercase lg:!text-lg m-4"
            @click="isDebrief = true"
          >
            Debrief
          </div> -->
          <div
            v-if="this.step.options.type.name == 'bad'"
            class="!w-fit flex justify-center dialog-container bg-red text-white !rounded-full !p-2 !px-4 cursor-pointer uppercase lg:!text-lg m-4"
            @click="replay"
          >
            Rejouer <span class="ml-2 font-bold">&#8594;</span>
          </div>

        </div>

        <div v-if="documents.length > 0" class="flex flex-col justify-center w-full mt-4">
          <div class="flex items-center mx-auto mb-4">
            <img src="/img/menu/tool.png" alt="" class="w-[60px]">
            <p class="font-bold barlow text-blue ml-4">Boite à outils</p>
          </div>
          <a
            v-for="document of documents"
            target="_blank"
            :key="document.id"
            :href="
              document.url != null
                ? baseUrl + '/api/files/' + document.url
                : null
            "
            class="cursor-pointer underline mb-1 text-xl"
          >
            <li>{{ document.title }}</li>
          </a>
        </div>
      </div>
      <div
        v-if="isLastSceneConclusion"
        @click="completedDialog(true)"
        class="absolute bottom-5 lg:bottom-16 right-[1%] border-blue border-2 bg-blue-light rounded-full p-2 w-8 lg:w-16 h-8 lg:h-16 z-50 cursor-pointer"
      >
        <img src="/img/right-arrow-w.png" alt="" />
      </div>
      <div
        v-if="lastScene && longDebrief && indexDebrief > 1" @click="indexDebrief > 1 ? indexDebrief-- : ''"
        class="absolute bottom-5 lg:bottom-16 left-[0%] border-blue border-2 bg-blue-light rounded-full p-2 w-8 lg:w-16 h-8 lg:h-16 z-50 cursor-pointer"
      >
        <img src="/img/left-arrow-w.png" alt="" />
      </div>
    </div>
  </div>

  <modal-scene v-if="isScore && isLastSceneConclusion">
    <p class="text-lg lg:text-2xl font-bold my-2 text-blue-dark">Score : {{scoreAnswers}} / {{Object.keys(corrects).length}}</p>

    <half-circle-spinner v-if="loading"
      :animation-duration="1000"
      :size="60"
      color="#009AB3"
      class="mx-auto my-2"
    />

    <div v-else
      v-for="(question, key, index) in questions"
      :key="index"
      class="bg-white my-2 w-10/12 m-auto "
    >
      <template v-if="question[0] && question[0].order != 0 && question[0].order != null">
        <p
          :class="corrects[key] == 1 ? 'text-green' : 'text-red'"
          class="text-lg lg:text-4xl font-bold my-5"
          v-text="
            'Scene ' + (index + 1) + ' ' + (corrects[key] == 1 ? 'VRAI' : 'FAUX')
          "
        ></p>

        <div
          v-for="answer in getOrder(question)"
          :key="answer.id"
          class="flex justify-center w-full mb-1"
        >
          <div class="m-auto w-2/12 bg-blue-dark rounded-full text-white text-bold w-8 h-8 mr-2">
          <p class="m-auto mt-1">{{answer.order}}</p>
          </div>
          <p v-html="answer.text" class="w-10/12 text-left text-blue-dark font-semibold text-sm lg:text-xl">
          </p>
        </div>
      </template>

      <template v-else>
        <p
          :class="corrects[key] == 1 ? 'text-green' : 'text-red'"
          class="text-lg lg:text-4xl font-bold my-5"
          v-text="
            'Scene ' + (index + 1) + ' ' + (corrects[key] == 1 ? 'VRAI' : 'FAUX')
          "
        ></p>

        <div
          v-for="answer in corrects[key] == 1
            ? goodAnswers(question)
            : badAnswers(question)"
          :key="answer.id"
          class="flex justify-center w-full mb-1"
        >
          <div class="w-1/12">
            <img
              class="w-8"
              :src="corrects[key] == 1 ? '/img/check.svg' : '/img/cross.svg'"
              alt=""
            />
          </div>
          <p v-html="answer.text" class="w-10/12 text-left text-blue-dark font-semibold text-sm lg:text-xl">
          </p>
        </div>

        <hr class="mt-4 mb-4" />

        <div
          v-for="answer in corrects[key] == 1
            ? badAnswers(question)
            : goodAnswers(question)"
          :key="answer.id"
          class="flex justify-center w-full mb-1"
        >
          <div class="w-1/12">
            <img
              class="w-8"
              :src="corrects[key] == 1 ? '/img/cross.svg' : '/img/check.svg'"
              alt=""
            />
          </div>
          <p v-html="answer.text" class="w-10/12 text-left text-blue-dark font-semibold text-sm lg:text-xl">
          </p>
        </div>
      </template>
     
    </div>
    <button
      @click="isScore = false"
      class="bg-blue hover:bg-blue-hover rounded-full text-white px-6 py-2 my-2"
    >
      Fermer
    </button>
  </modal-scene>

  <modal-scene v-show="isDebrief && isLastSceneConclusion">
    <div class="bg-white my-2 w-10/12 m-auto">
      <p class="text-lg lg:text-4xl font-bold my-5 text-green">Debrief</p>
      <p class="text-sm lg:text-lg my-5" v-html="step.options.debrief"></p>
    </div>

    <button
      @click="isDebrief = false"
      class="bg-blue hover:bg-blue-hover rounded-full text-white px-6 py-2 my-2"
    >
      Fermer
    </button>
  </modal-scene>
</template>

<script>
import StepMixin from '@/components/scene/components/mixins/StepMixin'
import TypingDialog from '@/components/scene/components/TypingDialog.vue'
import apiManager from '../../../../datas/apiManager'
// import store from '../../../../store/index'
import { HalfCircleSpinner } from 'epic-spinners'

export default {
  emits: ['is-view', 'is-next-practice'],
  name: 'DialogSubtitle',
  mixins: [StepMixin],
  components: {
    TypingDialog,
    HalfCircleSpinner 
  },
  data() {
    return {
      isScore: false,
      isDebrief: false,
      type: '',
      dialog: '',
      preDialog: '',
      questions: [],
      correct: [],
      increment: [],
      // forceSkip: false,
      isDialogComplete: false,
      firstClick: false,
      retour_img: '/img/btn_quit.svg',
      isLastSceneConclusion: false,
      indexDebrief : 1,
      corrects : {},
      loading : false,
      documents : [],
    }
  },
  computed: {
    scoreAnswers() {
      let nbCorrect = 0
      for (const key in this.corrects) {
        if (this.corrects[key]) {
          nbCorrect++
        }
      }
      return nbCorrect
    },
    user() {
      return this.$store.state.account.data
    },
    allDialog() {
      return this.step.options.dialog.split('£cut£')
    },
    longDebrief() {
      let textDebrief = []
      // if(this.step.options.dialog && this.step.options.debrief ) {
      //   textDebrief = (this.step.options.dialog + '\n' + this.step.options.debrief).split('£cut£')
      // } else if (this.step.options.dialog) {
      //   textDebrief = this.step.options.dialog.split('£cut£')
      // } else if (this.step.options.debrief) {
      //   textDebrief = this.step.options.debrief.split('£cut£')
      // } else {
      //   textDebrief =  []
      // }

      if (this.step.options.debrief) {
        textDebrief = this.step.options.debrief.split('£cut£')
      }

      if (textDebrief.length > 1 && this.lastScene) {
        return textDebrief
      }
      return false
    },
  },
  methods: {
    colorBorder() {
      if (this.isContext() && !this.step.options.think) {
        return 'shadow-dialog-green'
      }
      if (this.step.options.think) {
        return ''
      } 
    
      return 'shadow-dialog-blue'
    },
    getOrder(answers) {
      return answers.sort((a, b) => (a.order > b.order) ? 1 : (a.order < b.order) ? -1 : 0);
    },
    decodeHTML(text) {
      var txt = document.createElement('textarea')
      txt.innerHTML = text
      let regex = /<\/?[^>]+(>|$)/g;

      return txt.value.replace(regex,'')
    },
    replay() {
      this.$router.go()
    },
    completedDialog(force) {
      if (this.lastScene && this.longDebrief && this.indexDebrief < this.longDebrief.length ) {
        this.indexDebrief++
        return
      }

      if (force) {
        this.forceNextStep()
      }
      this.isDialogComplete = true
      if (
        this.forceSkip &&
        (this.firstClick || this.step.options.dialog == null)
      ) {
        this.forceNextStep()
        return
      }
      this.firstClick ? this.nextStep() : ''

      this.firstClick = !this.firstClick
    },
    isContextOrEnd() {
      if (this.lastScene) {
        return 'bg-[#00627B] !text-white'
      }  
      if (this.step.options.think) {
        return 'bg-white !text-blue-dark ' + 'thought'
      }
      if (this.isContext()) {
        return 'bg-white !text-blue-dark'
      }  
      return 'border-blue border-2 lg:border-[3px] bg-blue-lightest !text-blue-dark ' + (this.step.options.arrowLeft && this.step.options.arrowLeft == true ? 'arrow-left' : 'arrow')
    },
    isContext() {
      const words = this.step.options.dialog ? this.step.options.dialog.split(']') : []
      if (words.length == 1) {
        return true
      }
      return false
    },
    decodeHtml(html) {
      var txt = document.createElement('textarea')
      txt.innerHTML = html
      return txt.value
    },
    debrief(value) {
      this.firstClick = value
    },
    goodAnswers(answers) {
      let goodAnswers = []
      answers.forEach((answer) => {
        if (answer.is_correct == 1) {
          goodAnswers.push(answer)
        }
      })

      return goodAnswers
    },
    badAnswers(answers) {
      let badAnswers = []

      answers.forEach((answer) => {
        if (!answer.is_correct == 1) {
          badAnswers.push(answer)
        }
      })

      return badAnswers
    },
    async getAnswersData() {
      this.loading = true
      let data = {
        module_id: this.module.id,
        set: this.step.options.set,
        user_id: this.user.id
      }

      if (this.step.options.end) {
        this.isLastSceneConclusion = true
      }

      await apiManager.getAnswersSet(data).then((response) => {
        this.questions = response.data.answers
        this.corrects = response.data.corrects
      })
      this.loading = false
    }
  },
  watch: {
    step() {
      this.isDialogComplete = false
      this.firstClick = false
    }
  },
  // beforeUpdate() {
  //   this.getAnswersData()
  // },
  created() {
    this.getAnswersData()
    apiManager.getDocumentsModule(this.module.id).then((response) => {
      this.documents = response.data
    })
  }
}
</script>

<style scoped>
.dialog-container {
  @apply rounded-xl text-sm my-auto lg:text-2xl p-4 lg:p-8 text-center w-full;
}
</style>
